import {
  imageFileTypeConst,
  userImagePathConst,
  bannerImagePathConst,
  notificationDetailImgConst
} from "../consts/fileConsts";

const useFetchFile = () => {
  const baseFileServeUrl = process.env.REACT_APP_FILE_SERVE_URL;
  const defaultAvatar = "/images/avatar.jpg";

  const fetchImageFile = async (imageUrl, destination) => {
    try {
      if (!imageUrl) {
        Promise.reject(new Error("Ivalid url"));
      }
      const url = `${baseFileServeUrl}${destination}/${imageUrl}`;
      const response = await fetch(url, {
        headers: {
          "api-key": process.env.REACT_APP_API_KEY_VALUE,
        },
      });
      if (!response.ok) {
        if (destination === userImagePathConst) {
          return defaultAvatar;
        }
      }
      const blob = await response.blob();
      const imageSrc = URL.createObjectURL(blob);
      return imageSrc;
    } catch (err) {
      if (destination === userImagePathConst) {
        return defaultAvatar;
      }
    }
  };

  const checkFileSource = async (fileUrl, fileType, destination) => {
    if (fileUrl) {
      if (fileType === imageFileTypeConst) {
        const imgBlobResponse = await fetchImageFile(fileUrl, destination);
        return imgBlobResponse;
      }
    }
  };

  return checkFileSource;
};

export default useFetchFile;
