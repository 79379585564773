import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { useForm } from "react-hook-form";
import { getFormErrMsg } from "../../utils/helperFunctions";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import LoadingButton from "../LoadingButton";

const AddAppKeyModal = ({ open, close, fetchAppKeyList }) => {
  const [isLoaderBtn, setLoaderBtn] = useState(false);
  const axios = useAxios();
  const [errMsg, setErrMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async (data) => {
    setLoaderBtn(true);
    await axios
      .post("auth/createAppKey", data)
      .then((res) => {
        if (res?.data?.status) {
          fetchAppKeyList();
          toast.success(res?.data?.message);
          close();
        } else {
          toast.info(
            res?.response?.data?.message || "Failed to create app key"
          );
          setErrMsg(res?.response?.data?.message || "Failed to create app key");
        }
      })
      .catch((err) => {
        toast.error("Failed to create app key");
        setErrMsg("Failed to create app key");
      })
      .finally(() => {
        setLoaderBtn(false);
      });
  };

  return (
    <Modal open={open} onClose={close} showCloseIcon={false} center>
      <div className="min-w-[400px]">
        <div className="flex justify-between py-1">
          <h1 className="">Add App Key</h1>
          <span
            onClick={close}
            className="cursor-pointer material-symbols-outlined"
          >
            close
          </span>
        </div>
        <span className="text-red-600 text-sm">{errMsg}</span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-3">
            <div>
              <label>Title</label>
              <input
                {...register("title", { required: "This field is required" })}
                type="text"
              />
              {errors.title && getFormErrMsg("title", errors)}
            </div>
            <div>
              <label>Value</label>
              <input
                {...register("value", { required: "This field is required" })}
                type="text"
              />
              {errors.value && getFormErrMsg("value", errors)}
            </div>
            <div>
              <label>Description</label>
              <textarea
                className="min-h-[60px]"
                {...register("description")}
              ></textarea>
              {errors.description && getFormErrMsg("description", errors)}
            </div>
          </div>
          <div className="flex justify-end gap-3 py-3">
            <button type="button" onClick={close} className="btn btn--border">
              Close
            </button>
            {isLoaderBtn ? (
              <LoadingButton title="Creating App Key" />
            ) : (
              <button type="submit" className="btn">
                Add App Key
              </button>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddAppKeyModal;
