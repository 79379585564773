import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { userRoles } from "../../utils/Permissions/permissionList";
import { useForm, Controller } from "react-hook-form";
import { alphaOnlyRegex, emailRegex } from "../../helpers/regexValues";
import { getFormErrMsg } from "../../utils/helperFunctions";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { permissionCategory } from "../../utils/Permissions/permissionList";
import LoadingButton from "../LoadingButton";

const ADMIN_ROLE = 1;

const AddUser = ({ fetchUserData, open, close }) => {
  const [isBtnLoader, setBtnLoader] = useState(false);
  const user = useSelector((state) => state.auth);
  const [permissionCategoryList, setPermissionCategoryList] =
    useState(permissionCategory);
  const { allPermissionList: permissionList } = user;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    getValues,
  } = useForm({ mode: "onBlur" });

  const [permissions, setPermissions] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [categoryIndex, setCategoryIndex] = useState(0);
  const axios = useAxios();

  const handleSetPermissions = (permissionId, status) => {
    if (status) {
      const isExists = permissions.find(
        (i) => Number(i) === Number(permissionId)
      );
      if (isExists) {
        return;
      }
      setPermissions((prev) => [...prev, permissionId]);
    } else {
      setPermissions((prev) =>
        prev.filter((i) => Number(i) !== Number(permissionId))
      );
    }
  };

  const handleSelectAllPermissions = (e) => {
    if (e) {
      let permissionArray = permissionList;
      if (Number(getValues("role") !== ADMIN_ROLE)) {
        permissionArray = permissionList.filter((i) => i.category !== "user");
      }
      setPermissions(permissionArray.map((i) => Number(i.id)));
    } else {
      setPermissions([]);
    }
  };

  const handleCloseModal = () => {
    setPermissions([]);
    reset();
    close();
  };
  const createUser = async (data) => {
    try {
      setBtnLoader(true);
      const createUserResponse = await axios
        .post("auth/createUser", { ...data, permissions })
        .then((res) => res)
        .catch((err) => err)
        .finally(() => {
          setBtnLoader(false);
        });
      if (createUserResponse?.data?.status) {
        toast.info(createUserResponse?.data?.message2 || "");
        toast.success(createUserResponse?.data?.message || "");
        fetchUserData();
        handleCloseModal();
      } else {
        const isEmailExists =
          createUserResponse?.response?.data?.validationRes?.errors?.find(
            (i) => i?.path === "email"
          );
        if (isEmailExists) {
          return setErrMsg("Email already exists");
        } else {
          return setErrMsg("Failed to create new user");
        }
      }
    } catch (err) {
      throw err;
    }
  };

  const handleChangeRole = (e) => {
    const roleId = Number(e);
    if (roleId === ADMIN_ROLE) {
      setPermissionCategoryList(permissionCategory);
      handleSelectAllPermissions(true);
    } else {
      setPermissionCategoryList(
        permissionCategory.filter((i) => i.key !== "user")
      );
      handleSelectAllPermissions(false);
    }
  };

  return (
    <Modal open={open} closeOnOverlayClick={false} center showCloseIcon={false}>
      <div className="w-[500px]">
        <form onSubmit={handleSubmit(createUser)}>
          <div className="flex justify-between py-3 items-start">
            <div>
              <h1 className="font-medium">Add Staff</h1>
              <p className="max-w-[200px] text-xs">
                Give staff access to your portal by sending them an invitation.
              </p>
              {errMsg && (
                <span className="font-medium mt-2 text-red-600 text-sm">
                  {errMsg}
                </span>
              )}
            </div>

            <button type="button" onClick={handleCloseModal}>
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex justify-between gap-5">
              <div className="w-full">
                <label>First Name</label>
                <input
                  {...register("firstName", {
                    required: "This field is required",
                    pattern: {
                      value: alphaOnlyRegex,
                      message: "Provide a valid first name",
                    },
                  })}
                  type="text"
                />
                {errors.firstName && getFormErrMsg("firstName", errors)}
              </div>
              <div className="w-full">
                <label>Last Name</label>
                <input
                  {...register("lastName", {
                    required: "This field is required",
                    pattern: {
                      value: alphaOnlyRegex,
                      message: "Provide a valid last name",
                    },
                  })}
                  type="text"
                />
                {errors.lastName && getFormErrMsg("lastName", errors)}
              </div>
            </div>
            <div className="flex justify-between gap-5">
              <div className="w-full">
                <label>Email</label>
                <input
                  {...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: emailRegex,
                      message: "Provide a valid email adress",
                    },
                  })}
                  type="text"
                />
                {errors.email && getFormErrMsg("email", errors)}
              </div>
            </div>
            <div className="flex w-full">
              <div className="w-full">
                <label>Role</label>
                <Controller
                  rules={{ required: "This field is required" }}
                  control={control}
                  name="role"
                  render={({ field }) => (
                    <Select
                      options={userRoles}
                      className="custom-select-container"
                      onChange={(e) => {
                        field.onChange(e.value);
                        handleChangeRole(e.value);
                      }}
                    />
                  )}
                />
                {errors.role && getFormErrMsg("role", errors)}
              </div>
            </div>
            <div className="border-t-2 p-5">
              <div className=" flex justify-end w-full whitespace-nowrap  font-medium ">
                <div className="flex border rounded-md p-2 w-fit items-center gap-1">
                  <input
                    onChange={(e) =>
                      handleSelectAllPermissions(e.target.checked)
                    }
                    checked={permissions.length === permissionList.length}
                    type="checkbox"
                    className="cursor-pointer"
                  />
                  Select All
                </div>
              </div>
              <div className="border rounded-md shadow-sm flex gap-5 my-3">
                <ul className="border-r w-fit ">
                  {permissionCategoryList.map((item, index) => (
                    <li
                      onClick={() => setCategoryIndex(index)}
                      key={`${index}_permisstion_category_list_key`}
                      className={`cursor-pointer text-sm hover:bg-gray-100 p-2 ${
                        index === categoryIndex ? "bg-gray-200" : ""
                      }`}
                    >
                      {item.title}
                    </li>
                  ))}
                </ul>
                <div className="flex whitespace-nowrap flex-col">
                  {permissionList.map((item, index) => {
                    const isChecked = !!permissions.find(
                      (i) => Number(i) === Number(item.id)
                    );
                    if (
                      item.category ===
                      permissionCategoryList[categoryIndex].key
                    ) {
                      return (
                        <span
                          className="flex items-center gap-2  py-1"
                          key={`add_user_permision_list_view_key_${index}`}
                        >
                          <input
                            onChange={(e) =>
                              handleSetPermissions(item.id, e.target.checked)
                            }
                            className="cursor-pointer"
                            type="checkbox"
                            checked={isChecked}
                          />

                          <p className="text-sm font-medium">{item.title}</p>
                        </span>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <div className="pt-3">
              {isBtnLoader ? (
                <LoadingButton title="Sending Invite" />
              ) : (
                <button type="submit" className="btn">
                  Send Invite
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddUser;
