import React, { useEffect, useState } from "react";
import { generateDates } from "../../../utils/helperFunctions";
import { Chart, registerables } from "chart.js";
import dayjs from "dayjs";
Chart.register(...registerables);

const AppUserActionChart = ({ dateRange, appUserActionCount }) => {
  const labels = generateDates(
    dateRange.fromDate,
    dateRange.toDate,
    "DD-MM-YYYY"
  );

  const [actionData, setActionData] = useState();

  useEffect(() => {
    let formattedData = {};
    if (Array.isArray(appUserActionCount)) {
      appUserActionCount.forEach((item) => {
        const actionName = item.action_type.split("_").join(" ");
        const createdDate = dayjs(item.created_date.split("T")[0]).format(
          "DD-MM-YYYY"
        );
        formattedData = {
          ...formattedData,
          [actionName]: {
            ...formattedData[actionName],
            [createdDate]: item.count,
          },
        };
      });
    }
    const formattedDataKey = Object.keys(formattedData);
    const formattedDataArr = formattedDataKey.map((i) => ({
      label: i,
      data: labels.map((date) => {
        const getValue = formattedData[i][date];
        return Number(getValue || 0);
      }),
      fill: false,
      tension: 0.4,
    }));
    setActionData(formattedDataArr);
    // eslint-disable-next-line
  }, [appUserActionCount, dateRange]);

  const data = {
    labels: labels,
    datasets: actionData,
  };
  const config = {
    type: "bar",
    data: data,
    options: {
      responsive: true,
      maintainAspectRatio: true,
      interaction: {
        intersect: false,
      },
      scales: {
        x: {
          display: true,
          title: {
            display: true,
          },
        },
        y: {
          display: true,
          title: {
            display: true,
          },
          suggestedMin: 0,
        },
      },
    },
  };

  useEffect(() => {
    const ctx = document
      .getElementById("app-user-action-count-chart")
      .getContext("2d");
    const myChart = new Chart(ctx, config);
    return () => {
      myChart.destroy();
    };
    // eslint-disable-next-line
  }, [actionData]);
  return (
    <div className="w-full">
      <div className="rounded-lg shadow-sm mb-4">
        <div className="rounded-lg bg-white shadow-md  relative overflow-hidden">
          <div className="px-3 pt-8 pb-10 text-center relative z-10">
            <div className="flex justify-center gap-10">
              <div>
                <h4 className="text-sm uppercase text-gray-500 leading-tight">
                  APP USER ACTIONS
                </h4>
              </div>
            </div>
            <div>
              <canvas
                className="w-[100%] max-h-[300px]"
                id="app-user-action-count-chart"
              ></canvas>
            </div>
            {/* <p className="text-xs text-green-500 leading-tight">▲ 57.1%</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUserActionChart;
