import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Search from "../../components/Search";
import UserListTable from "../../components/UserManagement/UserListTable";
import AddUser from "../../components/UserManagement/AddUser";
import useAxios from "../../hooks/useAxios";
import Loader from "../../components/Loader/Loader";
import useGetAccess from "../../hooks/useGetAccess";
import { ADD_USER_KEY, VIEW_USER_KEY } from "../../utils/AccessList/accessList";
import { useSelector } from "react-redux";
import {
  accountStatusOptions,
  userRoleOptions,
  portalAccessOptions,
} from "../../utils/selectBoxValues";

const ROLE_FILTER = "roleId";
const ACCOUNT_STATUS_FILTER = "accountStatus";
const PORTAL_ACCESS_FILTER = "portalAccess";
const SEARCH_FILTER = "filter";

const UserManagement = () => {
  const axios = useAxios();
  const getAccess = useGetAccess();
  const [isAddUserModal, setAddUserModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const [pageComponent, setPageComponent] = useState(<Loader />);
  const [filters, setFilters] = useState({
    roleId: "",
    accountStatus: "",
    portalAccess: "",
    filter: "",
  });
  const filterRef = useRef({
    roleId: null,
    accountStatus: null,
    portalAccess: null,
    filter: null,
  });
  const [clearSearchValue, setClearSearchValue] = useState(false);
  const { roleId, accountStatus, portalAccess, filter } = filters;
  const isFilerApplied = roleId || accountStatus || portalAccess || filter;
  // ACCESS LIST
  const user = useSelector((state) => state.auth);
  const isUserDataLoader = useSelector((state) => state.auth.userDataLoader);

  const [accessList, setAccessList] = useState({
    hasAddUserAccess: getAccess(ADD_USER_KEY),
    hasViewUserAccess: getAccess(VIEW_USER_KEY),
  });

  useEffect(() => {
    setAccessList({
      hasAddUserAccess: getAccess(ADD_USER_KEY),
      hasViewUserAccess: getAccess(VIEW_USER_KEY),
    });
  }, [user]);

  const { hasAddUserAccess, hasViewUserAccess } = accessList;

  // ACCESS LIST

  const handleSetUserDataFilter = (e, source) => {
    setFilters((prev) => ({
      ...prev,
      [source]: e,
    }));
  };

  const formatFetchUserUrl = () => {
    let baseUrl = "auth/getAllUserList";
    let queryFlag = true;
    const filterKeys = Object.keys(filters);
    filterKeys.forEach((key) => {
      const value = filters?.[key];
      if (value || Number.isInteger(value)) {
        baseUrl += `${queryFlag ? "?" : "&"}${key}=${value}`;
        queryFlag = false;
      }
    });
    return baseUrl;
  };

  const fetchUserData = async () => {
    const url = formatFetchUserUrl();
    setUserData(null);
    setPageComponent(<Loader />);
    try {
      const controller = new AbortController();
      const signal = controller.signal;
      await axios
        .get(url, { signal })
        .then((res) => {
          if (res?.data?.status) {
            setUserData(res.data.results);
          } else {
            setPageComponent("No data found");
          }
        })
        .catch((err) => {
          // console.error(err)
          setPageComponent("No data found");
        });
      return () => {
        controller.abort();
      };
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (hasViewUserAccess) {
      fetchUserData();
    } else {
      setPageComponent("No access to view user list");
    }
  }, [filters]);

  const handleClearFilter = () => {
    const selectFilterKeys = [
      ROLE_FILTER,
      ACCOUNT_STATUS_FILTER,
      PORTAL_ACCESS_FILTER,
    ];
    selectFilterKeys.forEach((item) => {
      filterRef?.current?.[item] && filterRef.current[item].clearValue();
    });
    setClearSearchValue((prev) => !prev);
    setFilters({
      roleId: "",
      accountStatus: "",
      portalAccess: "",
      filter: "",
    });
  };

  return (
    <div className="p-10">
      <AddUser
        fetchUserData={fetchUserData}
        open={isAddUserModal}
        close={() => setAddUserModal(false)}
      />
      <div className="flex justify-between">
        <h1 className="text-xl font-semibold">Staff List</h1>
        {hasAddUserAccess && (
          <button
            className="btn btn--black"
            onClick={() => setAddUserModal(true)}
          >
            Add Staff
          </button>
        )}
      </div>
      <div className="flex justify-between py-5">
        <div className="flex gap-3 items-center">
          <Select
            ref={(ele) => (filterRef.current.roleId = ele)}
            placeholder="Role"
            options={userRoleOptions}
            className="custom-select-container"
            onChange={(e) => handleSetUserDataFilter(e?.value, ROLE_FILTER)}
          />
          <Select
            ref={(ele) => (filterRef.current.accountStatus = ele)}
            placeholder="Account Status"
            options={accountStatusOptions}
            className="custom-select-container"
            onChange={(e) =>
              handleSetUserDataFilter(e?.value, ACCOUNT_STATUS_FILTER)
            }
          />
          <Select
            ref={(ele) => (filterRef.current.portalAccess = ele)}
            placeholder="Portal Access"
            options={portalAccessOptions}
            className="custom-select-container"
            onChange={(e) =>
              handleSetUserDataFilter(e?.value, PORTAL_ACCESS_FILTER)
            }
          />
          {isFilerApplied ? (
            <span
              className="font-medium text-primary-600 text-sm cursor-pointer"
              onClick={handleClearFilter}
            >
              Clear Filters
            </span>
          ) : null}
        </div>
        <Search
          clearValue={clearSearchValue}
          onChange={(e) => handleSetUserDataFilter(e, SEARCH_FILTER)}
        />
      </div>
      <div className="">
        {!isUserDataLoader && Array.isArray(userData) ? (
          <UserListTable data={userData} />
        ) : (
          <div className="flex justify-center items-center w-full h-[calc(100vh-300px)]">
            {isUserDataLoader ? <Loader /> : pageComponent}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserManagement;
