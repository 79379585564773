import React, { useState, useEffect } from "react";

const Search = (props) => {
  const [inputValue, setInputValue] = useState("");
  
  const searchHandle = (e) => {
    const value = e
    if (props?.onChange) {
      props.onChange(value);
    }
    setInputValue(value);
  };

  useEffect(() => {
    setInputValue("");
  }, [props?.clearValue]);

  return (
    <div className={`search-box mx-${props.mx || "6"} relative`}>
      <input
        className="rounded-md max-w-[240px] h-[38px]"
        type="text"
        value={inputValue}
        placeholder={props?.placeholder ? props.placeholder : "search"}
        onChange={(e) => searchHandle(e.target.value)}
      />
      <div className="absolute left-4 top-2">
        <i className="material-symbols-outlined text-xl text-gray-400">
          search
        </i>
      </div>
    </div>
  );
};

export default Search;
