import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Login from "./containers/LogIn";
import StarterLayout from "./Layout/StarterLayout";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPassword";
import DashboardLayout from "./Layout/DashboardLayout";
import Dashboard from "./containers/Dashboard/Dashboard";
import AppBanners from "./containers/AppBanners";
import NotificationManagement from "./containers/NotificationManagement";
import UserManagement from "./containers/UserManagement";
import PageNotFound from "./components/PageNotFound";
import CreatePushNotification from "./components/NotificationManagement/CreatePushNotification";
import ViewStaff from "./components/UserManagement/ViewStaff";
import Profile from "./containers/ProfileManagement";
import ProfileSettings from "./components/Profile/ProfileSettings";
import Segment from "./containers/NotificationManagement/Segment";
import PersistLogin from "./components/PersistLogin/PersistLogin";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./components/Unauthorized";
import AppSettings from "./containers/AppSettings";
import StaticSite from "./containers/AppSettings/StaticSite";
import AppUserList from "./containers/AppUserList/AppUserList";
import AppLog from "./containers/AppSettings/AppLog";
import VideoBanners from "./containers/VideoBanners";
import DatabaseLogs from "./containers/DatabaseLogs";


const ROLES = {
  admin: 1,
  staff: 2,
  superAdmin: 3,
  dev: 4,
};

function App() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route path="unauthorized" element={<Unauthorized />} />
      {/* STARTER LAYOUT ROUTES STARTS */}
      <Route path="/" element={<StarterLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>
      {/* STARTER LAYOUT ROUTES ENDS */}

      {/* DASHBOARD ROUTES STARTS */}
      <Route element={<PersistLogin />}>
        <Route path="dashboard" element={<DashboardLayout />}>
          <Route path="" element={<Dashboard />} />
          <Route path="app-user-list" element={<AppUserList />} />
          <Route path="profile">
            <Route path="" element={<Profile />} />
            <Route path="settings" element={<ProfileSettings />} />
          </Route>
          <Route path="app-banners">
            <Route path="banner-list" element={<AppBanners />} />
            <Route path="video-banner" element={<VideoBanners />} />
          </Route>
          <Route path="notification-management">
            <Route
              path="notification-list"
              element={<NotificationManagement />}
            />
            <Route path="segments" element={<Segment />} />
            <Route
              path="create-push-notification"
              element={<CreatePushNotification />}
            />
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={[ROLES.superAdmin, ROLES.admin, ROLES.dev]} />
            }
          >
            <Route path="app-setting">
              <Route path="" element={<AppSettings />} />
              <Route path="static-site" element={<StaticSite />} />
              <Route element={<RequireAuth allowedRoles={[ROLES.dev, ROLES.superAdmin]} />}>
                <Route path="app-logs" element={<AppLog />} />
                <Route path="database-logs" element={<DatabaseLogs />} />
              </Route>
            </Route>
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={[ROLES.admin, ROLES.superAdmin, ROLES.dev]} />
            }
          >
            <Route path="user-management">
              <Route path="manage-staff" element={<UserManagement />} />
              <Route path="view-staff" element={<ViewStaff />} />
            </Route>
          </Route>
        </Route>
      </Route>
      {/* DASHBOARD ROUTES STARTS */}
    </Routes>
  );
}

export default App;
