import React, { useId } from "react";

const SegmentFilters = ({
  showFilters,
  selectedFilters,
  handleSelectFilter,
  handleRemoveOperator,
  lastOperator,
}) => {
  const filters = [
    {
      id: `${useId()}-segmentFilter`,
      icon: "arrow_circle_left",
      title: "First Session",
      description:
        "The first date/time the device communication with our servers",
      key: "first_session",
      filters: [
        {
          title: "greater than",
          operator: ">",
          value: "number",
          description: "hours ago",
        },
        {
          title: "less than",
          operator: "<",
          value: "text",
          description: "hours ago",
        },
      ],
    },
    {
      id: `${useId()}-segmentFilter`,
      icon: "arrow_circle_right",
      title: "Last Session",
      description:
        "The most date/time the device communication with our servers",
      key: "last_session",
      filters: [
        {
          title: "greater than",
          operator: ">",
          value: "number",
          description: "hours ago",
        },
        {
          title: "less than",
          operator: "<",
          value: "number",
          description: "hours ago",
        },
      ],
    },
    {
      id: `${useId()}-segmentFilter`,
      icon: "money",
      title: "Amount Spend",
      description:
        "The most date/time the device communication with our servers",
      key: "amount_spent",
      filters: [
        {
          title: "greater than",
          operator: ">",
          value: "number",
          description: "hours ago",
        },
        {
          title: "less than",
          operator: "<",
          value: "number",
          description: "hours ago",
        },
        {
          title: "is",
          operator: "=",
          value: "number",
          description: "hours ago",
        },
      ],
    },
    {
      id: `${useId()}-segmentFilter`,
      icon: "list",
      title: "Session Count",
      description:
        "Total number of times the device has opened your app or website",
      key: "session_count",
      filters: [
        {
          title: "greater than",
          operator: ">",
          value: "number",
          description: "sessions",
        },
        {
          title: "less than",
          operator: "<",
          value: "number",
          description: "sessions",
        },
        {
          title: "is",
          operator: "=",
          value: "number",
          description: "sessions",
        },
        {
          title: "is not",
          operator: "!=",
          value: "number",
          description: "sessions",
        },
      ],
    },
    {
      id: `${useId()}-segmentFilter`,
      icon: "subject",
      title: "Language",
      description:
        "Total number of times the device has opened your app or website",
      key: "language",
      filters: [
        {
          title: "is",
          operator: "=",
          value: "select",
          description: "",
        },
        {
          title: "is not",
          operator: "!=",
          value: "select",
          description: "",
        },
      ],
    },
    {
      id: `${useId()}-segmentFilter`,
      icon: "sell",
      title: "User Tag",
      description: "Tags you have added to the user's device",
      key: "tag",
      filters: [
        {
          title: "greater than",
          operator: ">",
          description: "",
          key: "text",
          value: "text",
        },
        {
          title: "less than",
          operator: "<",
          description: "",
          key: "text",
          value: "text",
        },
        {
          title: "is",
          operator: "=",
          description: "",
          key: "text",
          value: "text",
        },
        {
          title: "is not",
          operator: "!=",

          description: "",
          key: "text",
          value: "text",
        },
        {
          title: "exists",
          operator: "exists",
          description: "",
          key: "text",
          // value: "text",
        },
        {
          title: "does not exists",
          operator: "not_exists",
          description: "",
          key: "text",
          // value: "text",
        },
        {
          title: "time elapsed greater than",
          operator: "time_elapsed_gt",

          description: "",
          key: "text",
          value: "number",
        },
        {
          title: "time elapsed less than",
          operator: "time_elapsed_lt",
          key: "text",
          value: "number",
        },
      ],
    },
    // {
    //   id: `${useId()}-segmentFilter`,
    //   icon: "where_to_vote",
    //   title: "Location",
    //   description: "Radius in meters from a particular geocordinate",
    //   key: "tag",
    //   filters: [
    //     {
    //       title: "Radius",
    //       operator: "radius",
    //       key: "number",
    //       // value: "number",
    //     },
    //     {
    //       title: "Latitude",
    //       operator: "lat",
    //       middle: "number",
    //       // value: "number",
    //     },
    //     {
    //       title: "Longitude",
    //       operator: "long",
    //       value: "number",
    //       // value: "number",
    //     },
    //   ],
    // },
    {
      id: `${useId()}-segmentFilter`,
      icon: "language",
      title: "Country",
      description: "Radius in meters from a particular geocordinate",
      key: "country",
      filters: [
        {
          title: "is",
          operator: "=",
          value: "select",
        },
        {
          title: "is not",
          operator: "!=",
          value: "select",
        },
      ],
    },
    // {
    //   id: `${useId()}-segmentFilter`,
    //   icon: "smartphone",
    //   title: "Device Type",
    //   description: "Included all test users, which can be set up in all page",
    //   key: "device_type",
    //   filters: [
    //     {
    //       title: "is",
    //       operator: "=",
    //       value: "select",
    //     },
    //   ],
    // },
    // {
    //   id: `${useId()}-segmentFilter`,
    //   icon: "schedule",
    //   title: "Total Duration",
    //   description:
    //     "The total number of seconds the user's device has had your app open",
    // },
  ];
  const isModalStyle = showFilters && Number(selectedFilters.length) > 0;
  return (
    <div
      className={`${
        isModalStyle ? "p-3 shadow-lg border rounded-md mt-2" : ""
      }`}
    >
      {isModalStyle ? (
        <div className="flex justify-between items-center pb-2 px-2">
          <span className="text-sm font-medium">
            {lastOperator.toUpperCase()} Filter
          </span>
          <span
            className="material-symbols-outlined cursor-pointer"
            onClick={handleRemoveOperator}
          >
            close
          </span>
        </div>
      ) : null}
      <div className="text-sm">
        {filters.map((item, index) => {
          return (
            <div
              className="flex py-2"
              key={`segment_filter_listing_key_${index}`}
            >
              <div className="bg-gray-200 min-w-[180px] font-medium p-4 flex items-center gap-2">
                <span className="material-symbols-outlined text-primary-800">
                  {item.icon}
                </span>
                <p>{item.title}</p>
              </div>
              <div className="bg-gray-50 p-4 flex justify-between gap-2 w-full">
                <p className="">{item.description}</p>{" "}
                <span
                  onClick={() => handleSelectFilter(item)}
                  className="material-symbols-outlined cursor-pointer text-primary-800"
                >
                  add
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SegmentFilters;
