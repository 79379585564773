import React, { useEffect, useState } from "react";
import AppBannerData from "./AppBannerData";

const APP_BANNER_TAB = 1;
const CATEGORY_BANNER_TAB = 2;

const appBannerTableHeadCols = [
  { title: "Image/Banner" },
  { title: "Title" },
  { title: "Start Date" },
  { title: "End Date" },
  { title: "Active" },
  { title: "Status" },
  { title: "Click Count" },
  { title: "" },
];

const appBannerSlidingTableHeadCols = [
  { title: "Image/Banner" },
  { title: "Title" },
  { title: "Active" },
  { title: "" },
];

const categoryBannerTableHeadCols = [
  { title: "Image/Banner" },
  { title: "Category" },
  { title: "" },
];

const AppBannerTable = ({
  bannerList,
  fetchBannerList,
  getCollectionBannerDatas,
  toggleTab,
  isCategoryBannerTab,
  categoryBanner,
  collectionList
}) => {
  const [tableHeadCols, setTableHeadCols] = useState(
    Number(toggleTab) === 3 ? appBannerSlidingTableHeadCols : isCategoryBannerTab ? categoryBannerTableHeadCols : appBannerTableHeadCols
  );

  useEffect(() => {
    setTableHeadCols(
      Number(toggleTab) === 3 ? appBannerSlidingTableHeadCols : isCategoryBannerTab ? categoryBannerTableHeadCols : appBannerTableHeadCols
    );
  }, [toggleTab]);

  return (
    <div className={`grid grid-cols-${isCategoryBannerTab ? "3" : "8"} items-center py-5`}>
      {tableHeadCols.map((col, index) => (
        <p
          className={`text-center font-medium`}
          key={`table_head_col_app_banner_${index}`}
        >
          {col.title}
        </p>
      ))}
      {bannerList.map((data, index) => {
        return (
          <AppBannerData
            fetchBannerList={fetchBannerList}
            getCollectionBannerDatas={getCollectionBannerDatas}
            item={data}
            key={`app_banner_data_${index}`}
            isCategoryBannerTab={isCategoryBannerTab}
            categoryBanner={categoryBanner}
            collectionList={collectionList}
            toggleTab={toggleTab}
          />
        );
      })}
    </div>
  );
};

export default AppBannerTable;
