import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

const UserProfileMenu = (props) => {
  const { menuBtnAction } = props;
  const menuItems = [
    { title: "My Profile", icon: "account_circle", action: menuBtnAction?.viewProfilePage },
    { title: "Manage Account", icon: "manage_accounts", action: menuBtnAction?.viewSettingsPage },
    { title: "Support", icon: "support_agent", action: null },
    { title: "Log Out", icon: "logout", action: menuBtnAction?.logOutBtn },
  ];
  return (
    <>
    <Menu as="div" className="relative inline-block text-left select-none">
      <div>
        <Menu.Button className="inline-flex w-full justify-center py-2 text-sm  text-gray-700 hover:bg-gray-50">
          <span className="material-symbols-outlined">arrow_drop_down</span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none select-none">
          <div className="py-1">
            {menuItems.map((item, index) => (
              <Menu.Item key={`user_data_menu_option_${index}`}>
                <div
                  onClick={item?.action}
                  className={
                    "text-gray-700 flex cursor-pointer hover:font-medium p-3 gap-2 hover:text-primary-800 hover:bg-gray-100"
                  }
                >
                  <span className="material-icons text-primary-800">{item.icon}</span>
                  <span className="">{item.title}</span>
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  </>
  )
}

export default UserProfileMenu