import React from "react";
import Modal from "react-responsive-modal";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
const DeleteVideoBanner = ({ bannerData, close, fetchBannerList }) => {
  const axios = useAxios();

  const deleteBanner = async () => {
    const id = bannerData?.id;
    if (!id) {
      toast.error("Something went wrong, Try again later");
      return;
    }
    await axios.delete(`banner/deleteBanner?id=${id}`).then((res) => {
      if (res?.data?.status) {
        toast.success("Video Banner deleted")
        fetchBannerList();
        close()
      } else {
        toast.error(res?.data?.message || "Something went wrong, try again")
      }
    }).catch((err) => {
      console.error(err)
    })
  }

  return (
    <Modal open={bannerData} onClose={close} center showCloseIcon={false}>
      <div className="min-w-[400px]">
        <div className="flex justify-between">
          <h1 className="font-medium">Delete video banner</h1>
          <button type="button">
            <span onClick={close} className="material-symbols-outlined">
              close
            </span>
          </button>
        </div>
        <div className="text-center py-3">
          Are you sure <br /> do you want to delete this video banner?
        </div>
        <div className="flex flex-col gap-2 pt-5">
          <button onClick={deleteBanner} className="btn btn--red">Delete</button>
          <button onClick={close} className="btn btn--border">Cancel</button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteVideoBanner;
