import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { DatePicker } from "antd";
// import BannerImageCropper from "./BannerImageCropper";
import { useForm, Controller } from "react-hook-form";
import { getFormErrMsg } from "../../../utils/helperFunctions";
import dayjs from "dayjs";
import useAxios from "../../../hooks/useAxios";
import { toast } from "react-toastify";
import useFetchFile from "../../../hooks/useFetchFile";
import {
  imageFileTypeConst,
  bannerImagePathConst,
} from "../../../consts/fileConsts";
import LoadingButton from "../../LoadingButton";
import Select from "react-select";

const START_DATE = "startDate";
const END_DATE = "endDate";
const APP_BANNER_TAB = 1;
const CATEGORY_BANNER_TAB = 2;
const CATEGORY_SLIDING_BANNER = 3;

const AddBannerForm = ({
  open,
  close,
  fetchBannerList,
  isEdit,
  bannerData,
  isUpdate,
  toggleTab,
  isCategoryBannerTab,
  getCollectionBannerDatas,
  collectionList,
}) => {
  const axios = useAxios();
  const fetchFile = useFetchFile();
  const [bannerImage, setBannerImage] = useState();
  // const [bannerImageAfterCrop, setBannerImageAfterCrop] = useState("");
  const [onBannerImgMouseOver, setBannerImgMouseOver] = useState(false);
  const [imageErrMsg, setImgErrMsg] = useState({});
  const [dateFieldState, setDateFieldState] = useState({});
  const [bannerFormData, setBannerFormData] = useState({});
  const [isDefaultBanner, setIsDefaultBanner] = useState(
    bannerData?.is_default || false
  );
  const [isBtnLoader, setBtnLoader] = useState(false);

  const bannerFormDataInitialValue = {
    bannerDescription: bannerData?.description,
    bannerTitle: bannerData?.title,
    url: bannerData?.url,
    startDate: bannerData?.start_date,
    endDate: bannerData?.end_date,
    isDefault: bannerData?.is_default || toggleTab === CATEGORY_SLIDING_BANNER,
    collection: bannerData?.collection,
  };

  const [collectionProductCount, setCollectionProductCount] = useState(
    bannerData?.collection?.productsCount || 0
  );

  const getBannerImg = async () => {
    const imgSrc = await fetchFile(
      bannerData?.image,
      imageFileTypeConst,
      bannerImagePathConst
    );
    setBannerImage(imgSrc);
    // setBannerImageAfterCrop(imgSrc);
  };
  useEffect(() => {
    getBannerImg();
  }, [bannerData?.image]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: bannerFormDataInitialValue,
  });

  const handleResetForm = () => {
    reset();
    // setBannerImageAfterCrop("");
  };

  const handleClose = () => {
    setBannerFormData({});
    setDateFieldState({});
    handleResetForm();
    close();
  };

  const onBannerImageCropDone = (imgCroppedArea) => {
    const canvasElement = document.createElement("canvas");
    canvasElement.width = imgCroppedArea.width;
    canvasElement.height = imgCroppedArea.height;
    const context = canvasElement.getContext("2d");

    let bannerImgObj = new Image();
    bannerImgObj.src = bannerImage;
    bannerImgObj.onload = () => {
      context.drawImage(
        bannerImgObj,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      const dataURL = canvasElement.toDataURL("image/jpeg");
      // setBannerImageAfterCrop(dataURL);
      setBannerImage("");
      handleSetBannerFormData(dataURL, "image");
    };
  };

  const onCropCancel = () => {
    setBannerImage("");
  };

  const handleChangeImgInput = (e) => {
    setImgErrMsg({});
    const inputFile = e?.target?.files?.[0] || null;
    if (inputFile) {
      const { size, type } = inputFile;
      const fileSizeLimit = 1024000;
      const allowedFileType = /image/;
      const isValidImageFile = allowedFileType.test(type);
      if (isValidImageFile) {
        if (size < fileSizeLimit) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(inputFile);
          fileReader.onload = () => {
            setBannerImage(fileReader.result);
            handleSetBannerFormData(fileReader.result, "image");
          };
        } else {
          setImgErrMsg((prev) => ({
            ...prev,
            fileError:
              "Uploaded image file is too large, try uploading image less than 1 MB",
          }));
        }
      } else {
        setImgErrMsg((prev) => ({
          ...prev,
          fileError: `Uploaded file is not a valid image file,
        Only image files are allowed`,
        }));
      }
    }
  };

  const handleSetBannerFormData = (value, source) => {
    if (source === "isDefault") {
      setIsDefaultBanner(value);
    }
    if (source === "collection") {
      setCollectionProductCount(value?.productsCount || 0);
    }
    setBannerFormData((prev) => ({
      ...prev,
      [source]: value,
    }));
  };

  const dataURLtoFile = (dataurl, filename) => {
    try {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1;
      }
      return new File([u8arr], filename, { type: mime });
    } catch (err) {
      setImgErrMsg((prev) => ({
        ...prev,
        fileError:
          "Something went wrong with the uploaded image file, try again",
      }));
      return err;
    }
  };

  const handleChangeDateErrMsg = (source, errStatus = false, errMsg = "") => {
    setDateFieldState((prev) => ({
      ...prev,
      [source]: { ...prev[source], error: errStatus, message: errMsg },
    }));
  };

  const handleSetDateValue = (value, source) => {
    setDateFieldState((prev) => {
      return {
        ...prev,
        [source]: { value },
      };
    });
  };

  const handleDateChange = (e, source) => {
    const date = dayjs(e).toISOString();
    setValue(source, date);
    handleChangeDateErrMsg(source);
    handleSetDateValue(date, source);
    handleSetBannerFormData(date, source);
  };

  useEffect(() => {
    if (bannerData?.start_date) {
      setValue(START_DATE, bannerData?.start_date);
      handleSetDateValue(bannerData?.start_date, START_DATE);
    }
    if (bannerData?.end_date) {
      setValue(END_DATE, bannerData?.end_date);
      handleSetDateValue(bannerData?.end_date, END_DATE);
    }
  }, [bannerData]);

  const validateDateEntry = async (reqObj) => {
    let isValidDateEntries = true;
    if (!reqObj[START_DATE]) {
      isValidDateEntries = false;
      handleChangeDateErrMsg(START_DATE, true, "This field is required");
    } else {
      handleChangeDateErrMsg(START_DATE);
    }
    if (!reqObj[END_DATE]) {
      isValidDateEntries = false;
      handleChangeDateErrMsg(END_DATE, true, "This field is required");
    } else {
      handleChangeDateErrMsg(END_DATE);
    }
    return isValidDateEntries;
  };

  const createBanner = async (formData) => {
    setBtnLoader(true);
    await axios
      .post("banner/createBanner", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res?.data?.status) {
          if (isCategoryBannerTab) {
            getCollectionBannerDatas();
          } else {
            fetchBannerList();
          }
          
          toast.success(res?.data?.message || "Banner created");
          handleClose();
        } else {
          toast.error(
            res?.data?.message ||
              res?.response?.data?.message ||
              "Something went wrong try again"
          );
        }
      })
      .catch((err) => {
        // console.log(err);
        toast.error(
          err?.response?.data?.message || "Something went wrong try again"
        );
      })
      .finally(() => {
        setBtnLoader(false);
      });
  };

  // const createCategoryBanner = async (formData) => {
  //   setBtnLoader(true);
  //   await axios
  //     .post("banner/createCategoryBanner", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .then((res) => {
  //       if (res?.data?.status) {
  //         getCollectionBannerDatas();
  //         toast.success(res?.data?.message || "Banner created");
  //         handleClose();
  //       } else {
  //         toast.error(
  //           res?.data?.message ||
  //             res?.response?.data?.message ||
  //             "Something went wrong try again"
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       // console.log(err);
  //       toast.error(
  //         err?.response?.data?.message || "Something went wrong try again"
  //       );
  //     })
  //     .finally(() => {
  //       setBtnLoader(false);
  //     });
  // };

  const updateBanner = async () => {
    setBtnLoader(true);
    const objKeys = Object.keys(bannerFormData);
    if (objKeys?.length) {
      const formData = new FormData();
      if (Object.prototype.hasOwnProperty.call(bannerFormData, "image")) {
        const file = dataURLtoFile(bannerImage, "banner-image.jpeg");
        // const file = dataURLtoFile(bannerImageAfterCrop, "banner-image.jpeg");
        bannerFormData.image = file;
      }
      objKeys.forEach((item) => {
        const value = bannerFormData[item];
        if (item === "collection") {
          formData.append(item, JSON.stringify(value));
        } else {
          formData.append(item, value);
        }
      });
      formData.append("bannerId", bannerData?.id);

      await axios
        .put("banner/updateBanner", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          // console.log(res);
          if (res?.data?.status) {
            fetchBannerList();
            toast.success(res?.data?.message || "Banner updated");
            handleClose();
          } else {
            toast.error(
              res?.data?.message ||
                res?.response?.data?.message ||
                "Something went wrong try again"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            err?.response?.data?.message || "Something went wrong try again"
          );
        })
        .finally(() => {
          setBtnLoader(false);
        });
    } else {
      toast.info("Nothing to update");
      setBtnLoader(false);
      handleClose();
    }
  };

  const onSubmit = async (data) => {
    setImgErrMsg({});
    const {
      bannerTitle,
      bannerDescription,
      startDate,
      endDate,
      url,
      isDefault,
      collection,
    } = data;
    const isValidDates = await validateDateEntry(data);
    if (isValidDates || isDefault || isCategoryBannerTab) {
      if (isEdit && !isCategoryBannerTab) {
        return updateBanner();
      }
      // if (bannerImageAfterCrop) {
      if (bannerImage) {
        if (isCategoryBannerTab) {
          const reqObj = new FormData();
          // const file = dataURLtoFile(bannerImageAfterCrop, "banner-image.jpeg");
          const file = dataURLtoFile(bannerImage, "banner-image.jpeg");
          reqObj.append("image", file);
          reqObj.append("categoryId", bannerData?.id);
          reqObj.append("bannerTitle", bannerData?.title);
          reqObj.append("bannerType", 2);
          return createBanner(reqObj); //to create category banner
        }
        const todayIsoDate = dayjs().toISOString();
        const formData = new FormData();
        const file = dataURLtoFile(bannerImage, "banner-image.jpeg");
        // const file = dataURLtoFile(bannerImageAfterCrop, "banner-image.jpeg");
        formData.append("image", file);
        formData.append("bannerTitle", bannerTitle);
        formData.append("bannerDescription", bannerDescription);
        formData.append("startDate", isDefault ? todayIsoDate : startDate);
        formData.append("endDate", isDefault ? todayIsoDate : endDate);
        formData.append(
          "collection",
          collection ? JSON.stringify(collection) : {}
        );
        formData.append("isDefault", isDefault);
        formData.append("bannerType", toggleTab === CATEGORY_SLIDING_BANNER ? CATEGORY_SLIDING_BANNER : APP_BANNER_TAB); //convert this to varibale
        return createBanner(formData);
      } else {
        setImgErrMsg((prev) => ({
          ...prev,
          fieldError: "Banner image is required",
        }));
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose} center={true}>
      <div className="w-auto">
        <h1 className="font-semibold text-xl">
          {isCategoryBannerTab
            ? `Update Category`
            : isUpdate
            ? "Update"
            : "Add"}{" "}
          {toggleTab === CATEGORY_SLIDING_BANNER ? "Category Sliding" : ""} Banner
        </h1>
        <span className="text-red-600 font-medium text-sm">
          {imageErrMsg?.fileError}
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 py-5">
            <div className="flex justify-between gap-5">
              <div className="w-[512px] h-[270px]">
                <div className="flex  rounded-md gap-1 justify-center items-center w-full h-full  bg-[#EDEDED]">
                  {
                    // bannerImage ? (
                    //   <BannerImageCropper
                    //     image={bannerImage}
                    //     onCropDone={onBannerImageCropDone}
                    //     onCropCancel={onCropCancel}
                    //   />
                    // ) :
                    // bannerImageAfterCrop ?
                    bannerImage ? (
                      <>
                        <div
                          onMouseLeave={() => setBannerImgMouseOver(false)}
                          className="w-full relative "
                        >
                          {onBannerImgMouseOver ? (
                            <div className="absolute backdrop-brightness-50 flex justify-center items-center  w-full h-full z-50 rounded-md">
                              <p
                                className="cursor-pointer text-white"
                                onClick={() => setBannerImage("")}
                                // onClick={() => setBannerImageAfterCrop("")}
                              >
                                Change Image
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          <img
                            onMouseOver={() => setBannerImgMouseOver(true)}
                            // src={bannerImageAfterCrop}
                            src={bannerImage}
                            className=" w-[512px] h-[270px] rounded-md object-cover"
                            alt="cropped image"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <label
                          htmlFor="input-banner-image"
                          className="flex flex-col items-center cursor-pointer"
                        >
                          <img
                            src="/images/imagedummy.png"
                            alt="insert image"
                          />
                          <h6>Browse Image</h6>
                        </label>
                        <input
                          id="input-banner-image"
                          onChange={handleChangeImgInput}
                          type="file"
                          hidden
                          accept="image/*"
                        />
                      </>
                    )
                  }
                </div>
                <span className="text-red-600 font-medium text-sm">
                  {imageErrMsg?.fieldError}
                </span>
              </div>

              {!isCategoryBannerTab && (
                <div className="w-[512px] h-[270px]">
                  <textarea
                    {...register("bannerDescription", {
                      required: "This field is required",
                      onChange: (e) =>
                        handleSetBannerFormData(
                          e.target.value,
                          "bannerDescription"
                        ),
                    })}
                    placeholder="Description"
                    className="w-full h-full"
                  ></textarea>
                  {errors?.bannerDescription &&
                    getFormErrMsg("bannerDescription", errors)}
                </div>
              )}
            </div>
            {!isCategoryBannerTab ? (
              <>
                <div className="flex justify-between gap-5 pt-2">
                  <div className="w-full">
                    <label>Title</label>
                    <input
                      {...register("bannerTitle", {
                        required: "This field is required",
                        onChange: (e) =>
                          handleSetBannerFormData(
                            e.target.value,
                            "bannerTitle"
                          ),
                      })}
                      className=""
                      type="text"
                    />
                    {errors?.bannerTitle &&
                      getFormErrMsg("bannerTitle", errors)}
                  </div>
                  <div className="w-full">
                    <label className="flex items-center gap-2">
                      Link to Collection{" "}
                      <span className="text-xs rounded-lg bg-green-50 border border-green-600 font-medium px-2 ">
                        {collectionProductCount} products in this collection
                      </span>
                    </label>
                    <Controller
                      name="collection"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            placeholder={
                              collectionList?.find(
                                (i) => i?.node?.id === value?.id
                              )?.node?.title || "Select"
                            }
                            className="custom-select-container"
                            options={collectionList}
                            getOptionLabel={(option) => option.node.title}
                            getOptionValue={(option) => option.node.id}
                            onChange={(e) => {
                              onChange(e.node);
                              handleSetBannerFormData(e.node, "collection");
                            }}
                          />
                        );
                      }}
                    />

                    {errors?.url && getFormErrMsg("collection", errors)}
                  </div>
                </div>
                <div className="flex items-center border p-2 rounded-md w-fit gap-2">
                  <input
                    {...register("isDefault", {
                      onChange: (e) =>
                        handleSetBannerFormData(e.target.checked, "isDefault"),
                    })}
                    type="checkbox"
                  />
                  <label className="text-sm">
                    Click here to set this banner to shown default if there is
                    no active banners
                  </label>
                </div>

                {!isDefaultBanner && toggleTab !== CATEGORY_SLIDING_BANNER ? (
                  <div className="flex justify-between gap-5">
                    <div className="flex flex-col w-full">
                      <label>Start Date</label>
                      <DatePicker
                        value={
                          dateFieldState?.[START_DATE]?.value
                            ? dayjs(dateFieldState?.[START_DATE]?.value)
                            : ""
                        }
                        onChange={(e) => handleDateChange(e, START_DATE)}
                        className="h-10"
                        changeOnBlur={true}
                        showTime={{
                          defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                        }}
                        format="YYYY-MM-DD HH:mm a"
                        allowClear={false}
                      />

                      {dateFieldState?.startDate?.error &&
                        getFormErrMsg("startDate", dateFieldState)}
                    </div>
                    <div className="flex flex-col w-full">
                      <label>End Date</label>
                      <DatePicker
                        value={
                          dateFieldState?.[END_DATE]?.value
                            ? dayjs(dateFieldState?.[END_DATE]?.value)
                            : ""
                        }
                        onChange={(e) => handleDateChange(e, END_DATE)}
                        className="h-10"
                        changeOnBlur={true}
                        showTime={{
                          defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                        }}
                        format="YYYY-MM-DD HH:mm a"
                        allowClear={false}
                      />
                      {dateFieldState?.endDate?.error &&
                        getFormErrMsg("endDate", dateFieldState)}
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              <div>
                <span className="btn btn--border w-full my-3">
                  {bannerFormDataInitialValue?.bannerTitle || ""}
                </span>
              </div>
            )}
            {/* <div className="flex items-center gap-2">
              <label>Default</label>
                  <input {
                    ...register("isDefault", {
                      onChange: (e) =>  handleSetBannerFormData(e.target.checked, "isDefault")
                    })
                  } type="checkbox"/>

            </div> */}
            <div className="flex justify-end">
              {isBtnLoader ? (
                <LoadingButton
                  title={
                    isCategoryBannerTab
                      ? `Updating Category Banner`
                      : isUpdate
                      ? "Updating App Banner"
                      : "Creating Banner"
                  }
                />
              ) : (
                <button type="submit" className="btn">
                  Save
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddBannerForm;
