import React from "react";

import AppStatDataSwiper from "./AppStatDataSwiper";

const MostViewedCategory = ({ data, isLoading, title, itemBaseUrl }) => {
  const results = data?.status ? data.results.nodes : null;
  return (
    <AppStatDataSwiper results={results} title={title} isLoading={isLoading} itemBaseUrl={itemBaseUrl}/>
  );
};

export default MostViewedCategory;
