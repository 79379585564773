import React, { useState, useEffect } from "react";
import AppKeyList from "./AppKeyList";
import AddAppKeyModal from "./AddAppKeyModal";
import useAxios from "../../hooks/useAxios";
import Loader from "../Loader/Loader";

const AppKey = () => {
  const [isOpenAddAppKeyModal, setOpenAddAppKeyModal] = useState(false);
  const axios = useAxios();
  const [appKeyList, setAppKeyList] = useState([]);
  const [isPageLoader, setPageLoader] = useState(false);

  const fetchAppKeyList = async () => {
    setPageLoader(true);
    await axios
      .get("auth/getAllAppKeyList")
      .then((res) => {
        if (res?.data?.status) {
          setAppKeyList(res.data.result);
        } else {
          setAppKeyList([]);
        }
      })
      .catch((err) => {
        setAppKeyList([]);
      })
      .finally(() => {
        setPageLoader(false);
      });
  };

  useEffect(() => {
    fetchAppKeyList();
  }, []);

  if (isPageLoader) {
    return (
      <div className="w-full h-[calc(100vh-230px)] flex justify-center items-center"><Loader/></div>
    )
  }

  return (
    <div className="overflow-x-auto">
      {isOpenAddAppKeyModal && (
        <AddAppKeyModal
          open={isOpenAddAppKeyModal}
          close={() => setOpenAddAppKeyModal(false)}
          fetchAppKeyList={fetchAppKeyList}
        />
      )}
      <div className="flex justify-end">
        <button className="btn mb-3" onClick={() => setOpenAddAppKeyModal(true)}>
          Add Key
        </button>
      </div>

      {appKeyList.length ? (
        <AppKeyList fetchAppKeyList={fetchAppKeyList} data={appKeyList} />
      ) : (
        "No data found"
      )}
    </div>
  );
};

export default AppKey;
