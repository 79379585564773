import React, { useEffect, useState, useRef } from "react";
import BannerOverview from "../../components/Widgets/BannerOverview";
import BannerActivities from "../../components/Widgets/BannerActivities";
import BannerVisitors from "../../components/Widgets/BannerVisitors";
import Notification from "../../components/Widgets/Notification";
import Outcomes from "../../components/Widgets/Outcomes/Outcomes";
import useGetAccess from "../../hooks/useGetAccess";
import AccessDenied from "../../components/AccessDenied";
import OrderStatitics from "../../components/Widgets/OrderStatitics";
import {
  VIEW_BANNER_ACTIVITIES_WIDGET,
  VIEW_BANNER_VISITORS_WIDGET,
  VIEW_NOTIFICATION_ACTIVITY_WIDGET,
  VIEW_BANNER_OVERVIEW_WIDGET,
  VIEW_OUTCOMES_WIDGET,
} from "../../utils/AccessList/accessList";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import AppStats from "../../components/Dashboard/AppStats";

const Dashboard = () => {
  const user = useSelector((state) => state.auth);
  const isUserDataLoader = useSelector((state) => state.auth.userDataLoader);
  const [isLoader, setIsLoader] = useState(true);
  const getAccess = useGetAccess();

  // ACCESS LIST

  const [accessList, setAccessList] = useState({
    hasViewBannerActivitiesAccess: getAccess(VIEW_BANNER_ACTIVITIES_WIDGET),
    hasViewBannerVisitorsAccess: getAccess(VIEW_BANNER_VISITORS_WIDGET),
    hasViewNotificationActivityAccess: getAccess(
      VIEW_NOTIFICATION_ACTIVITY_WIDGET
    ),
    hasViewBannerOverviewAccess: getAccess(VIEW_BANNER_OVERVIEW_WIDGET),
    hasViewOutcomesAccess: getAccess(VIEW_OUTCOMES_WIDGET),
  });

  useEffect(() => {
    setAccessList({
      hasViewBannerActivitiesAccess: getAccess(VIEW_BANNER_ACTIVITIES_WIDGET),
      hasViewBannerVisitorsAccess: getAccess(VIEW_BANNER_VISITORS_WIDGET),
      hasViewNotificationActivityAccess: getAccess(
        VIEW_NOTIFICATION_ACTIVITY_WIDGET
      ),
      hasViewBannerOverviewAccess: getAccess(VIEW_BANNER_OVERVIEW_WIDGET),
      hasViewOutcomesAccess: getAccess(VIEW_OUTCOMES_WIDGET),
    });
  }, [user]);

  const {
    hasViewBannerActivitiesAccess,
    hasViewBannerOverviewAccess,
    hasViewBannerVisitorsAccess,
    hasViewNotificationActivityAccess,
    hasViewOutcomesAccess,
  } = accessList;

  // ACCESS LIST

  const isAllNoAccess =
    !hasViewBannerActivitiesAccess &&
    !hasViewBannerVisitorsAccess &&
    !hasViewNotificationActivityAccess &&
    !hasViewBannerOverviewAccess &&
    !hasViewOutcomesAccess;

  useEffect(() => {
    setIsLoader(true);
    const delayTimer = setTimeout(() => {
      setIsLoader(false);
    }, 2000);

    return () => {
      clearTimeout(delayTimer);
    };
  }, [user.accessList, user.allPermissionList]);

  if (isLoader || isUserDataLoader) {
    return (
      <div className="w-full flex justify-center items-center h-[calc(100%-100px)]">
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={`p-10 bg-gray-50 min-w-[386px] min-h-[calc(100%-100px)] ${
        isAllNoAccess
          ? "flex justify-center items-center h-[calc(100%-100px)] w-full"
          : ""
      }`}
    >
      {isAllNoAccess ? (
        <AccessDenied />
      ) : (
        <div className={`grid grid-cols-3 justify-center gap-x-10 gap-y-10`}>
          <div className="min-h-[200px] col-span-3"><AppStats/></div>
          <div className="flex flex-wrap md950:flex-nowrap col-span-3 gap-10 justify-between w-full">
            {hasViewBannerActivitiesAccess && (
              <div className="min-h-[300px] w-full">
                {" "}
                <BannerActivities />
              </div>
            )}
            {hasViewBannerActivitiesAccess && (
              <div className="min-h-[300px] w-full">
                {" "}
                <OrderStatitics />
              </div>
            )}
            {/* {hasViewBannerVisitorsAccess && (
              <div className="min-h-[200px] w-full">
                <BannerVisitors />
              </div>
            )} */}
            {hasViewNotificationActivityAccess && (
              <div className="min-h-[300px] w-full">
                <Notification />
              </div>
            )}
          </div>

          {hasViewBannerOverviewAccess && (
            <div className="min-h-[200px] col-span-3">
              <BannerOverview />
            </div>
          )}
          {/* {hasViewOutcomesAccess && (
            <div className="min-h-[200px] col-span-3">
              <Outcomes />
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
