import React from 'react'
import AppKeyListData from './AppKeyListData'

const AppKeyList = ({data, fetchAppKeyList}) => {
  return (
    <div>
        {
            data.map((item, index) => (
                <AppKeyListData key={`app_key_item_${index}`} fetchAppKeyList={fetchAppKeyList} data={item}/>
            ))
        }
    </div>
  )
}

export default AppKeyList