import React, { useEffect, useState } from "react";
import AppBannerDataMenu from "./AppBannerDataMenu";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import useFetchFile from "../../hooks/useFetchFile";
import {
  bannerImagePathConst,
  imageFileTypeConst,
} from "../../consts/fileConsts";
import AddBannerForm from "./AddBanner/AddBanner";
import formatDate from "../../utils/FormatDateAndTime/formatDate";
import DeleteBanner from "./DeleteBanner";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import useGetAccess from "../../hooks/useGetAccess";
import { MANAGE_BANNER_STATUS_KEY } from "../../utils/AccessList/accessList";
import { useSelector } from "react-redux";

const AppBannerData = ({
  item,
  fetchBannerList,
  isCategoryBannerTab,
  getCollectionBannerDatas,
  categoryBanner,
  collectionList,
  toggleTab
}) => {
  const isCategorySlidingBannerTab = Number(toggleTab) === 3;
  const axios = useAxios();
  const getAccess = useGetAccess();

  const user = useSelector((state) => state.auth);

  const [accessList, setAccessList] = useState({
    hasManageBannerStatusAccess: getAccess(MANAGE_BANNER_STATUS_KEY),
  });

  useEffect(() => {
    setAccessList({
      hasManageBannerStatusAccess: getAccess(MANAGE_BANNER_STATUS_KEY),
    });
  }, [user]);

  const { hasManageBannerStatusAccess } = accessList;

  const {
    title,
    image: bannerImageUrl,
    start_date,
    end_date,
    active,
    status,
    id: bannerId,
    is_default,
    popularity
  } = item;


  const categoryBannerImg =
    categoryBanner?.find((i) => i?.id === bannerId)?.image || null;
  const image = isCategoryBannerTab ? categoryBannerImg : bannerImageUrl;

  const [bannerImg, setBannerImg] = useState();
  const fetchFile = useFetchFile();
  const [bannerFormModal, setBannerFormModal] = useState(false);
  const [deleteBannerModal, setDeleteBannerModal] = useState(false);

  const startDate = is_default ? "Not Applicable" : `${formatDate?.(start_date)?.dateString2 || ""} ${formatDate?.(start_date)?.localString2 || ""}`;
  const endDate = is_default ? "Not Applicable" : `${formatDate?.(end_date)?.dateString2 || ""} ${formatDate?.(end_date)?.localString2 || ""}`;
 
  const statusClass =
    status === "Completed"
      ? "text-green-800"
      : status === "In-Progress"
      ? "text-blue-800"
      : "text-yellow-800";

  const getBannerImg = async () => {
    const imgSrc = await fetchFile(
      image,
      imageFileTypeConst,
      bannerImagePathConst
    );
    setBannerImg(imgSrc || "/images/imagedummy.png");
  };
  useEffect(() => {
    getBannerImg();
  }, [image]);

  const handleBannerFormModal = (e) => {
    setBannerFormModal(e);
  };

  const handleDeleteBannerModal = (e) => {
    setDeleteBannerModal(e);
  };

  const menuBtnFn = {
    handleBannerFormModal,
    handleDeleteBannerModal,
  };

  const updateBannerStatus = async (e) => {
    await axios
      .patch("banner/updateBannerStatus", {
        bannerId,
        active: Number(e),
      })
      .then((res) => {
        if (res?.data?.status) {
          fetchBannerList();
          toast.success(res?.data?.message || "Banner status updated");
        } else {
          toast.error(res?.data?.message || "Failed to update banner status");
        }
      })
      .catch((err) => {
        // console.log(err)
        toast.error(
          err?.res?.data?.message || "Failed to update banner status"
        );
      });
  };

  return (
    <>
      {bannerFormModal ? (
        <AddBannerForm
          open={bannerFormModal}
          close={() => handleBannerFormModal(false)}
          isEdit={true}
          bannerData={item}
          fetchBannerList={fetchBannerList}
          isUpdate={true}
          getCollectionBannerDatas={getCollectionBannerDatas}
          isCategoryBannerTab={isCategoryBannerTab}
          collectionList={collectionList}
        />
      ) : null}
      {deleteBannerModal ? (
        <DeleteBanner
          fetchBannerList={fetchBannerList}
          bannerData={item}
          open={deleteBannerModal}
          close={() => handleDeleteBannerModal(false)}
          isCategoryBannerTab={isCategoryBannerTab}
          getCollectionBannerDatas={getCollectionBannerDatas}
        />
      ) : null}
      <div className="col-span-8 border rounded-md mt-5 py-3 font-medium">
        <div
          className={`grid ${
            isCategoryBannerTab ? "grid-cols-3" : "grid-cols-8"
          } items-center`}
        >
          <span className="mx-auto">
            <img
              src={bannerImg}
              className="object-cover rounded-md border w-36 h-16"
            />
          </span>
          <span className="text-center">{title}</span>
          {(!isCategoryBannerTab && !isCategorySlidingBannerTab) && (
            <span className="text-center">{startDate}</span>
          )}
          {(!isCategoryBannerTab && !isCategorySlidingBannerTab) && (
            <span className="text-center">{endDate}</span>
          )}
          {!isCategoryBannerTab && (
            <>
              {hasManageBannerStatusAccess ? (
                <span className="text-center">
                  <ToggleSwitch
                    getToggleState={updateBannerStatus}
                    isChecked={!!Number(active)}
                  />
                </span>
              ) : (
                <span
                  className={`text-center ${
                    !!Number(active) ? "text-primary-800" : "text-red-600"
                  }`}
                >
                  {!!Number(active) ? "Yes" : "No"}
                </span>
              )}
            </>
          )}
          {(!isCategoryBannerTab && !isCategorySlidingBannerTab) && (
            <>
              {is_default ? (
                <span className="text-center">Default</span>
              ) : (
                <span className={`text-center ${statusClass}`}>{status}</span>
              )}
            </>
          )}
          {(!isCategoryBannerTab && !isCategorySlidingBannerTab) && (
            <>
               <span className="text-center">{popularity}</span>
            </>
          )}

          <div className="text-center">
            <AppBannerDataMenu
              categoryBannerImg={categoryBannerImg}
              isCategoryBannerTab={isCategoryBannerTab}
              menuBtnFn={menuBtnFn}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppBannerData;
