import React, { useState } from "react";
// import AppKeyListDataMenu from "./AppKeyListDataMenu";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";

const AppKeyListData = ({ data, fetchAppKeyList }) => {
  const axios = useAxios();
  const title = data.title;
  const value = data.value;
  const accessKey = data.access_key;
  const appKeyId = data.id;
  const description = data?.description || "No info";

  const [isEdit, setEdit] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const [showDescription, setShowDescription] = useState(false);
  const [focusValue, setFocusValue] = useState(false);

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(value);
      toast.info("Value copied to clipboard");
    } catch (err) {
      toast.error("Failed to copy the value to the clipboard");
    }
  };

  const onSubmit = async () => {
    if (newValue !== value) {
      await axios
        .put("auth/updateAppKey", {
          id: appKeyId,
          value: newValue,
        })
        .then((res) => {
          if (res?.data?.status) {
            fetchAppKeyList();
            toast.success(res.data.message);
            setEdit(false);
          } else {
            toast.error("Failed to update app key data");
          }
        })
        .catch((err) => {
          // console.log(err, "err");
          toast.error("Failed to update app key data");
        });
    } else {
      setEdit(false);
    }
  };
  return (
    <div className="py-1 grid grid-cols-4 gap-3 ">
      <div
        onMouseEnter={() => setFocusValue(true)}
        onMouseLeave={() => setFocusValue(false)}
        className="border rounded-md px-2 py-1 hover:bg-gray-100 cursor-pointer"
      >
        <p className="font-medium w-[200px]">{title}</p>
        <p className="text-xs">
          access key:{" "}
          <span className="bg-green-50 break-words rounded-md px-1 py-[2px] text-xs font-medium text-primary-800">
            {accessKey}
          </span>
        </p>
      </div>

      <div
        className={` w-full ${
          isEdit ? "" : "px-2 py-1 border"
        } rounded-md flex justify-between items-center gap-3  col-span-3 hover:bg-gray-100 hover:border-green-600 ${
          focusValue ? "border-green-600 bg-gray-100" : ""
        }`}
      >
        {isEdit ? (
          <input
            className="h-11"
            defaultValue={value}
            onChange={(e) => setNewValue(e.target.value)}
            type="text"
          />
        ) : (
          <p className="break-all">{value}</p>
        )}

        {isEdit ? (
          <span
            onClick={onSubmit}
            className=" cursor-pointer material-symbols-outlined text-gray-700"
          >
            check_small
          </span>
        ) : (
          <div className="flex items-center gap-1">
            <span
              onClick={copyToClipboard}
              className="material-symbols-outlined text-gray-700 text-base cursor-pointer"
            >
              content_copy
            </span>
            <div className="relative">
              {showDescription && (
                <div className="text-xs absolute z-50  min-w-[200px] max-w-[350px] break-words border shadow-md bg-gray-50 rounded-md p-2 bottom-6 right-3">
                  {description}
                </div>
              )}
              <span
                onMouseEnter={() => setShowDescription(true)}
                onMouseLeave={() => setShowDescription(false)}
                className="material-symbols-outlined text-gray-700 text-base cursor-pointer"
              >
                info
              </span>
            </div>
            <span
              onClick={() => setEdit(true)}
              className="cursor-pointer material-symbols-outlined text-gray-700 text-base"
            >
              edit_square
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppKeyListData;
