import React from 'react'
import SegmentListData from './SegmentListData';

const tableHeaderCols = [
  {
    title: "Name",
  },
  {
    title: "Status",
  },
  {
    title: "Created At",
  },
  {
    title: "",
  },
];



const SegmentListTable = ({segmentList}) => {
  return (
    <div>
      <table className="w-full">
        <thead className="bg-gray-50 border">
          <tr>
            {tableHeaderCols.map((col, index) => (
              <th key={`push_notification_list_table_head_key_${index}`} className="py-3 text-left px-5">{col.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {segmentList.map((item, index) => (
            <SegmentListData
              data={item}
              key={`push_notification_data_key_${index}`}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SegmentListTable