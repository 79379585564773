import React from "react";
import Loader from "../Loader/Loader";

const LoadingButton = (props) => {
  return (
    <button
      type="button"
      className={`btn flex gap-1 ${props.class || ""}`}
      style={{ backgroundColor: props.btnBg || "" }}
    >
      {props.title || "Loading"}{" "}
      <Loader height="25" width="25" color={props.loaderBg || "#fff"} />
    </button>
  );
};

export default LoadingButton;
