import React from "react";

import { Triangle } from "react-loader-spinner";

const Loader = (props) => {
  return (
    <div className="flex flex-col z-50">
      <Triangle
        height={props.height || "80"}
        width={props.width || "80"}
        color={props.color || "#4fa94d"}
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
      {
        props?.title && <p className="text-center py-3">{props.title || ""}</p>
      }
      
    </div>
  );
};

export default Loader;
