import React, { useState } from "react";
import formatDate from "../../../utils/FormatDateAndTime/formatDate";
import PushNotificationListMenu from "./PushNotificationListMenu";
import useAxios from "../../../hooks/useAxios";
import { toast } from "react-toastify";
import PushNotificationDetailsModal from "./PushNotificationDetailsModal";

const PushNotificationListData = ({ data }) => {
  const {
    contents,
    queued_at,
    send_after,
    name,
    headings,
    id,
  } = data;

  const axios = useAxios();
  const [isNotificationDetailModal, setNotificationDetailModal] =
    useState(false);
  const isScheduled = queued_at !== send_after;

  const cancelScheduledNotification = async () => {
    await axios
      .get(`notification/cancelNotification?id=${id}`)
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message);
        } else {
          toast.success(
            res?.data?.message || "Failed to cancel scheduled notification"
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNotificaitonDetailsModal = (e) => {
    setNotificationDetailModal(e);
  };

  const menuBtnFn = {
    cancelScheduledNotification,
    handleNotificaitonDetailsModal,
  };
  return (
    <>
      <PushNotificationDetailsModal
        open={isNotificationDetailModal}
        close={() => handleNotificaitonDetailsModal(false)}
        data={data}
      />
      <tr className="border">
        <td
          onClick={() => handleNotificaitonDetailsModal(true)}
          className="py-3 text-left px-5 cursor-pointer hover:text-primary-600 font-medium"
        >
          {name}
        </td>
        <td className="py-3 text-left px-5">{headings?.en || ""}</td>
        <td className="py-3 text-left px-5">{contents?.en || ""}</td>
        <td className="py-3 text-left px-5">
          <span className={`status ${!isScheduled && "status--green"}`}>
            <div className="flex items-center gap-1">
              <div
                className={`${
                  isScheduled ? "bg-yellow-800" : "bg-green-600"
                } rounded-full w-1 h-1 p-1`}
              ></div>
              <p>{isScheduled ? "Scheduled" : "Delivered"}</p>
            </div>
          </span>
        </td>
        <td className="py-3 text-left px-5">
          <PushNotificationListMenu
            isScheduled={isScheduled}
            menuBtnFn={menuBtnFn}
          />
        </td>
      </tr>
    </>
  );
};

export default PushNotificationListData;
