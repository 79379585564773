import React from 'react'
import AppLogData from './AppLogData';

const tableHeadCols = [
  {
    title: "Target",
  },
  {
    title: "Created At",
  },
  {
    title: "Device Platform",
  },
  {
    title: "Action Details",
  },
  {
    title: "Action Type",
  },
  {
    title: "Is Guest",
  },
];

const AppLogTable = ({ data }) => {

  return (
    <table className="w-full">
      <thead className="bg-gray-50 border whitespace-nowrap text-sm">
        <tr>
          {tableHeadCols.map((col, index) => (
            <td
              key={`user_list_table_head_key_${index}`}
              className={`py-1 font-medium text-center  px-1 border`}
            >
              {col.title}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <AppLogData key={`app_log_data_key_${index}`} data={item} />
        ))}
      </tbody>
    </table>
  );
}

export default AppLogTable