import React, { useEffect, useRef } from "react";
import DashboardSidebar from "../components/DashboardSideBar/DashboardSidebar";
import DashboardHeader from "../components/DashboardHeader";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../components/Loader/Loader";
import useAxios from "../hooks/useAxios";
import {
  setUserData,
  setUserDataLoader,
  setAllPermissionsList,
} from "../redux/reducers/auth";

const DashboardLayout = () => {
  const user = useSelector((state) => state.auth);
  const isUserDataLoader = useSelector((state) => state.auth.userDataLoader);
  const initialRender = useRef(true);
  const axios = useAxios();
  const dispatch = useDispatch();

  const fetchUserData = async () => {
    try {
      const controller = new AbortController();
      const response = await axios
        .get("auth/getUserByAuth", {
          signal: controller.signal,
        })
        .then((res) => res)
        .catch((err) => err);
      if (response?.data?.status) {
        dispatch(setUserData(response?.data));
        return true;
      }
      return () => {
        controller.abort();
      };
    } catch (err) {
      throw err;
    } finally {
      setTimeout(() => {
        dispatch(setUserDataLoader(false));
      }, 1500);
    }
  };

  const fetchAllPermissionList = async () => {
    try {
      const response = await axios
        .get("auth/getAllPermissionList")
        .then((res) => res)
        .catch((err) => err);
      if (response?.data?.status) {
        const permissionList = response?.data?.result || [];
        dispatch(setAllPermissionsList(permissionList));
        return true;
      }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {

    if (initialRender.current) {
      dispatch(setUserDataLoader(true));
      Promise.all([fetchUserData(), fetchAllPermissionList()])
        .then((res) => res)
        .catch((err) => err)
        .finally(() => {
          setTimeout(() => {
            dispatch(setUserDataLoader(false));
          }, 1500);
        });
      initialRender.current = false;
    }
  }, [user]);

  return (
    <div className="flex h-full min-h-screen">
      <div className="border-r ">
        <DashboardSidebar />
      </div>
      <div className="w-full">
        <DashboardHeader />
        {isUserDataLoader ? (
          <div className="flex justify-center items-center h-[calc(100%-100px)] ">
            <Loader />
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
};

export default DashboardLayout;
