import React from "react";

const ActionDetailsView = ({ label, value }) => {
  return (
    <div className="grid grid-cols-4 my-1 py-1 gap-3">
      <label className="uppercase text-green-600">{label}: </label>
      <span className="col-span-3 break-words">{JSON.stringify(value)}</span>
    </div>
  );
};

export default ActionDetailsView;
