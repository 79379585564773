import { configureStore  } from "@reduxjs/toolkit";
import thunk from "redux-thunk"; //will use this later for async methods
import authReducer from "./reducers/auth";

export const store = configureStore({
  reducer: {
    auth: authReducer,
  },
});

export const AppDispatch = store.dispatch;
export const RootState = store.getState;
