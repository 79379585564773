import React from "react";
import PushNotificationListData from "./PushNotificationListData";

const tableHeaderCols = [
  {
    title: "Name",
  },
  {
    title: "Heading",
  },
  {
    title: "Content",
  },
  {
    title: "Status",
  },
  {
    title: "",
  },
];

const PushNotificationListTable = ({ notificaitonList }) => {
  return (
    <div>
      <table className="w-full">
        <thead className="bg-gray-50 border">
          <tr>
            {tableHeaderCols.map((col, index) => (
              <th key={`push_notification_list_table_head_key_${index}`} className="py-3 text-left px-5">{col.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {notificaitonList.map((item, index) => (
            <PushNotificationListData
              data={item}
              key={`push_notification_data_key_${index}`}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PushNotificationListTable;
