import React, { useEffect, useState, useRef } from "react";
import Modal from "react-responsive-modal";
import Loader from "../../../components/Loader/Loader";
import useAxios from "../../../hooks/useAxios";

const StaticSitePreview = ({ open, close, data }) => {
  const axios = useAxios();
  const [previewPage, setPreviewPage] = useState(null);
  const [pageComponent, setPageComponent] = useState(<Loader />);

  const previewStaticSite = async () => {
    const response = await axios
      .post("services/getHtmlContent", { siteUrl: data.site_url, className: data.class_name })
      .then((res) => res)
      .catch((err) => err);
    if (response?.data?.status) {
      setPreviewPage(response.data.html);
    } else {
      setPageComponent("Failed to fetch preview of the webpage, Check the url and try again")
    }
  };

  useEffect(() => {
    if (open) {
      previewStaticSite();
    }
  }, [data]);
  return (
    <Modal
      open={open}
      onClose={close}
      showCloseIcon={false}
      styles={{
        modal: {
          width: "90%",
          padding: "0px",
        },
      }}
    >
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between border-b-2 p-5">
          <span className="text-xl font-medium">Site Preview</span>
          <span
            onClick={close}
            className="material-symbols-outlined cursor-pointer"
          >
            close
          </span>
        </div>
        <div className="p-5">
          {previewPage ? (
            <div dangerouslySetInnerHTML={{ __html: previewPage }}></div>
          ) : (
            <div className="flex justify-center items-center">
              {pageComponent}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default StaticSitePreview;
