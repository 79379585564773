import React from "react";
import Modal from "react-responsive-modal";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify"
const DeleteBanner = ({ open, close, bannerData, fetchBannerList, isCategoryBannerTab, getCollectionBannerDatas }) => {

  const axios = useAxios();
  const { title, id } = bannerData;

  const deleteBanner = async () => {
    await axios.delete(`banner/deleteBanner?id=${id}`).then((res) => {
      if (res?.data?.status) {
        if (isCategoryBannerTab) {
          getCollectionBannerDatas()
        } else {
          fetchBannerList()
        }
        
        toast.success(res?.data?.message || "Banner deleted")
        close()
      } else {
        toast.error(res?.data?.message || "Something went wrong, try again")
      }
    }).catch((err) => {
      console.error(err)
    })
  }
  return (
    <Modal center showCloseIcon={false} open={open}>
      <div className="max-w-[500px]">
        <div className="flex justify-between">
          <h1 className="font-medium">Delete Banner - {title}</h1>
          <span className="cursor-pointer material-symbols-outlined" onClick={close}>close</span>
        </div>
        <div className="py-5">
          <p className="text-sm font-medium text-gray-800">Are you sure do you want to delete this banner?</p>
          {
            isCategoryBannerTab && <p className="text-sm font-medium text-gray-800 py-2">This action will remove the banner from the category</p>
          }
        </div>
        <div className="flex flex-col gap-2">
          <button className="btn btn--border" onClick={close}>Close</button>
          <button className="btn btn--red" onClick={deleteBanner}>Delete</button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteBanner;
