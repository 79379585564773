import moment from "moment";


export const getFormErrMsg = (field, errors) => {
  return (
    <p className="text-red-600 font-medium text-xs ml-1 my-1" role="alert">
      {errors[field].message}
    </p>
  );
};

export const formatDeviceType = (userAgent) => {
  try {
    const regex = /\(([^;]+); ([^)]+)\)/;
    const match = userAgent?.match(regex);
    return match ? `${match[1]}; ${match[2]}` : null;
  } catch (err) {
    throw err;
  }
};

export const chartColors = [
  "#1E8449",
  "#229954",
  "#27AE60",
  "#52BE80",
  "#7DCEA0",
  "#ABEBC6",
  "#D5F5E3",
  "#28B463",
  "#2ECC71",
  "#82E0AA",
  "#ABEBC6"
];


export const generateDates =  (fromDate, toDate, dateFormat = "YYYY-MM-DD") => {
  let dates = [];
  if (fromDate && toDate) {
    let startDate = moment(fromDate);
    const endDate = moment(toDate);
    dates.push(startDate.format(dateFormat))
    while (!moment(startDate).isSame(endDate)) {
      startDate = startDate.add(1, "day");
      dates.push(startDate.format(dateFormat));
    }
  }
  return dates;
};
