import React, { useEffect, useState, useRef } from "react";
import useAxios from "../../../hooks/useAxios";
import Loader from "../../../components/Loader/Loader";
import AppLogTable from "../../../components/AppSettings/AppLog/AppLogTable";
import PaginationBar from ".././../../components/PaginationBar/PaginationBar";
import { DatePicker } from "antd";
import Select from "react-select";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import Search from "../../../components/Search";
import { toast } from "react-toastify";

const CREATED_AT = "createdAt";
const DEVICE_PLATFORM = "devicePlatform";
const SORT_ORDER = "sortOrder";
const ACTION_TYPE = "actionType";
const SEARCH = "filter";
const appActionType = {
  addToCart: "ADD_TO_CART",
  viewCategory: "VIEW_CATEGORY",
  viewProduct: "VIEW_PRODUCT",
  appOpen: "APP_OPEN",
  addToWishList: "ADD_TO_WISHLIST",
  frequencyOfBuying: "FREQUENCY_OF_BUYING",
  removeFromWishList: "REMOVE_FROM_WISHLIST",
  appClose: "APP_CLOSE",
  removeFromCart: "REMOVE_FROM_CART",
  error: "ERROR",
};
const actionTypeKeys = Object.keys(appActionType);
const tabs = actionTypeKeys.map((key) => ({
  label: appActionType[key].split("_").join(" "),
  key: appActionType[key],
}));
tabs.unshift({ label: "ALL", key: "" });

const AppLog = () => {
  const {
    profile: { role_id },
  } = useSelector((state) => state.auth);
  const isDevRole =
    Number(role_id) === Number(process.env.REACT_APP_DEVELOPER_ROLE_ID);
  const LOADER = <Loader />;
  const NODATAFOUNDMSG = "No data found";
  const axios = useAxios();
  const [logData, setLogData] = useState([]);
  const [pageComponent, setPageComponent] = useState(LOADER);
  const [filter, setFilter] = useState({
    createdAt: "",
    devicePlatform: "",
    sortOrder: "DESC",
    actionType: "",
    filter: "",
  });
  const filterRef = useRef({
    createdAt: "",
    devicePlatform: "",
  });

  const handleClearFilters = () => {
    filterRef?.current?.devicePlatform &&
      filterRef.current.devicePlatform.clearValue();
    setFilter((prev) => ({
      ...prev,
      createdAt: "",
      devicePlatform: "",
      actionType: "",
      filter: "",
    }));
  };

  const { createdAt, devicePlatform, filter: search } = filter;
  const isAscSortOrder = filter.sortOrder === "ASC";
  const isFilterApplied = createdAt || devicePlatform || search;

  const handleSetFilter = (e, source) => {
    setFilter((prev) => ({
      ...prev,
      [source]: e,
    }));
  };

  const { results: logDataResult, totalRecords } = logData;
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchAppLogs = async (filters = "") => {
    setPageComponent(LOADER);
    const result = await axios
      .get(
        `services/searchLogs?pageNo=${pageNo}&pageSize=${pageSize}${filters}`
      )
      .then((res) => res)
      .catch((err) => err);
    if (result?.data?.status) {
      setLogData(result.data);
      setPageComponent(null);
    } else if (result?.response?.data?.error === "INVALID_PAGE_NUMBER") {
      setPageNo(1);
    } else {
      setPageComponent(NODATAFOUNDMSG);
    }
  };

  const formatFilters = () => {
    let filters = "";
    const filterArr = Object.keys(filter);
    filterArr.forEach((i) => {
      const value = filter[i];
      if (value) {
        filters += `&${i}=${value}`;
      }
    });
    return filters;
  };

  useEffect(() => {
    const filters = formatFilters();
    fetchAppLogs(filters);
  }, [pageNo, pageSize, filter]);

  const getSearchValue = (e) => {
    handleSetFilter(e, SEARCH);
  };

  const handleExportCsv = async () => {
    const filters = formatFilters();
    const result = await axios
      .get(
        `services/searchLogs?exportCsv=1pageNo=${pageNo}&pageSize=${pageSize}${filters}`
      )
      .then((res) => res)
      .catch((err) => err);
    if (Number(result?.status === 200) && typeof result.data === "string") {
      try {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = "app_log_report";
        link.setAttribute("download", `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.success("Report downloaded");
      } catch (error) {
        toast.error(result?.data?.message || "Failed to generate report");
      }
    } else {
      toast.error(
        result?.response?.data?.message || "Failed to generate report"
      );
    }
  };

  return (
    <div className=" xl:w-[calc(100vw-308px)] w-[calc(100vw-110px)]">
      <div className="px-5">
        <div className="flex flex-col pt-5">
          <h1 className="text-xl font-semibold">App Logs</h1>
          <h1 className="text-sm text-gray-500">view app logs</h1>
        </div>
        <div className="flex gap-3 py-1  border-b  overflow-x-auto xl:w-[calc(100vw-330px)] w-[calc(100vw-110px)]">
          {tabs.map((item) => {
            if (item.key === appActionType.error) {
              if (isDevRole) {
                return (
                  <button
                    className={`${
                      filter[ACTION_TYPE] === item.key
                        ? "border-b-2 border-b-primary-800 font-medium"
                        : "hover:border-b-2 hover:border-b-primary-800 font-medium"
                    } py-2 px-1 whitespace-nowrap `}
                    onClick={() => handleSetFilter(item.key, ACTION_TYPE)}
                  >
                    {item.label}
                  </button>
                );
              }
              return null;
            } else {
              return (
                <button
                  className={`${
                    filter[ACTION_TYPE] === item.key
                      ? "border-b-2 border-b-primary-800 font-medium"
                      : "hover:border-b-2 hover:border-b-primary-800 font-medium"
                  } py-2 px-1 whitespace-nowrap `}
                  onClick={() => handleSetFilter(item.key, ACTION_TYPE)}
                >
                  {item.label}
                </button>
              );
            }
          })}
        </div>
        <div className="flex justify-between py-5 px-3  xl:w-[calc(100vw-330px)] w-[calc(100vw-110px)]">
          <div className="flex gap-3 items-center">
            <DatePicker
              value={filter?.createdAt ? dayjs(filter.createdAt) : null}
              ref={(ele) => (filterRef.current.createdAt = ele)}
              onChange={(e) =>
                handleSetFilter(
                  e ? dayjs(e).format("YYYY-MM-DD") : "",
                  CREATED_AT
                )
              }
              className="h-10"
              placeholder="Created Date"
            />
            <Select
              ref={(ele) => (filterRef.current.devicePlatform = ele)}
              onChange={(e) => handleSetFilter(e?.value, DEVICE_PLATFORM)}
              className="custom-select-container"
              placeholder="Device Platform"
              options={[
                { label: "IOS", value: "ios" },
                { label: "Android", value: "android" },
                { label: "Other", value: "other" },
              ]}
            />
            <button
              onClick={() =>
                handleSetFilter(isAscSortOrder ? "DESC" : "ASC", SORT_ORDER)
              }
              className="flex items-center border h-10 px-1 rounded-md border-gray-300"
            >
              <span class="material-symbols-outlined ">swap_vert</span> Sort by
              date {isAscSortOrder ? "Ascending" : "Descending"}
            </button>
            {isFilterApplied && (
              <button
                onClick={handleClearFilters}
                className="text-sm rounded-md h-10 text-green-600 font-medium"
              >
                Clear Filters
              </button>
            )}
          </div>
          <div className="flex gap-3 items-center">
            <Search onChange={getSearchValue} />
            <button className="btn btn--border" onClick={handleExportCsv}>
              <span className="material-symbols-outlined">download</span>Export
              CSV
            </button>
          </div>
        </div>
        <div className="pb-10">
          {pageComponent ? (
            <div className="flex  justify-center items-center w-full h-[calc(100vh-450px)]">
              {pageComponent}
            </div>
          ) : Array.isArray(logDataResult) ? (
            <div className="mb-20  overflow-x-auto xl:w-[calc(100vw-330px)] w-[calc(100vw-110px)]">
              <AppLogTable data={logDataResult} />
            </div>
          ) : (
            "Something went wrong, try again later"
          )}
        </div>
      </div>

      <div className="fixed bottom-0 xl:w-[calc(100vw-308px)] w-[calc(100vw-110px)] border">
        <PaginationBar
          page={{
            pageNo,
            setPageNo,
            pageSize,
            setPageSize,
            totalRecords,
          }}
        />
      </div>
    </div>
  );
};

export default AppLog;
