import axios from "../helpers/axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useRefreshToken from "./useRefreshToken";
import { useNavigate, useLocation } from "react-router-dom";

const useAxios = () => {
  const user = useSelector((state) => state.auth);
  const refreshToken = useRefreshToken();
  const navigate = useNavigate();
  const location = useLocation();
 
  const [accessToken, setToken] = useState(
    user?.authentication?.token || sessionStorage?.getItem("accessToken")
  );

  const getAccessToken = async () => {
    if (user?.authentication?.token) {
      setToken(user?.authentication?.token);
      return;
    }
    return setToken(await refreshToken());
  };

  useEffect(() => {
    if (!accessToken) {
      getAccessToken();
    }
    const request = axios.interceptors.request.use(
      async (config) => {
        if (!config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const response = axios.interceptors.response.use(
      (res) => res,
      async (error) => {
        if (error?.response?.data?.error === "NO_PORTAl_ACCESS") {
          if (location.pathname !== "/") {
           
            return navigate("/", {
              state: {
                portalAccessDenied: true
              }
            })
          }
        }
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refreshToken();
          prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(prevRequest);
        }
        return error;
      }
    );
    return () => {
      axios.interceptors.request.eject(request);
      axios.interceptors.response.eject(response);
    };
  }, [user, refreshToken, accessToken]);

  return axios;
};

export default useAxios;
