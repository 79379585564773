import React, { useEffect, useState } from "react";
import Switch from "react-switch";

const ToggleSwitch = (props) => {
  const [isChecked, setChecked] = useState(props.isChecked || false);

  const handleOnchange = (e) => {
    props?.getToggleState?.(e);
    setChecked(e);
  };

  useEffect(() => {
    setChecked(props?.isChecked || false)
  }, [props?.isChecked])
  return (
    <Switch
      checked={isChecked}
      onChange={handleOnchange}
      onColor="#189634"
      offColor="#DC3545"
      onHandleColor="#ffffff"
      handleDiameter={25}
      uncheckedIcon={
        <div
          style={{
            display: "flex",
            color: "#fff",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
            marginTop: 1,
          }}
        >
          No
        </div>
      }
      checkedIcon={
        <div
          style={{
            display: "flex",
            marginLeft: 3,
            color: "#fff",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
            marginTop: 1,
          }}
        >
          Yes
        </div>
      }
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={23}
      width={58}
    />
  );
};

export default ToggleSwitch;
