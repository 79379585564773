import React, { useEffect } from "react";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const BannerVisitors = () => {
  const chartColors = [
    "#189634",
    "#A8E8B6",
    "#A8E8B6",
    "#9AB3F3",
    "#789BF6",
    "#4875E9",
    "#3466E7",
    "#2A53B9",
    "#1F3D8B",
  ];
  const data = [
    {
      title: "Annual",
      value: 12,
    },
    {
      title: "Engagement",
      value: 12,
    },
    {
      title: "Maternity",
      value: 12,
    },
  ];
  useEffect(() => {
    const ctx = document
      .getElementById("BannerVisitorsChart")
      .getContext("2d");
    const myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["", "", "", "", ""],
        datasets: [
          {
            label: "# of Votes",
            data: [10, 10, 10],
            backgroundColor: chartColors,
          },
        ],
      },
      options: {
        scales: {},
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
    return () => {
      myChart.destroy();
    };
  });
  return (
    <>
      <div className="border rounded-md p-3 xlg:max-h-[200px] h-full bg-white shadow-md">
        <div className="flex justify-between">
          <p className="font-semibold">Banner Visitors</p>
        </div>

        <div className="flex flex-col xlg:flex-row items-center py-4">
          <div className="max-w-[150px] max-h-[150px] px-3">
            <canvas id="BannerVisitorsChart" ></canvas>
          </div>
          <div className="flex pt-5 flex-wrap xlg:flex-col w-3/4">
            {data.map((item, index) => {
              return (
                <div
                  className="flex  justify-between  w-full pb-3 pr-5 text-sm items-center"
                  key={`banner_activities_key_${index}`}
                >
                  <span
                    style={{ backgroundColor: chartColors[index] }}
                    className={`xlg:h-3 xlg:w-5 h-2 w-3 rounded-[20px] mr-3`}
                  ></span>

                  <p className="text-left w-4/5">{item.title}</p>
                  <h3 className=" text-gray-800 ml-2 font-semibold">
                    {item.value}
                  </h3>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerVisitors;
