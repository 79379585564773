import React from 'react'
import StaticSiteData from './StaticSiteData';

const StaticSiteTable = ({data}) => {
    const tableHeadCols = [
        {
          title: "Site Name",
        },
        {
          title: "URL",
        },
        {
          title: "Access Key",
        },
        {
          title: "Preview",
        },
        {
          title: "",
        },
      ];

  return (
    <table className="w-full">
    <thead className="bg-gray-100 border border-t-0">
      <tr className='text-center'>
        {tableHeadCols.map((col, index) => (
          <td
            key={`user_list_table_head_key_${index}`}
            className={`py-3 font-medium  ${
              tableHeadCols.length - 1 === index ? "" : "px-10"
            }`}
          >
            {col.title}
          </td>
        ))}
      </tr>
    </thead>
    <tbody>
      {Array.isArray(data) ? data.map((item, index) => (
        <StaticSiteData key={`user_list_data_key_${index}`} data={item} />
      )) : null}
    </tbody>
  </table>
  )
}

export default StaticSiteTable