import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div
      style={{
        backgroundImage: "url(/images/layout/404.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
      className="flex  flex-col justify-center items-center h-screen w-screen"
    >
      <p className=" text-5xl font-semibold py-1">Page Not Found</p>
      <span className="text-gray-500">Woops. Looks like this page doesn’t exist.</span>
      <Link to="/dashboard" className="py-1 ">
        <button className="btn font-mono font-semibold text-xl">
          Back to dashboard
        </button>
      </Link>
    </div>
  );
};

export default PageNotFound;
