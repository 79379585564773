import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const NotificationDeliveryStas = ({ data }) => {
  const {
    successful,
    remaining,
    failed,
    errored
  } = data;

  const chartColors = ["#239B56", "#E74C3C", "#C70039", "#FFC300"];
  useEffect(() => {
    const ctx = document
      .getElementById("NotificationStatisticsChart")
      .getContext("2d");
    const myChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: ["Delivered", "Unsubscribed", "Errored", "Remaining"],
        datasets: [
          {
            label: "# count",
            data: [successful, failed, errored, remaining],
            backgroundColor: chartColors,
            hoverOffset: 4,
          },
        ],
      },
      options: {
        scales: {},
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
    return () => {
      myChart.destroy();
    };
  }, []);

  const notificationStats = [
    {
      title: "Delivered",
      value: successful,
    },
    {
      title: "Failed (Unsubscribed)",
      value: failed,
    },
    {
      title: "Failed (Errored)",
      value: errored,
    },
    {
      title: "Remaining",
      value: remaining,
    },
  ];

  return (
    <div className="flex justify-between items-center">
      <div className="flex justify-between">
        <ul>
          <li className="py-2">Total Sent</li>
          {notificationStats.map((i, index) => (
            <li key={`${index}_notfii_stats_li_title`} className="flex items-center gap-2 border-t py-2">
              <div
                style={{ backgroundColor: chartColors[index] }}
                className="rounded-full w-2 h-2"
              ></div>
              <span className="whitespace-nowrap">{i.title}</span>
            </li>
          ))}
        </ul>
        <ul>
          <li className="px-3 py-2">{successful + failed + errored + remaining}</li>
          {notificationStats.map((i, index) => (
            <li key={`${index}_notfii_stats_li_val`} className="border-t px-3 py-2">{i.value}</li>
          ))}
        </ul>
      </div>
      <div className="max-w-[150px] max-h-[150px] px-3">
        <canvas id="NotificationStatisticsChart"></canvas>
      </div>
    </div>
  );
};

export default NotificationDeliveryStas;
