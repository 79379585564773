import React from "react";
import { useNavigate, Link } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate()
  const onSubmit = () => {
    navigate("/")
  }
  return (
    <div className="min-w-[400px]">
      <h1 className="form-head mb-2">RESET YOUR PASSWORD</h1>
      <div className="mb-10">
        <p className="text-sm text-gray-500">
          Hai, Jayne. Please enter new password to reset your old password
        </p>
      </div>
      <div className="flex flex-col gap-7 mb-10">
        <div className="flex flex-col gap-3">
          <label className="form-label">Password</label>
          <input
            className="form-input"
            placeholder="Enter Your Password"
            type="password"
          />
        </div>
        <div className="flex flex-col gap-3">
          <label className="form-label">Confirm Password</label>
          <input
            className="form-input"
            placeholder="Enter Your Password"
            type="password"
          />
        </div>
      </div>

      <div>
        <button className="btn btn--full" onClick={onSubmit}>
          Reset Password
        </button>
      </div>
      <div className=' py-4 font-medium'>
        <Link to="/"><button className='btn btn--border btn--full'>Back to Login</button></Link>
      </div>
    </div>
  );
};

export default ResetPassword;
