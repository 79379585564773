import React from "react";
import Avatar from "../../Avatar/Avatar";

const StaffProfileView = ({ userData, permissionStatus }) => {
  const { results: profile } = userData;

  const fullName = `${profile?.first_name || ""} ${profile?.last_name || ""}`;
  const email = profile?.email || "";
  const adress = profile?.adress || "";
  const personalWebsiteAdress = profile?.personal_website_adress || "";
  const image = profile?.image || null;

  return (
    <div className="flex justify-between">
      <div className="w-1/5 py-10">
        <h1 className="font-medium">{fullName}</h1>
        <span className="text-xs font-medium">Staff Profile</span>
      </div>
      <div className="bg-white  w-4/5 py-10">
        <div className="px-10 py-5">
          <div className="border rounded-md p-5 flex justify-between items-center">
            <div className="flex items-center gap-2">
              <Avatar image={image} />
              <div className="flex flex-col">
                <h1 className="font-medium">{fullName}</h1>
                <h6 className="text-sm text-gray-500">{email}</h6>
              </div>
            </div>
            <span className="text-gray-500 text-sm">{permissionStatus}</span>
          </div>
        </div>
        {adress || personalWebsiteAdress ? (
          <div className="border-t-2 my-3 flex flex-col gap-5 px-10 py-5">
            {adress ? (
              <div>
                <h1 className="font-medium">Adress</h1>
                <div className="border rounded-md p-5 text-sm text-gray-500">
                  {adress}
                </div>
              </div>
            ) : null}
            {personalWebsiteAdress ? (
              <div>
                <h1 className="font-medium">Personal Website Adress</h1>
                <div className="border rounded-md p-5 text-sm text-gray-500">
                  {personalWebsiteAdress}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StaffProfileView;
