import React, { useEffect, useState, useRef } from "react";
import { DatePicker } from "antd";
import Search from "../../components/Search";
import AppBannerTable from "../../components/AppBanners/AppBannerTable";
import AddBannerForm from "../../components/AppBanners/AddBanner/AddBanner";
import useAxios from "../../hooks/useAxios";
import Loader from "../../components/Loader/Loader";
import useGetAccess from "../../hooks/useGetAccess";
import AccessDenied from "../../components/AccessDenied";
import {
  ADD_APP_BANNER_KEY,
  VIEW_APP_BANNER_KEY,
  VIEW_CATEGORY_BANNER_KEY,
} from "../../utils/AccessList/accessList";
import PaginationBar from "../../components/PaginationBar";
import Select from "react-select";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
// import CategoryBanner from "../../components/AppBanners/CategoryBanner/CategoryBanner";
import CategoryBannerData from "../../components/AppBanners/CategoryBanner/CategoryBannerData";
import useFetchFile from "../../hooks/useFetchFile";
import {
  imageFileTypeConst,
  bannerImagePathConst,
} from "../../consts/fileConsts";

const APP_BANNER_TAB = 1;
const CATEGORY_BANNER_TAB = 2;
const CATEGORY_SLIDING_BANNER = 3;
const { RangePicker } = DatePicker;

const AppBanners = () => {
  const axios = useAxios();
  const getAccess = useGetAccess();

  const user = useSelector((state) => state.auth);
  const isUserDataLoader = useSelector((state) => state.auth.userDataLoader);

  const [accessList, setAccessList] = useState({
    hasViewAppBannerAccess: getAccess(VIEW_APP_BANNER_KEY),
    hasAddAppBannerAccess: getAccess(ADD_APP_BANNER_KEY),
    hasViewCategoryBannerAcess: getAccess(VIEW_CATEGORY_BANNER_KEY),
  });

  useEffect(() => {
    setAccessList({
      hasViewAppBannerAccess: getAccess(VIEW_APP_BANNER_KEY),
      hasAddAppBannerAccess: getAccess(ADD_APP_BANNER_KEY),
      hasViewCategoryBannerAcess: getAccess(VIEW_CATEGORY_BANNER_KEY),
    });
  }, [user]);

  const {
    hasAddAppBannerAccess,
    hasViewAppBannerAccess,
    hasViewCategoryBannerAcess,
  } = accessList;

  const [isAddBannerModal, setIsAddBannerModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [bannerList, setBannerList] = useState(null);
  const [pageComponent, setPageComponent] = useState(<Loader />);
  const [toggleTab, setToggleTab] = useState(APP_BANNER_TAB);
  const [categoryBanner, setCategoryBanner] = useState([]);
  const isCategoryBannerTab = toggleTab === CATEGORY_BANNER_TAB;
  const [totalRecords, setTotalRecords] = useState(0);
  const [dateRange, setDateRange] = useState();
  const [filter, setFilter] = useState({
    active: null,
    status: null,
    search: null,
  });
  const [clearSearchValue, setClearSearchValue] = useState(false);
  const [categoryListFromShopify, setCategoryListFromShopify] = useState(null);
  const [collectionList, setCollectionList] = useState([]);

  const filterRef = useRef({
    active: null,
    status: null,
    search: null,
    dateRange: null,
  });
  const expandedValueRef = useRef([]) //category banner expanded items list for accordian

  const [baseUrl, setBaseUrl] = useState(
    `banner/getAllBannerList?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&sortBy=added_date`
  );

  const setNoDataFoundTemplate = (isLoader = false) => {
    setPageComponent(isLoader ? <Loader /> : "No data found");
    setBannerList(null);
    setCategoryListFromShopify(null);
  };

  const fetchBannerList = async () => {
    setNoDataFoundTemplate(true);
    const getBannerUrl = `${baseUrl}&bannerType=${toggleTab}`;
    await axios
      .get(getBannerUrl)
      .then((res) => {
        if (res?.data?.status) {
          setBannerList(res?.data?.results);
          setTotalRecords(Number(res.data.totalRecords));
        } else {
          setNoDataFoundTemplate();
        }
      })
      .catch((err) => {
        setNoDataFoundTemplate();
      });
  };

  useEffect(() => {
    let baseUrl = `banner/getAllBannerList?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&sortBy=added_date`;
    const fromDate = dateRange?.[0]
      ? dayjs(dateRange?.[0]).format("YYYY-MM-DD")
      : null;
    const toDate = dateRange?.[1]
      ? dayjs(dateRange?.[1]).format("YYYY-MM-DD")
      : null;
    if (fromDate && toDate) {
      baseUrl += `&fromDate=${fromDate}&toDate=${toDate}`;
    }
    if (filter.active) {
      baseUrl += `&active=${filter.active}`;
    }
    if (filter.status) {
      baseUrl += `&status=${filter.status}`;
    }
    if (filter.search) {
      baseUrl += `&search=${filter.search}`;
    }
    const delayTimer = setTimeout(() => {
      setBaseUrl(baseUrl);
    }, 300);
    return () => {
      clearTimeout(delayTimer);
    };
  }, [dateRange, filter]);

  const fetchFile = useFetchFile();

  const [categoryBannerImages, setCategoryBannerImages] = useState([]);

  const getBannerImg = async (imageUrl, itemId) => {
    const imgSrc = await fetchFile(
      imageUrl,
      imageFileTypeConst,
      bannerImagePathConst
    );
    if (imgSrc) {
      setCategoryBannerImages((prev) => [...prev, { imgSrc, itemId }]);
    }
    return imgSrc || "/images/imagedummy.png";
  };

  useEffect(() => {
    if (categoryBanner.length) {
      setCategoryBannerImages([]);
      const items = categoryBanner.map((i) => getBannerImg(i.image, i.id));
      Promise.all(items);
    }
  }, [categoryBanner]);

  const fetchCategoryBannerList = async () => {
    setNoDataFoundTemplate(true);
    await axios
      .get(`banner/getAllBannerList?bannerType=${CATEGORY_BANNER_TAB}`)
      .then((res) => {
        // console.log(res, "res")
        if (res?.data?.status) {
          setCategoryBanner(res?.data?.results);
        } else {
          setNoDataFoundTemplate();
        }
      })
      .catch(() => {
        setNoDataFoundTemplate();
      });
  };

  const fetchCollections = async (pageLimit = 50) => {
    setCollectionList([]);
    await axios
      .get(`shopify/getCollections?pageLimit=${pageLimit}`)
      .then((res) => {
        if (res?.data?.status) {
          setCollectionList(res?.data?.collections?.edges || []);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const getCollectionBannerDatas = () => {
    fetchCategoryListFromShopify();
    fetchCategoryBannerList();
  };

  const fetchCategoryListFromShopify = async () => {
    await axios
      .get("shopify/getCategory")
      .then((res) => {
        if (res?.data?.status) {
          setCategoryListFromShopify(res?.data?.result);
        } else {
          setNoDataFoundTemplate();
        }
      })
      .catch((err) => {
        // console.log(err);
        setNoDataFoundTemplate();
      });
  };

  useEffect(() => {
    fetchCollections();
    if (isCategoryBannerTab) {
      if (hasViewCategoryBannerAcess) {
        getCollectionBannerDatas();
      } else {
        setBannerList(null);
        setPageComponent(<AccessDenied />);
      }
    } else {
      if (hasViewAppBannerAccess) {
        fetchBannerList();
      } else {
        setBannerList(null);
        setPageComponent(<AccessDenied />);
      }
    }
  }, [toggleTab, baseUrl]);

  const handleBannerFormModal = (e) => {
    setIsAddBannerModal(e);
  };

  const clearFilters = () => {
    filterRef?.current?.active?.setValue([], "clear");
    filterRef?.current?.status?.setValue([], "clear");
    setDateRange(null);
    setFilter({
      active: null,
      status: null,
      search: null,
    });
    setClearSearchValue((prev) => !prev);
  };

  return (
    <div
      className={`bg-gray-50 w-auto ${
        (bannerList?.length > 4) || (toggleTab === CATEGORY_BANNER_TAB &&
          categoryListFromShopify?.menu)
          ? "h-auto"
          : bannerList
          ? "h-full"
          : "h-[calc(100vh-100px)]"
      }`}
    >
      <div className="p-10">
        {isAddBannerModal ? (
          <AddBannerForm
            toggleTab={toggleTab}
            fetchBannerList={fetchBannerList}
            open={isAddBannerModal}
            close={() => handleBannerFormModal(false)}
            collectionList={collectionList}
          />
        ) : null}

        <div className="flex justify-between">
          <h1 className="text-xl font-semibold">Banner Properties</h1>
          {!isCategoryBannerTab && hasAddAppBannerAccess ? (
            <button
              className="btn btn--black"
              onClick={() => handleBannerFormModal(true)}
            >
             {toggleTab === APP_BANNER_TAB ? " Add App Banner" : "Add Category Sliding Banner"}
            </button>
          ) : null}
        </div>
        <div className="flex gap-3  border-b">
          <button
            className={`${
              toggleTab === APP_BANNER_TAB
                ? "border-b-2 border-b-primary-800  font-medium"
                : "px-1 py-3"
            }`}
            onClick={() => setToggleTab(APP_BANNER_TAB)}
          >
            App Banner
          </button>
          <button
            onClick={() => setToggleTab(CATEGORY_BANNER_TAB)}
            className={`${
              toggleTab === CATEGORY_BANNER_TAB
                ? "border-b-2 border-b-primary-800  font-medium"
                : "px-1 py-3"
            }`}
          >
            Category Banner
          </button>
          <button
            onClick={() => setToggleTab(CATEGORY_SLIDING_BANNER)}
            className={`${
              toggleTab === CATEGORY_SLIDING_BANNER
                ? "border-b-2 border-b-primary-800  font-medium"
                : "px-1 py-3"
            }`}
          >
            Category Sliding Banner
          </button>
        </div>
        {!isCategoryBannerTab ? (
          <div className="flex justify-between items-center py-4">
            <div className="flex items-center gap-3">
              <RangePicker
                className="h-10"
                onChange={(val) => {
                  setDateRange(val);
                }}
                value={dateRange}
                changeOnBlur
              />
              <Select
                ref={(element) => (filterRef.current.active = element)}
                options={[
                  { label: "Active", value: "1" },
                  { label: "In Active", value: "0" },
                ]}
                className="custom-select-container"
                placeholder="Active"
                onChange={(e) =>
                  setFilter((prev) => ({ ...prev, active: e.value }))
                }
              />
              <Select
                ref={(element) => (filterRef.current.status = element)}
                options={[
                  { label: "In Progress", value: "3" },
                  { label: "Pending", value: "2" },
                  { label: "Completed", value: "1" },
                  { label: "Default", value: "4" },
                ]}
                onChange={(e) =>
                  setFilter((prev) => ({ ...prev, status: e.value }))
                }
                className="custom-select-container"
                placeholder="Status"
              />
              {dateRange ||
              filter?.search ||
              filter?.active ||
              filter?.status ? (
                <span
                  className="material-symbols-outlined text-gray-700 cursor-pointer"
                  onClick={clearFilters}
                >
                  filter_list_off
                </span>
              ) : null}
            </div>
            <div>
              <Search
                clearValue={clearSearchValue}
                onChange={(e) => setFilter((prev) => ({ ...prev, search: e }))}
              />
            </div>
          </div>
        ) : null}

        {(!categoryListFromShopify && !bannerList) || isUserDataLoader ? (
          <div className="flex w-full h-[calc(100vh-290px)] items-center justify-center">
            {isUserDataLoader ? <Loader /> : pageComponent}
          </div>
        ) : (
          <>
            {toggleTab === CATEGORY_BANNER_TAB &&
            categoryListFromShopify?.menu ? (
              <CategoryBannerData
                categoryListFromShopify={categoryListFromShopify}
                getCollectionBannerDatas={getCollectionBannerDatas}
                categoryBannerImages={categoryBannerImages}
                categoryBanner={categoryBanner}
                expandedValueRef={expandedValueRef}
              />
            ) : null}

            {(toggleTab === APP_BANNER_TAB || toggleTab === CATEGORY_SLIDING_BANNER) &&
            bannerList &&
            Array.isArray(bannerList) ? (
              <div className="bg-white px-5 mb-14">
                <AppBannerTable
                  fetchBannerList={fetchBannerList}
                  bannerList={bannerList}
                  getCollectionBannerDatas={getCollectionBannerDatas}
                  toggleTab={toggleTab}
                  isCategoryBannerTab={isCategoryBannerTab}
                  categoryBanner={categoryBanner}
                  collectionList={collectionList}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
      {!isCategoryBannerTab ? (
        <div className="fixed bottom-0 xl:w-[calc(100%-300px)] w-[calc(100%-77px)] border">
          <PaginationBar
            page={{
              pageNo,
              setPageNo,
              pageSize,
              setPageSize,
              totalRecords,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AppBanners;
