import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const RequireAuth = ({ allowedRoles }) => {
  const user = useSelector((state) => state.auth);
  const userRoleId = Number(user?.profile?.role_id);

  return allowedRoles.includes(userRoleId) ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" replace />
  );
};

export default RequireAuth;
