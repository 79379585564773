import React, { useEffect, useState } from "react";
import MostViewedCategory from "../Widgets/AppStats/MostViewedCategory";
import useAxios from "../../hooks/useAxios";
import { DatePicker, Select } from "antd";
import MostViewedProduct from "../Widgets/AppStats/MostViewedProduct";
import dayjs from "dayjs";
import UserStatsCard from "../Widgets/AppStats/UserStatsCard";
import Loader from "../Loader/Loader";
import OrderRevenueStats from "../Widgets/AppStats/OrderRevenueStats";
import AppUserActionChart from "../Widgets/AppStats/AppUserActionChart";

const { RangePicker } = DatePicker;
const DATE_RANGE = "DATE_RANGE";
const TODAY = "TODAY";
const MONTH = "MONTH";

const selectedFilterFocusStyle = "border-blue-600 border-2 shadow-md";

const AppStats = () => {
  const axios = useAxios();

  const [report, setReport] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [resultLimit, setResultLimit] = useState(3);
  const defaultToDate = dayjs().format("YYYY-MM-DD");
  const defaultFromDate = dayjs(defaultToDate)
    .subtract(7, "days")
    .format("YYYY-MM-DD");
  const [dateRange, setDateRange] = useState({
    fromDate: defaultFromDate,
    toDate: defaultToDate,
  });
  const [selectedDateSource, setSelectedDateSource] = useState(DATE_RANGE);
  const [customerAnalyticsReport, setCustomerAnalyticsReport] = useState({});
  const [orderRevenueReport, setOrderRevenueReport] = useState([]);
  const isDateRangeFilter = selectedDateSource === DATE_RANGE;
  const isMonthFilter = selectedDateSource === MONTH;
  const isTodayFilter = selectedDateSource === TODAY;

  const categoryViewBaseUrl = process.env.REACT_APP_COLLECTION_VIEW_BASE_URL;
  const productViewBaseUrl = process.env.REACT_APP_PRODUCT_VIEW_BASE_URL;

  const handleDateRangeChange = (e, source) => {
    let fromDate = dateRange.fromDate;
    let toDate = dateRange.toDate;

    if (source === DATE_RANGE) {
      fromDate = e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : defaultFromDate;
      toDate = e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : defaultToDate;
    } else if (source === MONTH) {
      fromDate = e
        ? dayjs(e).startOf("month").format("YYYY-MM-DD")
        : defaultFromDate;
      toDate = e ? dayjs(e).endOf("month").format("YYYY-MM-DD") : defaultToDate;
    } else {
      fromDate = dayjs().format("YYYY-MM-DD");
      toDate = dayjs().format("YYYY-MM-DD");
    }
    setSelectedDateSource(source);
    setDateRange({
      fromDate,
      toDate,
    });
  };
  const {
    mostAddToCartProduct,
    mostAddToWishListProduct,
    mostViewedCategory,
    mostViewedProducts,
  } = report;

  const fetchAppUserReport = async () => {
    const { fromDate, toDate } = dateRange;
    if (!fromDate && toDate) {
      return;
    }
    const res = await axios
      .get(
        `customer/appUserlogReport?fromDate=${fromDate}&toDate=${toDate}&limit=${resultLimit}`
      )
      .then((res) => res)
      .catch((err) => err);
    if (res?.data?.status) {
      setReport(res.data);
    }
  };

  const fetchcustomerAnalyticsReport = async () => {
    const { fromDate, toDate } = dateRange;
    if (!fromDate && toDate) {
      return;
    }
    const res = await axios
      .get(
        `customer/customerAnalytics?fromDate=${fromDate}&toDate=${toDate}&count=true`
      )
      .then((res) => res)
      .catch((err) => err);
    if (res?.data?.status) {
      setCustomerAnalyticsReport(res.data);
    }
  };

  const fetchOrderRevenue = async () => {
    const { fromDate, toDate } = dateRange;
    if (!fromDate && toDate) {
      return;
    }
    const res = await axios
      .get(`shopify/orderRevenue?fromDate=${fromDate}&toDate=${toDate}`)
      .then((res) => res)
      .catch((err) => err);
    if (res?.data?.status) {
      setOrderRevenueReport(res.data.results);
    }
  };

  const [appUserActionCount, setAppUserActionCount] = useState();
  const fetchAppUserActionCount = async () => {
    const { fromDate, toDate } = dateRange;
    if (!fromDate && toDate) {
      return;
    }
    const res = await axios
      .get(`customer/appUserActionsChart?fromDate=${fromDate}&toDate=${toDate}`)
      .then((res) => res)
      .catch((err) => err);
    if (res?.data?.status) {
      setAppUserActionCount(res.data.results);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      fetchAppUserReport(),
      fetchcustomerAnalyticsReport(),
      fetchOrderRevenue(),
      fetchAppUserActionCount(),
    ]).finally(() => {
      setIsLoading(false);
    });
  }, [dateRange, resultLimit]);

  let arrayOfObjects = [];

  for (let i = 0; i < 10; i++) {
    let obj = { label: i + 1, value: `${i + 1}` };
    arrayOfObjects.push(obj);
  }
  const maxDate = dayjs().endOf("day");
  const minDate = dayjs().subtract(3, "month");

  const disabledDateRangeDates = (current) => {
    return current && (current < minDate || current > maxDate);
  };
  const disabledMonthDates = (current) => {
    return current && current > maxDate;
  };

  return (
    <div className="border rounded-md bg-gray-50 flex flex-col shadow-md h-full p-3">
      <div className="flex items-center justify-between mb-3">
        <h1 className="font-semibold">Analytics Overview</h1>
        <div className="flex items-center gap-4">
          <div className="border rounded-md px-3 py-1 text-xs font-medium">
            Top
            <Select
              defaultValue={3}
              onChange={(e) => setResultLimit(e)}
              className="mx-2 w-[60px]"
              options={arrayOfObjects}
            />
            Results
          </div>
          <button
            onClick={() => handleDateRangeChange(null, TODAY)}
            className={`bg-white rounded-md border text-sm font-medium px-3 py-1  leading-6  ${
              isTodayFilter ? selectedFilterFocusStyle : "border-gray-300"
            }`}
          >
            Today
          </button>
          <DatePicker
            className={isMonthFilter && selectedFilterFocusStyle}
            onChange={(e) => handleDateRangeChange(e, MONTH)}
            picker="month"
            disabledDate={disabledMonthDates}
          />
          <RangePicker
            allowClear={false}
            disabledDate={disabledDateRangeDates}
            className={isDateRangeFilter && selectedFilterFocusStyle}
            value={[
              dateRange.fromDate ? dayjs(dateRange.fromDate) : null,
              dateRange.toDate ? dayjs(dateRange.toDate) : null,
            ]}
            onChange={(e) => handleDateRangeChange(e, DATE_RANGE)}
          />
        </div>
      </div>
      <div className=" xl:flex gap-3">
        <UserStatsCard
          customerAnalyticsReport={customerAnalyticsReport}
          dateRange={dateRange}
        />
        <OrderRevenueStats
          orderRevenueReport={orderRevenueReport}
          dateRange={dateRange}
        />
      </div>
      <div>
        <AppUserActionChart
          appUserActionCount={appUserActionCount}
          dateRange={dateRange}
        />
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center border rounded-md shadow-md min-h-[300px] my-2">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-wrap gap-2 justify-between">
          <div className="">
            <MostViewedCategory
              title="Top Visiter Category"
              data={mostViewedCategory}
              isLoading={isLoading}
              itemBaseUrl={categoryViewBaseUrl}
            />
          </div>
          <div>
            <MostViewedProduct
              title="Top Viewed Products"
              data={mostViewedProducts}
              isLoading={isLoading}
              itemBaseUrl={productViewBaseUrl}
            />
          </div>
          <div>
            <MostViewedProduct
              title="Most Added to Wishlist Product"
              data={mostAddToWishListProduct}
              itemBaseUrl={productViewBaseUrl}
              isLoading={isLoading}
            />
          </div>
          <div>
            <MostViewedProduct
              title="Most Added to Cart Product"
              data={mostAddToCartProduct}
              isLoading={isLoading}
              itemBaseUrl={productViewBaseUrl}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AppStats;
