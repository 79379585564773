import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import useGetAccess from "../../../hooks/useGetAccess";
import { DELETE_SEGMENT_KEY } from "../../../utils/AccessList/accessList";
import { useSelector } from "react-redux";
const SegmentListOptions = ({ menuBtnFn }) => {
  const getAccess = useGetAccess();

  // ACCESS LIST
  const user = useSelector((state) => state.auth);

  const [accessList, setAccessList] = useState({
    hasDeleteSegmentAccess: getAccess(DELETE_SEGMENT_KEY),
  });

  useEffect(() => {
    setAccessList({
      hasDeleteSegmentAccess: getAccess(DELETE_SEGMENT_KEY),
    });
  }, [user]);

  const { hasDeleteSegmentAccess } = accessList;

  // ACCESS LIST

  const menuItems = [
    {
      title: "Delete Segment",
      icon: "delete",
      action: () => menuBtnFn?.deleteSegment(),
      status: hasDeleteSegmentAccess,
    },
  ];
  return (
    <>
      <Menu as="div" className="relative inline-block text-left select-none">
        <div>
          <Menu.Button className="inline-flex w-full justify-center py-2 text-sm  text-gray-700 ">
            <span className="material-symbols-outlined">more_vert</span>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none select-none">
            <div className="py-1">
              {menuItems.map((item, index) => {
                if (item.status) {
                  return (
                    <Menu.Item key={`menu_item_app_banner_${index}`}>
                      <div
                        onClick={item?.action}
                        className={
                          "text-gray-700 flex cursor-pointer hover:font-medium p-3 gap-2 hover:bg-gray-100"
                        }
                      >
                        <span className="material-symbols-outlined">
                          {item.icon}
                        </span>
                        {item.title}
                      </div>
                    </Menu.Item>
                  );
                }
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default SegmentListOptions;
