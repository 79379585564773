import { useEffect } from "react";
import useAxios from "./useAxios";

const useFetchUserDetailsUsingId = (userId) => {
  const axios = useAxios();
  const fetchUserById = async (id) => {
    try {
      const response = await axios
        .get(`auth/getUserById?userId=${id}`)
        .then((res) => res)
        .catch((err) => err);
      return response;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (userId) {
    fetchUserById(userId);
    }
  }, []);

  return fetchUserById;
};

export default useFetchUserDetailsUsingId;
