import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StaffProfileView from "./StaffProfileView";
import StaffPermissionView from "./StaffPermissionView";
import ManageStaffAccess from "./ManageStaffAccess";
import useFetchUserDetailsUsingId from "../../../hooks/useFetchUserDetailsUsingId";
import { useSelector } from "react-redux";
import useGetAccess from "../../../hooks/useGetAccess";
import {
  MANAGE_USER_PERMISSIONS_KEY,
  REMOVE_USER_KEY,
} from "../../../utils/AccessList/accessList";
import Loader from "../../Loader/Loader";

const ViewStaff = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const getAccess = useGetAccess();

  const user = useSelector((state) => state.auth);
  const { allPermissionList: permissionListOptions } = user;
  const staffId = location?.state?.staffId || null;
  const fetchUserById = useFetchUserDetailsUsingId();
  const [userData, setUserData] = useState(null);
  const [isLoader, setLoader] = useState(false);

  const [accessList, setAccessList] = useState({
    hasManageUserPermissionAccess: getAccess(MANAGE_USER_PERMISSIONS_KEY),
    hasRemoveUserAccess: getAccess(REMOVE_USER_KEY),
  });

  useEffect(() => {
    setAccessList({
      hasManageUserPermissionAccess: getAccess(MANAGE_USER_PERMISSIONS_KEY),
      hasRemoveUserAccess: getAccess(REMOVE_USER_KEY),
    });
  }, [user]);

  const { hasManageUserPermissionAccess, hasRemoveUserAccess } = accessList;

  const getStaffDetails = async () => {
    setLoader(true);
    const response = await fetchUserById(staffId)
      .then((res) => res)
      .catch((err) => err)
      .finally(() => {
        setLoader(false);
      });
    if (response?.data?.status) {
      setUserData(response?.data);
    }
  };

  const getPermissionStatus = () => {
    try {
      const { permissionList } = userData;
      let isAllPermissionGranted = true;
      let permissionAccessCount = 0;
      permissionListOptions.forEach((item) => {
        const getUserPermission = permissionList.find(
          (i) => Number(i.permission_id) === Number(item.id)
        );
        if (getUserPermission) {
          if (!!Number(getUserPermission.status)) {
            permissionAccessCount += 1;
          } else {
            isAllPermissionGranted = false;
          }
        } else {
          isAllPermissionGranted = false;
        }
      });
      if (isAllPermissionGranted) {
        return "All Permissions Granted";
      } else if (permissionAccessCount) {
        return "Limited Permissions";
      } else {
        return "No permissions granted";
      }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (staffId) {
      getStaffDetails();
    } else {
      navigate("/dashboard/user-management/manage-staff");
    }
  }, [staffId]);

  if (isLoader) {
    return (
      <div className="w-full flex justify-center items-center h-[calc(100%-100px)]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="p-10 bg-gray-50">
      <div className="flex gap-2 items-center pb-10">
        <span
          className="material-symbols-outlined cursor-pointer"
          onClick={() => navigate("/dashboard/user-management/manage-staff")}
        >
          arrow_back_ios
        </span>

        <h1 className="text-xl font-medium">Staff Management</h1>
      </div>
      {userData ? (
        <div className="flex flex-col gap-10">
          <StaffProfileView
            permissionStatus={getPermissionStatus()}
            userData={userData}
          />
          {hasManageUserPermissionAccess && (
            <StaffPermissionView
              getStaffDetails={getStaffDetails}
              userData={userData}
              permissionStatus={getPermissionStatus()}
            />
          )}
          {hasRemoveUserAccess && <ManageStaffAccess />}
        </div>
      ) : null}
    </div>
  );
};

export default ViewStaff;
