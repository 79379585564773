export const userRoles = [
  {
    value: 1,
    label: "Admin",
  },
  {
    value: 2,
    label: "Staff",
  },
];

export const permissionCategory = [
  { title: "User Management", key: "user" },
  { title: "App Banner", key: "app_banner" },
  { title: "Category Banner", key: "category_banner" },
  { title: "Push Notification", key: "push_notificaton" },
  { title: "Segment", key: "segment" },
  { title: "Widgets", key: "widget" },
];
