import React, { useState, useRef } from "react";

import AddBannerForm from "../AddBanner/AddBanner";
import DeleteBanner from "../DeleteBanner";
import CategoryBannerAccordian from "./CategoryBannerAccordian";

const CategoryBannerData = ({
  categoryListFromShopify,
  getCollectionBannerDatas,
  categoryBannerImages,
  categoryBanner,
  expandedValueRef,
}) => {
  const [expanded, setExpanded] = useState(expandedValueRef?.current || []);

  const handleExpandlick = (id) => {
    const isExist = expanded?.find((i) => i === id);
    let updatedValues = [];
    if (isExist) {
      updatedValues = expanded.filter((i) => i !== id);
      setExpanded((prev) => prev.filter((i) => i !== id));
    } else {
      updatedValues = [...expanded, id];
      setExpanded((prev) => [...prev, id]);
    }
    expandedValueRef.current = updatedValues;
  };

  const [bannerFormModal, setBannerFormModal] = useState(false);
  const [deleteBannerModal, setDeleteBannerModal] = useState(false);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(null);

  const handleBannerFormModal = (e, categoryItemData) => {
    if (e) {
      setSelectedCategoryItem(categoryItemData);
    } else {
      setSelectedCategoryItem(null);
    }
    setBannerFormModal(e);
  };

  const handleDeleteBannerModal = (e, categoryItemData) => {
    if (e) {
      setSelectedCategoryItem(categoryItemData);
    } else {
      setSelectedCategoryItem(null);
    }
    setDeleteBannerModal(e);
  };

  const menuBtnFn = {
    handleBannerFormModal,
    handleDeleteBannerModal,
  };

  return (
    <>
      {bannerFormModal ? (
        <AddBannerForm
          open={bannerFormModal}
          close={() => handleBannerFormModal(false)}
          isEdit={true}
          bannerData={selectedCategoryItem}
          // fetchBannerList={fetchBannerList}
          isUpdate={true}
          getCollectionBannerDatas={getCollectionBannerDatas}
          isCategoryBannerTab={true}
        />
      ) : null}
      {deleteBannerModal ? (
        <DeleteBanner
          // fetchBannerList={fetchBannerList}
          bannerData={selectedCategoryItem}
          open={deleteBannerModal}
          close={() => handleDeleteBannerModal(false)}
          isCategoryBannerTab={true}
          getCollectionBannerDatas={getCollectionBannerDatas}
        />
      ) : null}

      {Array.isArray(categoryListFromShopify?.menu?.items) &&
        categoryListFromShopify?.menu?.items?.map((val, index) => {
          return (
            <CategoryBannerAccordian
              key={`category_from_shopify_${index}`}
              val={val}
              categoryBannerImages={categoryBannerImages}
              categoryBanner={categoryBanner}
              menuBtnFn={menuBtnFn}
              handleExpandlick={handleExpandlick}
              expanded={expanded}
            />
          );
        })}
    </>
  );
};

export default CategoryBannerData;
