import React from "react";
import Avatar from "../Avatar/Avatar";
import formatDate from "../../utils/FormatDateAndTime/formatDate";

const AppUserListData = ({ data }) => {
  // const addedDate = `${formatDate?.(data?.added_date)?.dateString2 || ""} ${
  //   formatDate?.(data?.added_date)?.localString2 || ""
  // }`; // once the timezone issue is fixed we can use the date and time value

  const addedDate = data?.added_date?.split("T")?.[0] || "";
  const numberOfOrders = data?.shopifyData?.numberOfOrders || 0;
  const name = data?.name || `${data?.shopifyData?.firstName || ""} ${data?.shopifyData?.lastName || ""}`
  return (
    <tr className="border">
      <td className=" py-3 px-10">
        <div className="flex items-center gap-2">
          <Avatar />
          <span className="font-medium cursor-pointer hover:text-primary-800">
            {name}
          </span>
        </div>
      </td>
      <td className=" py-3 px-10">{data.email}</td>
      <td className=" py-3 px-10">{data.phone}</td>
      <td className=" py-3 px-10">{addedDate}</td>
      <td className=" py-3 px-10">{numberOfOrders}</td>
    </tr>
  );
};

export default AppUserListData;
