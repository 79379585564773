import React, { useEffect, useState } from "react";
import Avatar from "../Avatar/Avatar";
import { greenCameraIcon } from "../../Icons/Icons";
import formatDate from "../../utils/FormatDateAndTime/formatDate";
import { useDispatch } from "react-redux";
import { logOutApi } from "../../redux/reducers/auth";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import Loader from "../Loader/Loader";

const cardClass =
  "border flex flex-col font-medium gap-2 rounded-md shadow-md p-5";

const ProfileDetails = ({ user, setUploadedImage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axios = useAxios();

  const { profile, lastLogInLog } = user;
  const email = profile?.email || "";
  const adress = profile?.adress || "";
  const phone = profile?.phone || "";
  const fullName = `${profile?.first_name || ""} ${profile?.last_name || ""}`;
  const roleName = profile?.role_name;
  const image = profile?.image;
  const lastLogin = formatDate(lastLogInLog?.created_at)?.localDate2;


  const handleSetCardLoader = (loaderStatus, title, cardSetFn) => {
    cardSetFn((prev) => {
      return prev.map((item) => {
        if (item.title === title) {
          return {
            ...item,
            loader: loaderStatus,
          };
        } else {
          return item;
        }
      });
    });
  };

  const downloadProfileDetails = async () => {
    handleSetCardLoader(true, "Download Details", setCard3);
    await axios
      .get("auth/downloadProfileDetails", {
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "profile.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        //console.log(err, "err [part");
      }).finally(() => {
        handleSetCardLoader(false, "Download Details", setCard3);
      });
  };

  const [card2, setCard2] = useState([
    {
      title: "Notifications",
      icon: "notifications",
    },
    {
      title: "Messages",
      icon: "chat_bubble",
    },
  ]);
  const [card3, setCard3] = useState([
    {
      title: email,
      icon: "mail",
    },
    {
      title: adress,
      icon: "location_on",
    },
    {
      title: phone,
      icon: "call",
    },
    {
      title: "Download Details",
      icon: "cloud_download",
      action: downloadProfileDetails,
      loader: false,
    },
  ]);


  useEffect(() => {
    setCard3([
      {
        title: email,
        icon: "mail",
      },
      {
        title: adress,
        icon: "location_on",
      },
      {
        title: phone,
        icon: "call",
      },
      {
        title: "Download Details",
        icon: "cloud_download",
        action: downloadProfileDetails,
        loader: false,
      },
    ])
  }, [user])



  const [previewImage, setPreviewImage] = useState(image);
  const [fileErrMsg, setFileErrMsg] = useState("");

  const validateImage = (e) => {
    setFileErrMsg("");
    const imageFile = e.target.files[0];
    const { size, type } = imageFile;
    const fileSizeLimit = 1024000;
    const allowedFileType = /image/;
    const isValidImageFile = allowedFileType.test(type);
    if (isValidImageFile) {
      if (size < fileSizeLimit) {
        setUploadedImage(imageFile);
        setPreviewImage(URL.createObjectURL(imageFile));
      } else {
        setFileErrMsg(
          "Uploaded image file is too large, try uploading image less than 1 MB"
        );
      }
    } else {
      setFileErrMsg(
        `Uploaded file is not a valid image file,
        Only image files are allowed`
      );
    }
  };

  const logOutBtn = async () => {
    await dispatch(logOutApi()).then((res) => {
      if (res?.payload?.status) {
        return navigate("/");
      }
    });
  };

  return (
    <div className="min-w-[250px] flex flex-col gap-5 ">
      <div className="border flex flex-col items-center gap-1.5 rounded-md shadow-md p-5">
        {fileErrMsg && (
          <span className="text-xs text-red-600 font-medium">{fileErrMsg}</span>
        )}

        <div className="relative py-3">
          <Avatar image={previewImage} width="20" height="20" />
          <label
            htmlFor="userProfileImage"
            className="absolute right-0 top-16 cursor-pointer"
          >
            {greenCameraIcon}
          </label>
          <input
            id="userProfileImage"
            onChange={validateImage}
            accept="image/*"
            type="file"
            hidden
          />
        </div>
        <h1 className="font-medium">{fullName}</h1>
        <span className="status status--black">{roleName}</span>
        {lastLogin && (
          <p className="text-sm text-gray-500">Last visit {lastLogin}</p>
        )}
        <button type="button" onClick={logOutBtn} className="btn btn--full">
          Log Out
        </button>
      </div>
      <div className={cardClass}>
        {card2.map((item, index) => (
          <div
            key={`card2_map_key_${index}`}
            className="flex gap-2 items-center cursor-pointer"
          >
            <span className="material-icons text-lg text-primary-600 ">
              {item.icon}
            </span>
            <span className="text-sm">{item.title}</span>
          </div>
        ))}
      </div>
      <div className={cardClass}>
        {card3.map((item, index) => {
          if (item.title) {
            return (
              <div
                key={`card3_map_key_${index}`}
                className="flex gap-2 items-center cursor-pointer"
                onClick={item?.action || null}
              >
                {
                  item.loader ? <Loader height="28" width="28"/> : <span className="material-icons text-lg text-primary-600 ">
                  {item.icon}
                </span>
                }
                <span className="text-sm">{item.title}</span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default ProfileDetails;
