import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import formatDate from "../../../utils/FormatDateAndTime/formatDate";
import useFetchFile from "../../../hooks/useFetchFile";
import {
  notificationImagePathConst,
  imageFileTypeConst,
} from "../../../consts/fileConsts";
import NotificationDeliveryStas from "./NotificationDeliveryStas";

const PushNotificationDetailsModal = ({ open, close, data }) => {
  const {

    big_picture,
    excluded_segments,
    queued_at,
    remaining,
    send_after,
    included_segments,
    platform_delivery_stats,
    completed_at,
    url,
  } = data;

  const notificationImageUrlFull = big_picture?.split("/");
  const formatedNotificationImgUrl =
    notificationImageUrlFull?.[notificationImageUrlFull?.length - 1];

  const [notificationImage, setNotificationImage] = useState(null);
  const fetchFile = useFetchFile();

  const getNotificationImg = async () => {
    const imgSrc = await fetchFile(
      formatedNotificationImgUrl,
      imageFileTypeConst,
      notificationImagePathConst
    );
    setNotificationImage(imgSrc);
  };

  useEffect(() => {
    if (big_picture) {
      getNotificationImg();
    }
  }, []);

  const PlatformDeliveryStatus = ({ deliveryStatus }) => {
    const platforms = Object.keys(deliveryStatus);
    if (Array.isArray(platforms) && platforms?.length) {
      return platforms.map((platform, index) => {
        const { successful, failed, errored } = deliveryStatus[platform];
        return (
          <div
            className="flex flex-col"
            key={`${index}_platform_status_data_keys_on_detail_view`}
          >
            <h1 className="capitalize font-medium">{platform}</h1>
            <div className="flex gap-3">
              <span>Success: {successful}</span>
              <span>Fail: {failed}</span>
              <span>Error: {errored}</span>
            </div>
          </div>
        );
      });
    }
    return "N/A";
  };



  const notificationDetails = [
    {
      title: "Included Segments",
      data: included_segments,
    },
    {
      title: "Excluded Segments",
      data: excluded_segments,
    },
    {
      title: "Image",
      data: [],
      type: "image",
    },
    {
      title: "URL",
      data: [url],
    },
    {
      title: "Remaining",
      data: [remaining],
    },
    {
      title: "Queued at",
      data: [
        `${formatDate(queued_at * 1000)?.dateString2} - ${
          formatDate(queued_at * 1000)?.localTimeString
        }`,
      ],
    },
    {
      title: "Send After",
      data: [
        `${formatDate(send_after * 1000)?.dateString2} - ${
          formatDate(send_after * 1000)?.localTimeString
        }`,
      ],
    },
    {
      title: "Completed At",
      data: [
        `${formatDate(completed_at * 1000)?.dateString2} - ${
          formatDate(completed_at * 1000)?.localTimeString
        }`,
      ],
    },
    {
      title: "Devlivery Status",
      data: [
        <PlatformDeliveryStatus deliveryStatus={platform_delivery_stats} />,
      ],
    },
  ];

  return (
    <Modal
      styles={{
        modal: {
          borderRadius: 0,
          padding: 0,
          margin: 0,
        },
      }}
      open={open}
      onClose={close}
      center
      showCloseIcon={false}
    >
      <div className="w-[700px]">
        <div className="flex justify-between p-5 border-b">
          <h1>View Details</h1>
          <span
            className="cursor-pointer material-symbols-outlined"
            onClick={close}
          >
            close
          </span>
        </div>

        <div className="flex flex-col">
          <div className="flex w-full">
            <div className="bg-gray-200 w-2/5 px-5 font-medium py-3">
              Devliery Statistics
            </div>
            <div className="w-full px-5 py-2">
            <NotificationDeliveryStas
              data={data}
            />
            </div>
          </div>
          {notificationDetails.map((item, index) => {
            const isDataAvailable = item.data.length;
            const isImage = item.type === "image" && big_picture;
            return (
              <div
                className="flex w-full"
                key={`${index}_view_details_modal_item`}
              >
                <div className="bg-gray-200 w-2/5 px-5 font-medium py-3">
                  {item.title}
                </div>
                <div className="w-full px-5 py-2">
                  {isImage ? (
                    <div className="w-[200px] border rounded-md overflow-hidden">
                      <img
                        src={notificationImage}
                        className="object-fit"
                        alt="notificaiton_image"
                      />
                    </div>
                  ) : isDataAvailable ? (
                    <>
                      <ul>
                        {item.data.map((k, index) =>
                          Number.isInteger(k) || k ? (
                            <li key={`${index}_segment_detail_list_key`}>
                              {k}
                            </li>
                          ) : (
                            "N/A"
                          )
                        )}
                      </ul>
                    </>
                  ) : (
                    "N/A"
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default PushNotificationDetailsModal;
