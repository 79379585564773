import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useRefreshToken from "../../hooks/useRefreshToken";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";

const PersistLogin = () => {
  const { token } = useSelector((state) => state.auth.authentication);
  const [isLoading, setIsLoading] = useState(true);
  const refreshToken = useRefreshToken();
  const location = useLocation();
  
  useEffect(() => {
    const verifyAccessToken = async () => {
      setIsLoading(true);
      try {
        await refreshToken().then(() => {
          setIsLoading(false);
        });
      } catch (err) {
        throw err;
      }
    };
    !token ? verifyAccessToken() : setIsLoading(false);
  }, [token]);

  if (token) {
    return (
      <>
        <Outlet />
      </>
    );
  } else if (isLoading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Loader title={"Loading..."} />
      </div>
    );
  } else {
    return (
      <>
        <Navigate to="/" replace state={{ tokenExpired: true, path: location.pathname }} />
      </>
    );
  }
};

export default PersistLogin;
