import React, { useEffect, useState } from "react";
import { generateDates } from "../../../utils/helperFunctions";
import { Chart, registerables } from "chart.js";
import dayjs from "dayjs";

Chart.register(...registerables);

const OrderRevenueStats = ({ dateRange, orderRevenueReport }) => {
  const labels = generateDates(
    dateRange.fromDate,
    dateRange.toDate,
    "DD-MM-YYYY"
  );
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    let revenue = 0;
    let revenueRealized = 0;
    let orderCountWithDates = {};
    let totalNumberOfOrders = 0;
    let revenueWithDates = {};
    let revenueRealizedWithDates = {};

    if (Array.isArray(orderRevenueReport)) {
      orderRevenueReport.forEach((item) => {
        const createdAt = item?.node?.createdAt
          ? dayjs(item?.node?.createdAt.split("T")[0]).format("DD-MM-YYYY")
          : null;
        const isDateInLabel = !!labels.find((i) => i === createdAt);
        if (isDateInLabel) {
          totalNumberOfOrders += 1;
          revenue += Number(item?.node?.totalPriceSet?.shopMoney?.amount || 0);
          revenueRealized += Number(item?.node?.totalReceivedSet?.shopMoney?.amount || 0);
          orderCountWithDates = {
            ...orderCountWithDates,
            [createdAt]: (orderCountWithDates?.[createdAt] || 0) + 1,
          };
          revenueWithDates = {
            ...revenueWithDates,
            [createdAt]:
              (revenueWithDates?.[createdAt] || 0) +
              Number(item?.node?.totalPriceSet?.shopMoney?.amount || 0),
          };
          revenueRealizedWithDates = {
            ...revenueRealizedWithDates,
            [createdAt]:
              (revenueRealizedWithDates?.[createdAt] || 0) +
              Number(item?.node?.totalReceivedSet?.shopMoney?.amount || 0),
          };
        }
      });
    }
    setOrderData((prev) => ({
      ...prev,
      revenue: revenue.toFixed(2),
      revenueRealized: revenueRealized.toFixed(2),
      orderCountWithDates,
      totalNumberOfOrders,
      revenueWithDates,
      revenueRealizedWithDates,
    }));
  }, [orderRevenueReport]);

  
  useEffect(() => {
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Total Order Amount",
          data: labels.map((date) => {
            const getValue = orderData?.revenueWithDates?.[date];
            return Number(getValue || 0);
          }),
          fill: false,
          tension: 0.4,
        },
        {
          label: "Total Amount Received",
          data: labels.map((date) => {
            const getValue = orderData?.revenueRealizedWithDates?.[date];
            return Number(getValue || 0);
          }),
          fill: false,
          tension: 0.4,
        },
        {
          label: "Total Orders",
          data: labels.map((date) => {
            const value = orderData?.orderCountWithDates?.[date];
            return value || 0;
          }),
          fill: false,
          tension: 0.4,
        },
      ],
    };
    const config = {
      type: "line",
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
            },
            suggestedMin: 0,
          },
        },
      },
    };
    const ctx = document.getElementById("orderRevenueChart").getContext("2d");
    const myChart = new Chart(ctx, config);
    return () => {
      myChart.destroy();
    };
  }, [orderData]);

  return (
    <div className="w-full">
      <div className="mb-4 rounded-lg shadow-sm">
        <div className="relative overflow-hidden bg-white rounded-lg shadow-md">
          <div className="relative z-10 px-3 pt-8 pb-10 text-center">
            <div className="flex justify-center gap-10">
              <div>
                <h4 className="text-sm leading-tight text-gray-500 uppercase">
                  REVENUE
                </h4>
                <h3 className="my-3 text-3xl font-semibold leading-tight text-gray-700">
                  {orderData.revenue}
                </h3>
              </div>
              <div>
                <h4 className="text-sm leading-tight text-gray-500 uppercase">
                  REVENUE REALIZED
                </h4>
                <h3 className="my-3 text-3xl font-semibold leading-tight text-gray-700">
                  {orderData.revenueRealized}
                </h3>
              </div>
              <div>
                <h4 className="text-sm leading-tight text-gray-500 uppercase">
                  TOTAL ORDERS
                </h4>
                <h3 className="my-3 text-3xl font-semibold leading-tight text-gray-700">
                  {orderData.totalNumberOfOrders}
                </h3>
              </div>
            </div>
            <div>
              <canvas
                className="w-[100%] max-h-[300px]"
                id="orderRevenueChart"
              ></canvas>
            </div>
            {/* <p className="text-xs leading-tight text-green-500">▲ 57.1%</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderRevenueStats;
