import React, { useState } from "react";
import ProfileDetails from "../../components/Profile/ProfileDetails";
import EditProfileDetails from "../../components/Profile/EditProfileDetails";
import { useSelector } from "react-redux";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import useFetchProfile from "../../hooks/useFetchProfile";

const Profile = () => {
  const axios = useAxios();
  const user = useSelector((state) => state?.auth);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isBtnLoader, setIsBtnLoader] = useState(false)
  const fetchProfile = useFetchProfile()

  const validateUserData = async (data, profileData) => {
    const reqObj = {};
    if (uploadedImage) {
      reqObj.image = uploadedImage;
    }
    const objectKeys = Object.keys(data);
    objectKeys.forEach((key) => {
      if (data?.[key] !== profileData?.[key]) {
        reqObj[key] = data[key];
      }
    });
    const newDataReqObjKeys = Object.keys(reqObj);
    const isNewData = !!Number(newDataReqObjKeys.length);
    if (isNewData) {
      const formData = new FormData();
      uploadedImage && formData.append("image", uploadedImage)
      objectKeys.forEach((item) => {
        formData.append(item, data[item]);
      });
      updateUserProfile(formData);
    } else {
      toast.info("No changes detected")
    }
  };

  const updateUserProfile = async (formatData) => {
    try {
      setIsBtnLoader(true)
      const controller = new AbortController();
      await axios
        .put("auth/updateUser", formatData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          if (res?.data?.status) {
            toast.success(res?.data?.message || "Profile updated")
            await fetchProfile()
          } else {
            toast.error("Something went wrong, Try again")
          }
        })
        .catch((err) => {
          //console.log(err);
          toast.error("Something went wrong, Try again")
        })
        .finally(() => {
          setTimeout(() => {
            setIsBtnLoader(false);
          }, 1000)
        });

        return () => {
          controller.abort()
        }
    } catch (err) {
      toast.error("Something went wrong, Try again")
      //console.log(err, "from err part");
      throw err;
    }
  };

  return (
    <div className="">
      <div className="px-10 py-5 bg-gray-50">
        <h1 className="font-medium text-xl">Profile</h1>
      </div>
      <div className="flex w-full gap-5 bg-white p-10">
        <ProfileDetails setUploadedImage={setUploadedImage} user={user} />
        <EditProfileDetails isBtnLoader={isBtnLoader} validateUserData={validateUserData} user={user} />
      </div>
    </div>
  );
};

export default Profile;
