import React, { useEffect, useState } from "react";
import Select from "react-select";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import {
  INCLUDED_SEGMENTS,
  EXCLUDED_SEGMENTS,
  SEND_AFTER,
} from "../../../consts/notificationFormFieldConsts";

const sendAfterScheduleType = 1;
const sendNowScheduleType = 2;
const requiredField = <span className="font-medium text-red-600">*</span>;
const requiredFieldMsg = (
  <span className="text-sm text-red-600 font-medium py-1">
    This field is required
  </span>
);
const StepTwoCreatePushNotification = (props) => {
  const {
    handleNext,
    handleBack,
    handleChangeData,
    segmentList,
    notificationData,
    formDataErr,
    setFormDataErr,
  } = props;
  const [sheduleType, setScheduleType] = useState(sendNowScheduleType);

  useEffect(() => {
    if (sheduleType === sendAfterScheduleType) {
      if (notificationData[SEND_AFTER]) {
        setFormDataErr((prev) => ({
          ...prev,
          [SEND_AFTER]: {
            error: false,
            title: "Shedule date",
            key: [SEND_AFTER],
            message: "This field is required",
          },
        }));
      } else {
        setFormDataErr((prev) => ({
          ...prev,
          [SEND_AFTER]: {
            error: true,
            title: "Shedule date",
            key: [SEND_AFTER],
            message: "This field is required",
          },
        }));
      }
    } else {
      setFormDataErr((prev) => ({
        ...prev,
        [SEND_AFTER]: {
          error: false,
        },
      }));
    }
  }, [sheduleType, notificationData]);

  const segmentListOptions = segmentList?.map((item) => ({
    label: item?.name,
    value: item?.id,
    isDisabled: !item.is_active,
  }));
  const includedSegmentsListOptions = segmentListOptions?.filter(
    (i) =>
      i?.value !==
      notificationData?.excludedSegments?.find((k) => k?.value === i?.value)
        ?.value
  );
  const excludedSegmentsListOption = segmentListOptions?.filter(
    (i) =>
      i?.value !==
      notificationData?.includedSegments?.find((k) => k?.value === i?.value)
        ?.value
  );
  const handleChangeScheduleType = (e) => {
    setScheduleType(e);
  };

  useEffect(() => {
    if (!Number(Object.keys(notificationData).length)) {
      handleChangeScheduleType(sendNowScheduleType);
    }
  }, [notificationData]);

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col w-1/2">
        <label className="font-medium">Included Segment {requiredField}</label>
        <Select
          className="custom-select-container"
          isMulti
          value={notificationData?.[INCLUDED_SEGMENTS] || []}
          options={includedSegmentsListOptions || []}
          onChange={(e) => handleChangeData(e, INCLUDED_SEGMENTS)}
        />
        {formDataErr[INCLUDED_SEGMENTS] && requiredFieldMsg}
      </div>
      <div className="flex flex-col w-1/2">
        <label className="font-medium">Excluded Segment (optional)</label>
        <Select
          className="custom-select-container"
          isMulti
          options={excludedSegmentsListOption || []}
          onChange={(e) => handleChangeData(e, EXCLUDED_SEGMENTS)}
          value={notificationData?.[EXCLUDED_SEGMENTS] || []}
        />
      </div>
      <div className="flex flex-col w-1/2">
        <div className="flex gap-2 pb-3">
          <div className="flex items-center gap-2 text-sm font-medium">
            <input
              onChange={() => {
                handleChangeData(null, SEND_AFTER);
                handleChangeScheduleType(sendNowScheduleType);
              }}
              checked={sheduleType === sendNowScheduleType}
              type="radio"
            />{" "}
            <span>Send now</span>
          </div>
          <div className="flex items-center gap-2 text-sm font-medium">
            <input
              checked={sheduleType === sendAfterScheduleType}
              type="radio"
              onChange={() => handleChangeScheduleType(sendAfterScheduleType)}
            />{" "}
            <span>
              Schedule{" "}
              {sheduleType === sendAfterScheduleType ? requiredField : null}
            </span>
          </div>
        </div>
        {sheduleType === sendAfterScheduleType ? (
          <>
            <DatePicker
              allowClear={false}
              className="h-10"
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              changeOnBlur
              onChange={(e) =>
                handleChangeData(dayjs(e).toISOString(), SEND_AFTER)
              }
            />
            {formDataErr?.[SEND_AFTER]?.error && requiredFieldMsg}
          </>
        ) : null}
      </div>
      <div className="flex gap-3">
        <button className="btn w-fit" onClick={handleNext}>
          Next
        </button>
        <button className="btn w-fit btn--border" onClick={handleBack}>
          Back
        </button>
      </div>
    </div>
  );
};

export default StepTwoCreatePushNotification;
