import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import Select from "react-select";
import dayjs from "dayjs";

Chart.register(...registerables);

const MONTH_RANGE = "1mo";
const HOUR_RANGE = "1h";
const DAY_RANGE = "1d";

const Outcomes = () => {
  const colors = ["#189634", "#A8E8B6", "#A8E8B6"];
  const [timeRange, setTimeRange] = useState("1mo");

  const generateTimeRange = (range) => {
    let labels = [];
    try {
      switch (range) {
        case MONTH_RANGE:
          const startOfMonth = dayjs().startOf("M");
          const numberOfDaysInMonth = dayjs().daysInMonth();

          for (let i = 0; i < numberOfDaysInMonth; i++) {
            const date = startOfMonth.add(i, "days").format("MMM D");
            labels.push(date);
          }
          break;
        case DAY_RANGE:
          for (let i = 1; i <= 24; i++) {
            labels.push(`${i}:00`);
          }
          break;
      }

      return labels;
    } catch (err) {
      return labels;
    }
  };

  useEffect(() => {
    const labels = generateTimeRange(timeRange);
    const ctx = document.getElementById("OutcomesChart").getContext("2d");
    const myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: "Clicks",
            backgroundColor: colors[0],
            data: [1, 7, 4, 4, 2, 6, 5]
              .concat([1, 7, 4, 4, 0, 6, 10, 9, 8])
              .concat([1, 7, 4, 4, 0, 6, 10, 9, 8]),
            tension: 0.1,
          },
          {
            label: "Session Count",
            backgroundColor: colors[1],
            data: [4, 3, 5, 5, 7, 3, 5].concat([4, 3, 5, 5, 7, 3, 5]),
          },
        ],
      },
      options: {
        scales: {
          y: {
            ticks: {
              callback: (value, index, ticks) => {
                return (index + 1) * 10;
              },
            },
            grid: {
              drawBorder: false,
              lineWidth: 0.5,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
        maxBarThickness: 50,
        categoryPercentage: 0.3,
      },
    });
    return () => {
      myChart.destroy();
    };
  }, [timeRange]);

  const actions = ["Banner Clicks", "Total Banner"];

  return (
    <>
      <div className="border rounded-md p-5 bg-white shadow-md">
        <div className="flex justify-between items-center font-semibold">
          <p>Outcomes</p>
          <Select
            className="custom-select-container font-normal"
            options={[
              { label: "Month", value: MONTH_RANGE },
              { label: "60 Minutes", value: HOUR_RANGE },
              { label: "24 Hour", value: DAY_RANGE },
            ]}
            defaultValue={{ label: "Month", value: MONTH_RANGE }}
            onChange={(e) => setTimeRange(e.value)}
          />
        </div>

        <div className="mx-auto my-3 h-[270px]">
          <canvas id="OutcomesChart" width="200" height="100"></canvas>
        </div>
        <div className="flex gap-2 justify-center">
          {actions.map((actions, index) => (
            <div className="flex items-center" key={index}>
              <span
                style={{ backgroundColor: colors[index] }}
                className={`h-2 w-3 mr-1`}
              ></span>
              <p className="text-sm font-medium">{actions}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Outcomes;
