import React from "react";
import { Link } from "react-router-dom";

const ProfileSettings = () => {
  return (
    <div className="p-10">
      <div className="py-10">
        <h1 className="font-medium text-xl">Profile Settings</h1>
        <span className="text-sm text-gray-500">
          Edit your account settings
        </span>
      </div>

      <div className="flex  justify-center">
        <div className="flex flex-col gap-3">
          <div className="pb-2">
            <h1 className="font-medium text-xl">Change Password</h1>
            <span className="text-sm text-gray-500">
              For an improved account security
            </span>
          </div>
          <div>
            <label className="font-medium">Current Password</label>
            <input type="text" />
          </div>
          <div>
            <label className="font-medium">New Password</label>
            <input type="text" />
          </div>
          <div>
            <label className="font-medium">Confirm Password</label>
            <input type="text" />
          </div>
          <div className="flex justify-end gap-3">
            <Link to="/dashboard"><button className="btn btn--border">Cancel</button></Link>
            <button className="btn">Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
