import React, { useState, useEffect } from "react";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import Avatar from "../Avatar/Avatar";
import UserDataMenu from "./UserDataMenu";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import useGetAccess from "../../hooks/useGetAccess";
import { MANAGE_PORTAL_ACCESS_KEY } from "../../utils/AccessList/accessList";
import { useSelector } from "react-redux";

const UserListData = ({ data }) => {
  const navigate = useNavigate();
  const axios = useAxios();
  const getAccess = useGetAccess();

  // ACCESS LIST

  const user = useSelector((state) => state.auth);

  const [accessList, setAccessList] = useState({
    hasManagePortalAcess: getAccess(MANAGE_PORTAL_ACCESS_KEY),
  });

  useEffect(() => {
    setAccessList({
      hasManagePortalAcess: getAccess(MANAGE_PORTAL_ACCESS_KEY),
    });
  }, [user]);

  const { hasManagePortalAcess } = accessList;

  // ACCESS LIST

  const fullName = `${data?.first_name || ""} ${data?.last_name || ""}`;
  const roleName = data?.role_name || "";
  const email = data?.email || "";
  const accountStatus = data?.account_status_label || "";
  const isPortalAccess = !!Number(data?.status) || 0;
  const userImage = data?.image || null;

  const viewStaff = () => {
    navigate("/dashboard/user-management/view-staff", {
      state: {
        staffId: data.user_account_id,
      },
    });
  };

  const menuBtnAction = {
    viewStaff,
  };

  const getToggleState = (e) => {
    updateAccountStatus(Number(e));
  };

  const updateAccountStatus = async (status) => {
    const reqObj = {
      userId: data.user_account_id,
      status,
    };
    try {
      await axios
        .patch("auth/updateAccountStatus", reqObj)
        .then((res) => {
          if (res?.data?.status) {
            const statusMsg = !!Number(status) ? "granter" : "denided";
            toast.success(`Portal access ${statusMsg} for ${fullName}`);
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    } catch (err) {
      throw err;
    }
  };

  return (
    <tr className="border">
      <td className=" py-3 px-10">
        <div
          className="flex items-center gap-2"
          onClick={menuBtnAction.viewStaff}
        >
          <Avatar image={userImage} />
          <span className="font-medium cursor-pointer hover:text-primary-800">
            {fullName}
          </span>
        </div>
      </td>
      <td className=" py-3 px-10">{roleName}</td>
      <td className=" py-3 px-10">{email}</td>
      <td className=" py-3 px-10">{accountStatus}</td>
      <td className=" py-3 px-10">
        {hasManagePortalAcess ? (
          <ToggleSwitch
            getToggleState={getToggleState}
            isChecked={isPortalAccess}
          />
        ) : (
          <span
            className={`${
              isPortalAccess ? "text-primary-800" : "text-red-600"
            } font-medium`}
          >
            {isPortalAccess ? "Yes" : "No"}
          </span>
        )}
      </td>
      <td className=" py-3">
        <UserDataMenu menuBtnAction={menuBtnAction} />
      </td>
    </tr>
  );
};

export default UserListData;
