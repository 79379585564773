import React, { useEffect, useState } from "react";
import PushNotificationListTable from "../../components/NotificationManagement/PushNotificationList/PushNotificationListTable";
import { Link } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import Loader from "../../components/Loader/Loader";
import useGetAccess from "../../hooks/useGetAccess";
import {
  CREATE_PUSH_NOTIFICATION_KEY,
  VIEW_PUSH_NOTIFICATION_KEY,
} from "../../utils/AccessList/accessList";
import AccessDenied from "../../components/AccessDenied";
import { useSelector } from "react-redux";

const tabs = [
  {
    title: "All",
    tabIndex: 0,
  },
  {
    title: "Active",
    tabIndex: 2,
  },
  {
    title: "Paused",
    tabIndex: 3,
  },
];
const NotificationManagement = () => {
  const axios = useAxios();
  const getAccess = useGetAccess();

  // ACCESS LIST
  const user = useSelector((state) => state.auth);
  const isUserDataLoader = useSelector((state) => state.auth.userDataLoader);

  const [accessList, setAccessList] = useState({
    hasCreatePushNotificationAccess: getAccess(CREATE_PUSH_NOTIFICATION_KEY),
    hasViewPushNotificationAccess: getAccess(VIEW_PUSH_NOTIFICATION_KEY),
  });

  useEffect(() => {
    setAccessList({
      hasCreatePushNotificationAccess: getAccess(CREATE_PUSH_NOTIFICATION_KEY),
      hasViewPushNotificationAccess: getAccess(VIEW_PUSH_NOTIFICATION_KEY),
    });
  }, [user]);

  const { hasCreatePushNotificationAccess, hasViewPushNotificationAccess } =
    accessList;

  // ACCESS LIST

  const [tabIndex, setTabIndex] = useState(tabs[0].tabIndex);
  const [notificaitonList, setNotificationList] = useState(null);
  const [pageComponent, setPageComponent] = useState(<Loader />);

  const fetchNotificationList = async () => {
    await axios
      .get("notification/getAllNotifications?limit=10&offset=0")
      .then((res) => {
        if (res?.data?.status) {
          setNotificationList(res?.data?.result?.notifications || null);
        }
      })
      .catch((err) => {
        //console.log(err);
        setPageComponent("No data found");
      });
  };

  useEffect(() => {
    if (hasViewPushNotificationAccess) {
      fetchNotificationList();
    } else {
      setPageComponent(<AccessDenied />);
    }
  }, []);


  return (
    <div className="p-10">
      <div className="flex justify-end">
        {hasCreatePushNotificationAccess && (
          <Link to="/dashboard/notification-management/create-push-notification">
            <button className="btn btn--black">
              <span className="material-symbols-outlined mr-1">add_circle</span>
              New Push Notification
            </button>
          </Link>
        )}
      </div>
      <div className="py-5">
        <h1 className="text-xl font-semibold">Notification Management</h1>
      </div>
      {/* <div className="flex gap-5 py-5">
        {tabs.map((tab, index) => (
          <span
            key={`notification_management_tab_index_key_${index}`}
            onClick={() => setTabIndex(tab.tabIndex)}
            className={`${
              tab.tabIndex === tabIndex
                ? "border-b-4 border-primary-600 font-semibold"
                : "font-medium"
            } px-3  cursor-pointer`}
          >
            {tab.title}
          </span>
        ))}
      </div> */}
      <div>
        {!isUserDataLoader && Array.isArray(notificaitonList) ? (
          <PushNotificationListTable notificaitonList={notificaitonList} />
        ) : (
          <div className="w-full h-[calc(100vh-360px)] flex justify-center items-center">
            {isUserDataLoader ? <Loader/> : pageComponent}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationManagement;
