import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import useAxios from "../../../hooks/useAxios";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import LoadingButton from "../../LoadingButton";

const fullPageViewContentTypeId = 1;
const selectPageViewContentTypeId = 2;
const SITE_URL = "siteUrl";
const SITE_NAME = "siteName";

const btnClass =
  "text-white bg-primary-800 text-base font-medium inline-flex items-center justify-center text-center px-5 rounded-md focus:outline-none h-10 cursor-pointer";

const AddStaticSite = ({ open, close, getAllStaticPageData }) => {
  const axios = useAxios();
  const htmlContentRef = useRef();
  const [previewPage, setPreviewPage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [siteData, setSiteData] = useState({
    siteUrl: "",
    siteName: "",
    className: "",
  });
  const [contentType, setContentType] = useState(fullPageViewContentTypeId);
  const previousSelectedDiv = useRef("");
  const [component, setComponent] = useState();
  const [btnLoader, setBtnLoader] = useState(false);

  const previewStaticSite = async () => {
    setComponent(<Loader />);
    const response = await axios
      .post("services/getHtmlContent", { siteUrl: siteData.siteUrl })
      .then((res) => res)
      .catch((err) => err)
      .finally(() => {
        setComponent("");
      });
    if (response?.data?.status) {
      setPreviewPage(response.data.html);
    } else {
      setComponent(
        "Failed to fetch preview of the webpage, Check the url and try again"
      );
    }
  };

  const handleSetSiteData = (e, source) => {
    setSiteData((prev) => ({
      ...prev,
      [source]: e,
    }));
  };

  const handleDivClick = (e) => {
    e.preventDefault();
    if (contentType === selectPageViewContentTypeId) {
      const selectedDiv = e.target;
      previousSelectedDiv?.current?.classList?.remove("selected-html-content");
      selectedDiv.classList.add("selected-html-content");
      previousSelectedDiv.current = selectedDiv;
    }
  };

  useEffect(() => {
    if (contentType === fullPageViewContentTypeId) {
      previousSelectedDiv?.current?.classList?.remove("selected-html-content");
      if (previousSelectedDiv.current) {
        previousSelectedDiv.current = "";
      }
    } else {
      previewStaticSite();
    }
  }, [contentType]);

  const saveStaticPage = async () => {
    const className =
      previousSelectedDiv?.current?.className?.replaceAll(
        "selected-html-content",
        ""
      ) || "";
    let divClass = "";
    className.split(" ").forEach((i) => {
      if (i) {
        divClass += `.${i}`;
      }
    });
    setBtnLoader(true);
    await axios
      .post("services/saveStaticPage", { ...siteData, className: divClass })
      .then((res) => {
        if (res?.data?.status) {
          getAllStaticPageData();
          toast.success("Static site added successfully");
          close();
        }
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        setBtnLoader(false);
      });
  };

  const onSubmit = () => {
    let errMsg = "";
    const className =
      previousSelectedDiv?.current?.className?.replaceAll(
        "selected-html-content",
        ""
      ) || "";
    if (siteData.siteName && siteData.siteUrl) {
      if (contentType === selectPageViewContentTypeId && !className) {
        return setErrMsg(
          (!previewPage ? "Click on show preview button and " : "") +
            "Select content by clicking on the content"
        );
      }
      saveStaticPage();
    } else {
      let errFlag = false;
      if (!siteData?.siteName) {
        errMsg += "Site name";
        errFlag = true;
      }
      if (!siteData?.siteUrl) {
        errMsg += `${errFlag ? " and" : ""} Site URL`;
      }
      errMsg += " is required";
      setErrMsg(errMsg);
    }
  };

  return (
    <Modal
      styles={{
        modal: {
          minWidth: "90%",
          padding: "0px",
        },
      }}
      open={open}
      showCloseIcon={false}
      onClose={close}
      closeOnOverlayClick={false}
    >
      <div className="p-5 flex flex-col gap-3">
        <div className="flex justify-between items-center">
          <h1 className="text-xl text-gray-800 font-medium">Add Static Site</h1>
          <span
            onClick={close}
            className="cursor-pointer material-symbols-outlined"
          >
            close
          </span>
        </div>
        <div className="text-red-800 font-medium text-sm">{errMsg}</div>
        <div>
          <span className="font-medium text-gray-600">Site Name</span>
          <input
            type="text"
            onChange={(e) => handleSetSiteData(e.target.value, SITE_NAME)}
          />
        </div>
        <div>
          <span className="font-medium text-gray-600">Site URL</span>
          <input
            type="text"
            onChange={(e) => handleSetSiteData(e.target.value, SITE_URL)}
          />
        </div>
        <div>
          <span className="font-medium text-gray-600">Content Type</span>
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-3 border rounded-md bg-gray-100 py-1 px-2">
              <span>Full Page</span>
              <input
                checked={contentType === fullPageViewContentTypeId}
                onChange={() => setContentType(fullPageViewContentTypeId)}
                type="checkbox"
                className="cursor-pointer"
              />
            </div>
            <div className="flex items-center gap-3 border rounded-md bg-gray-100 py-1 px-2">
              <span>Select Content</span>
              <input
                checked={contentType === selectPageViewContentTypeId}
                onChange={() => setContentType(selectPageViewContentTypeId)}
                type="checkbox"
                className="cursor-pointer"
              />
            </div>
          </div>
          {contentType === selectPageViewContentTypeId ? (
            <div className="my-3">
              <div
                className="bg-teal-50 border-t-4 w-auto border-teal-300 rounded-b text-teal-900 px-4 py-3 shadow-md"
                role="alert"
              >
                <div className="flex gap-3">
                  <div className="">
                    <svg
                      className="fill-current h-6 w-6 text-teal-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </div>
                  <div>
                    <p className="font-medium text-sm">
                      Selected content will be Highlighted with green borders.{" "}
                      <br />{" "}
                      <span className="">
                        Info: click on the content to select
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="flex">
          {previewPage ? (
            <button onClick={() => setPreviewPage("")} className={btnClass}>
              Close Preview
            </button>
          ) : siteData?.siteUrl ? (
            <button onClick={previewStaticSite} className={btnClass}>
              Show Preview
            </button>
          ) : null}
        </div>
        <div
          onClick={handleDivClick}
          ref={htmlContentRef}
          className="cursor-pointer"
          dangerouslySetInnerHTML={{ __html: previewPage }}
        ></div>
        {!previewPage && component ? (
          <div className="flex justify-center items-center">{component}</div>
        ) : null}
        <div className="flex justify-end gap-3">
          <button
            onClick={close}
            className="text-base font-medium inline-flex items-center justify-center text-center px-5 rounded-md bg-gray-100 h-10 cursor-pointer"
          >
            Close
          </button>
          {btnLoader ? (
            <button>
              <LoadingButton title="Saving static site" />
            </button>
          ) : (
            <button onClick={onSubmit} className={btnClass}>
              Save
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddStaticSite;
