import React from "react";
import AppStatDataSwiper from "./AppStatDataSwiper";

const MostViewedProduct = ({ data, isLoading, title, itemBaseUrl }) => {
  const results = data?.status ? data.results.nodes : null;
  return (
    <AppStatDataSwiper results={results} isLoading={isLoading} title={title} itemBaseUrl={itemBaseUrl} />
  );
};

export default MostViewedProduct;
