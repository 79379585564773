import React from "react";

const Notifications = () => {
  return (
    <div className="flex items-center relative cursor-pointer">
      <span className="material-icons text-gray-600 ">notifications</span>
      <span className="absolute block w-[10px] h-[10px] bg-green-600 rounded-full right-0 top-0 border-[2px] border-white"></span>
    </div>
  );
};

export default Notifications;
