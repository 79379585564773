import React from "react";
import { formatDeviceType } from "../../utils/helperFunctions";
const AuthenticationDetails = ({ user }) => {
  const { profile, lastLogInLog, lastPasswordResetLog } = user;

  const fullName = `${profile?.first_name || ""} ${profile?.last_name || ""}`;
  const lastLoginDeviveType = formatDeviceType(lastLogInLog.device_type);

  return (
    <div className="p-5 font-medium">
      <div className="pb-5">
        <h1 className="font-semibold">Authentication Details</h1>
      </div>
      <div className="flex justify-between gap-5 text-xs max-w-[340px]">
        <ul className="flex flex-col gap-1">
          <li>User Name:</li>
          {/* <li>Login Password:</li> */}
          {lastLoginDeviveType && <li>Last Login:</li>}
          <li>Last Password Change:</li>
        </ul>
        <ul className="flex flex-col gap-1">
          <li>{fullName}</li>
          {/* <li>**********</li> */}
          {lastLoginDeviveType && <li>{lastLoginDeviveType}</li>}
          <li>3 Months Ago</li>
          {/* need to do this part */}
        </ul>
      </div>
    </div>
  );
};

export default AuthenticationDetails;
