import { useEffect } from "react";
import useAxios from "./useAxios";
import { useDispatch } from "react-redux";
import { setUserData } from "../redux/reducers/auth";

const useFetchProfile = () => {
  const axios = useAxios();
  const dispatch = useDispatch();

  const fetchProfile = async () => {
    try {
      const response = await axios
        .get(`auth/myProfile`)
        .then((res) => res)
        .catch((err) => err);
      if (response?.data?.status) {
        dispatch(setUserData(response?.data));
      }
      return response;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
      fetchProfile();
  }, []);

  return fetchProfile;
}

export default useFetchProfile
