import React, { useState, useEffect } from "react";
import SegmentListTable from "../../../components/NotificationManagement/Segment/SegmentListTable";
import AddSegment from "../../../components/NotificationManagement/Segment/AddSegment";
import useAxios from "../../../hooks/useAxios";
import useGetAccess from "../../../hooks/useGetAccess";
import Loader from "../../../components/Loader/Loader";
import AccessDenied from "../../../components/AccessDenied";
import {
  CREATE_SEGMENT_KEY,
  VIEW_SEGMENT_KEY,
} from "../../../utils/AccessList/accessList";
import { useSelector } from "react-redux";

const tabs = [
  {
    title: "All",
    tabIndex: 0,
  },
  {
    title: "Active",
    tabIndex: 2,
  },
  {
    title: "Paused",
    tabIndex: 3,
  },
];

const Segment = () => {
  const [tabIndex, setTabIndex] = useState(tabs[0].tabIndex);
  const [isCreateSegmentModal, setCreateSegmentModal] = useState(false);
  const [segmentList, setSegmentList] = useState(null);
  const [pageComponent, setPageComponent] = useState(<Loader />);
  const axios = useAxios();
  const getAccess = useGetAccess();

  // ACCESS LIST
  const user = useSelector((state) => state.auth);
  const isUserDataLoader = useSelector((state) => state.auth.userDataLoader);

  const [accessList, setAccessList] = useState({
    hasCreateSegmentAccess: getAccess(CREATE_SEGMENT_KEY),
    hasViewSegmentAccess: getAccess(VIEW_SEGMENT_KEY),
  });

  useEffect(() => {
    setAccessList({
      hasCreateSegmentAccess: getAccess(CREATE_SEGMENT_KEY),
      hasViewSegmentAccess: getAccess(VIEW_SEGMENT_KEY),
    });
  }, [user]);

  const { hasCreateSegmentAccess, hasViewSegmentAccess } = accessList;

  // ACCESS LIST

  const fetchSegmentList = async () => {
    await axios
      .get("notification/segmentList")
      .then((res) => {
        if (res?.data?.status) {
          setSegmentList(res?.data?.results?.segments || []);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (hasViewSegmentAccess) {
      fetchSegmentList();
    } else {
      setPageComponent(<AccessDenied />);
    }
  }, []);
  
  const handleCreateSegmentModal = (e) => {
    setCreateSegmentModal(e);
  };
  return (
    <div className="p-10 h-[calc(100%-240px)]">
      <AddSegment
        open={isCreateSegmentModal}
        close={() => handleCreateSegmentModal(false)}
      />
      <div className="flex justify-end">
        {hasCreateSegmentAccess && (
          <button
            className="btn btn--black"
            onClick={() => handleCreateSegmentModal(true)}
          >
            <span className="material-symbols-outlined mr-1">add_circle</span>
            New Segment
          </button>
        )}
      </div>
      <div className="py-5">
        <h1 className="text-xl font-semibold">Segment Listing</h1>
      </div>
      <div className="flex gap-5 py-5">
        {tabs.map((tab, index) => (
          <span
            key={`tab_index_seegment_page_${index}`}
            onClick={() => setTabIndex(tab.tabIndex)}
            className={`${
              tab.tabIndex === tabIndex
                ? "border-b-4 border-primary-600 font-semibold"
                : "font-medium"
            } px-3  cursor-pointer`}
          >
            {tab.title}
          </span>
        ))}
      </div>

      {!isUserDataLoader && Array.isArray(segmentList) ? (
        <div>
          <SegmentListTable segmentList={segmentList} />
        </div>
      ) : (
        <div className="flex justify-center items-center h-full">
          {isUserDataLoader ? <Loader/> : pageComponent}
        </div>
      )}
    </div>
  );
};

export default Segment;
