import React from 'react'

const ManageStaffAccess = () => {
  return (
    <div className="flex justify-between">
      <div className="w-1/5 py-10">
        <h1 className="font-medium">Manage Staff Access</h1>
      </div>
      <div className="bg-white flex flex-col gap-4 w-4/5 p-10 ">
       <h1 className='font-medium'>Remove Jhone Doe</h1>
       <span>Removed staff members will be permanently removed from your portal</span>
        <button className='btn btn--red max-w-[270px]'>Remove Access</button>
      </div>
    </div>
  )
}

export default ManageStaffAccess