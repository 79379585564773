import React, { useEffect, useRef, useState } from "react";
import useAxios from "../../../hooks/useAxios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { permissionCategory } from "../../../utils/Permissions/permissionList";

const STAFF_ROLE_ID = 2;

const StaffPermissionView = ({
  userData,
  getStaffDetails,
  permissionStatus,
}) => {
  const axios = useAxios();
  const user = useSelector((state) => state.auth);
  const [permissionCategoryList, setPermissionCategoryList] =
    useState(permissionCategory);
  const { allPermissionList: permissionListOptions } = user;
  const { permissionList, results } = userData;
  const [userPermissions, setUserPermissions] = useState([]);
  const initialPermissionList = useRef();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isSubmitBtn, setIsSubmitBtn] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const firstName = results?.first_name || "Staff";
  const roleId = Number(results?.role_id);



  useEffect(() => {
    if (roleId === STAFF_ROLE_ID) {
      setPermissionCategoryList(
        permissionCategory.filter((i) => i.key !== "user")
      );
    }
    if (permissionList) {
      let permissionArray = permissionListOptions;
      if (roleId === STAFF_ROLE_ID) {
        permissionArray = permissionListOptions.filter((i) => i.category !== "user")
      }
      const formatPermissionList = permissionArray.map((item) => {
        const isPermissionGranted = permissionList?.find(
          (i) => Number(i.permission_id) === Number(item.id)
        );
        return {
          permissionId: Number(item.id),
          status: Number(isPermissionGranted?.status) || 0,
          title: item.title,
          key: item.key,
          category: item.category,
        };
      });
      setUserPermissions(formatPermissionList);
      initialPermissionList.current = formatPermissionList;
    }
  }, [userData]);

  const handleChangePermission = (e, permissionId) => {
    setUserPermissions((prev) =>
      prev.map((i) => {
        if (Number(i.permissionId) === Number(permissionId)) {
          return {
            ...i,
            status: Number(e),
          };
        } else {
          return i;
        }
      })
    );
  };
  useEffect(() => {
    let isAllPermissionGranted = true;
    let isChangeDetectedInPermissionStatus = false;
    userPermissions.forEach((item) => {
      if (Number(item.status) === 0) {
        isAllPermissionGranted = false;
      }
      const getPermission = initialPermissionList.current.find(
        (i) => Number(i.permissionId) === Number(item.permissionId)
      );
      if (Number(getPermission.status) !== Number(item.status)) {
        isChangeDetectedInPermissionStatus = true;
      }
    });
    setIsSubmitBtn(isChangeDetectedInPermissionStatus);
    setIsAllSelected(isAllPermissionGranted);
  }, [userPermissions]);

  const handleSelectAllPermissions = (e) => {
    setUserPermissions((prev) =>
      prev.map((i) => ({ ...i, status: Number(e) }))
    );
  };

  const updateUserPermission = async () => {
    try {
      const reqObj = {
        userId: results.user_account_id,
        permissions: userPermissions,
      };
      await axios
        .patch("auth/updateUserPermissions", reqObj)
        .then((res) => {
          if (res?.data?.status) {
            getStaffDetails();
            toast.success(res?.data?.message || "Permissions updated");
          } else {
            toast.error("Failed to update user permissions");
          }
        })
        .catch((err) => {
          //console.log(err);
          toast.error("Failed to update user permissions");
        });
    } catch (err) {
      toast.error("Failed to update user permissions");
      //console.log(err, "err");
    }
  };

  return (
    <div className="flex justify-between">
      <div className="w-1/5 py-10">
        <h1 className="font-medium">Permissions</h1>
      </div>
      <div className="bg-white  w-4/5 p-10 ">
        <div className="flex justify-between items-center">
          <h1 className="font-medium">
            {firstName} have {permissionStatus.toLowerCase()} in this portal
          </h1>
          <div className="border whitespace-nowrap rounded-md p-2 font-medium flex items-center gap-1">
            <input
              onChange={(e) => handleSelectAllPermissions(e.target.checked)}
              checked={isAllSelected}
              type="checkbox"
              className="cursor-pointer"
            />
            Select All
          </div>
        </div>

        <div className="border rounded-md shadow-sm flex gap-5 my-3">
          <ul className="border-r w-fit ">
            {permissionCategoryList.map((item, index) => (
              <li
                onClick={() => setCategoryIndex(index)}
                key={`${index}_permisstion_category_list_key`}
                className={`cursor-pointer text-sm hover:bg-gray-100 p-2 ${
                  index === categoryIndex ? "bg-gray-200" : ""
                }`}
              >
                {item.title}
              </li>
            ))}
          </ul>
          <div className="flex whitespace-nowrap flex-col">
            {userPermissions.map((item, index) => {
              const isPermissionGranted = Number(item.status);
              if (item.category === permissionCategoryList[categoryIndex].key) {
                return (
                  <span
                    className="flex items-center gap-2  py-1"
                    key={`permissiion_list_staff_view_key_${index}`}
                  >
                    {" "}
                    <input
                      checked={isPermissionGranted}
                      className="cursor-pointer"
                      type="checkbox"
                      onChange={(e) =>
                        handleChangePermission(
                          e.target.checked,
                          item.permissionId
                        )
                      }
                    />{" "}
                    <p className="text-sm font-medium">{item.title}</p>
                  </span>
                );
              }
            })}
          </div>
        </div>

        {isSubmitBtn && (
          <div className="py-3">
            <button
              onClick={updateUserPermission}
              type="button"
              className="btn"
            >
              Update Permissions
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffPermissionView;
