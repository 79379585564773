import React from "react";

const PaginationBar = (props) => {
  // MUST REQUERED PROPS TO PASS FROM PARENT USE THE SAME OBJECT
  // page={
  //   {
  //     pageNo,
  //     setPageNo,
  //     pageSize,
  //     setPageSize,
  //     totalRecords
  //   }
  // }

  const { page } = props;
  const totalPages = Math.ceil(page?.totalRecords / page?.pageSize) || 1;
  const listFrom = page?.pageNo * page?.pageSize - page?.pageSize + 1;
  const listTo = page?.pageNo * page?.pageSize;

  const pageNumberOptions = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumberOptions.push({ value: `${i}`, label: i });
  }

  const hasNextPageNo = page?.pageNo < totalPages;
  const hasPrevPageNo = page?.pageNo > 1;

  const nextPage = () => {
    if (hasNextPageNo) {
      page?.setPageNo(page?.pageNo + 1);
    }
  };

  const previousPage = () => {
    if (hasPrevPageNo) {
      page?.setPageNo(page?.pageNo - 1);
    }
  };

  const selectedPageOption = (label) => {
    const selectedPage = Number(page?.pageNo) === Number(label);
    return selectedPage;
  };

  return (
    <div className="bg-gray-100 p-3  flex items-center justify-between">
      <div className="flex items-center gap-10">
        <select
          className="w-16 bg-transparent border-none"
          onChange={(e) => page?.setPageSize(Number(e.target.value))}
          value={page?.pageSize}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
        <p>
          Found {page?.totalRecords || 0} {props.title || "Records"}
        </p>
      </div>
      <div className="items-per-page">
        {listFrom || 0} {"-"}{" "}
        {listTo > page?.totalRecords ? page?.totalRecords : listTo || 0} of{" "}
        {page?.totalRecords} items
      </div>
      <div className="page-navigation flex items-center gap-8">
        <ul className="flex items-center gap-2">
          <span>
            <select
              className="w-14 bg-transparent border-none"
              onChange={(e) => page?.setPageNo(Number(e.target.value))}
              value={page?.pageNo}
            >
              {pageNumberOptions.length
                ? pageNumberOptions.map((page) => {
                    return (
                      <option
                        value={page.value}
                        defaultValue={selectedPageOption(page.label)}
                        key={page.value}
                      >
                        {page.label}
                      </option>
                    );
                  })
                : null}
            </select>
          </span>
          of {totalPages} pages
        </ul>
        <div className="flex items-center gap-8">
          <button onClick={() => previousPage()}>
            <span
              className={`material-symbols-outlined ${
                !hasPrevPageNo && "text-gray-400"
              }`}
            >
              chevron_left
            </span>
          </button>
          <button onClick={() => nextPage()}>
            <span
              className={`material-symbols-outlined ${
                !hasNextPageNo && "text-gray-400"
              }`}
            >
              chevron_right
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaginationBar;
