import { useSelector } from "react-redux";

const useGetAccess = () => {
  const user = useSelector((state) => state.auth);
  const isSuperAdmin =
    Number(process.env.REACT_APP_SUPER_ADMIN_ROLE_ID) ===
    Number(user?.profile?.role_id);
  const isDevRole =
    Number(user?.profile?.role_id) ===
    Number(process.env.REACT_APP_DEVELOPER_ROLE_ID);

  const { accessList, allPermissionList } = user;

  const getAccess = (permissionKey) => {
    if (isSuperAdmin || isDevRole) {
      return true;
    }
    if (permissionKey) {
      try {
        const getPermission = allPermissionList?.find(
          (i) => i?.key === permissionKey
        );
        const isAllowedAccess =
          !!Number(
            accessList?.find(
              (i) => Number(i?.permission_id) === Number(getPermission?.id)
            )?.status
          ) || false;
        return isAllowedAccess;
      } catch (err) {
        throw err;
      }
    }
  };

  return getAccess;
};

export default useGetAccess;
