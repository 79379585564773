import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import useAxios from "../../../hooks/useAxios";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { generateDates } from "../../../utils/helperFunctions";
import { chartColors } from "../../../utils/helperFunctions";
import isBetween from "dayjs/plugin/isBetween"

Chart.register(...registerables);
const { RangePicker } = DatePicker;
dayjs.extend(isBetween)

const BannerOverview = () => {
  const axios = useAxios();
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(7, "days"),
    dayjs(),
  ]);
  const startDate = dayjs(dateRange?.[0]).format("YYYY-MM-DD");
  const endDate = dayjs(dateRange?.[1]).format("YYYY-MM-DD");

  const [bannerOverviewData, setBannerOverviewData] = useState([]);
  const [dateRangeValues, setDateRangeValues] = useState([])

  const fetchBannerOverview = async () => {
    await axios
      .get(`banner/bannerOverview?fromDate=${startDate}&toDate=${endDate}`)
      .then((res) => {
        if (res?.data?.status) {
          setBannerOverviewData(res.data.results);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    const getDateRangeValues = generateDates(startDate, endDate);
    setDateRangeValues(getDateRangeValues)
    fetchBannerOverview();
  }, [dateRange]);

  useEffect(() => {
    const ctx = document.getElementById("BannerOverviewChart").getContext("2d");
    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: dateRangeValues,
        datasets: bannerOverviewData.map((item, index) => {
          return ({
            label: item.title,
            backgroundColor: index < chartColors.length ? chartColors[index] : chartColors[index - chartColors.length],
            data: dateRangeValues.map((date) => {
              const Banneritem = bannerOverviewData?.find?.((i) => dayjs(date)?.isBetween(dayjs(i?.start_date), dayjs(i?.end_date), 'day', '[]'));
              if (Banneritem) {
                return Number(item.popularity || 0);
              }
              return 0;
            })
          })
        }),
        borderWidth: 2,
      },
      options: {
        scales: {
          y: {
            ticks: {
              callback: function (value, index, ticks) {
                return value;
              },
            },
            grid: {
              drawBorder: false,
              lineWidth: 0.5,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        maintainAspectRatio: false,
      },
    });
    return () => {
      myChart.destroy();
    };
  }, [dateRangeValues, bannerOverviewData]);


  return (
    <>
      <div className="border rounded-md p-5 bg-white shadow-md">
        <div className="flex justify-between items-center font-semibold">
          <p>Banner Overview</p>
          <RangePicker
            className="h-10"
            onChange={(val) => {
              setDateRange(val);
            }}
            value={dateRange}
            changeOnBlur
          />
        </div>

        <div className="mx-auto my-3 min-h-[270px]">
          <canvas id="BannerOverviewChart" width="200" height="100"></canvas>
        </div>
        <div className="flex gap-2 justify-center">
          {/* {actions.map((actions, index) => (
            <div className="flex items-center" key={index}>
              <span
                style={{ backgroundColor: colors[index] }}
                className={`h-2 w-3 mr-1`}
              ></span>
              <p className="text-sm font-medium">{actions}</p>
            </div>
          ))} */}
        </div>
      </div>
    </>
  );
};

export default BannerOverview;
