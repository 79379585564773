import React from "react";
import formatDate from "../../../utils/FormatDateAndTime/formatDate";
import ActionDetailsView from "./ActionDetailsView";

const AppLogData = ({ data }) => {
  const {
    created_date,
    action_details,
    device_platform,
    is_guest,
    target_id,
    action_type,
  } = data;
  const createdDate = created_date ? `${formatDate(created_date).dateString2} ${formatDate(created_date).localTimeString}` : "";
  const actionDetailsKey = Object.keys(action_details);


  return (
    <tr className="text-xs border  font-medium even:bg-gray-100">
      <td className=" py-2 px-1 border break-words">{target_id}</td>
      <td className=" py-2 px-1 border whitespace-nowrap">{createdDate}</td>
      <td className=" py-2 px-1 text-center border">{device_platform}</td>
      <td className=" py-2 px-1 border">
        {actionDetailsKey.map((item) => {
          const value = action_details[item];
          if (typeof value === "object") {
            const itemKey = Object.keys(value || {});
            return itemKey.map((i) => {
              const itemValue = value[i];
              return <ActionDetailsView label={i} value={itemValue} />;
            });
          } else {
            return <ActionDetailsView label={item} value={value} />;
          }
        })}
      </td>
      <td className=" py-2 px-1 border">{action_type}</td>
      <td className=" py-2 px-1 border text-center">
        {is_guest ? "Yes" : "No"}
      </td>
    </tr>
  );
};

export default AppLogData;
