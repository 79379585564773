import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import SegmentFilters from "./SegmentFilters";
import SelectedSegmentFilters from "./SelectedSegmentFilters";
import { toast } from "react-toastify";

const AddSegment = ({ open, close }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(true);
  const [lastOperator, setLastOperator] = useState("");

  const handleSelectFilter = (filter) => {
    setShowFilters(false);
    setSelectedFilters((prev) => [...prev, filter]);
  };

  const handleRemoveSelectedFilter = (filter) => {
    let positionIndex = null;
    let newArr = selectedFilters.filter((i, index) => {
      if (i.id !== filter.id) {
        return i;
      } else {
        positionIndex = index;
      }
    });
    if (Number.isInteger(positionIndex)) {
      if (
        newArr[positionIndex] &&
        Object.prototype.hasOwnProperty.call(newArr[positionIndex], "operator")
      ) {
        newArr = newArr.filter((i, index) => index !== positionIndex);
      }
    }
    if (
      newArr[newArr.length - 1] &&
      Object.prototype.hasOwnProperty.call(
        newArr[newArr.length - 1],
        "operator"
      )
    ) {
      newArr = newArr.slice(0, -1);
    }
    setSelectedFilters(newArr);
  };

  const handleShowFilter = (e, operator) => {
    setSelectedFilters((prev) => [...prev, { operator }]);
    setShowFilters(e);
    setLastOperator(operator);
  };
  const handleRemoveOperator = () => {
    setShowFilters(false);
    setSelectedFilters(selectedFilters.slice(0, -1));
  };

  return (
    <Modal
      open={open}
      onClose={close}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      styles={{
        modal: {
          padding: "0px",
          minWidth: "800px",
          borderRadius: "0px",
          height: "calc(100vh - 50px)",
        },
      }}
    >
      <div className="bg-gray-200 flex justify-between items-center py-5 font-medium px-10 text-lg">
        <h1>Create New Segment</h1>

        <span
          onClick={close}
          className="material-symbols-outlined cursor-pointer"
        >
          close
        </span>
      </div>
      <div className="px-10">
        <div>
          <div className="flex justify-between gap-10 py-5">
            <div className="flex flex-col gap-3">
              <div>
                <label className="font-medium">Name of the segment</label>
                <input type="text" />
              </div>
              <p className="text-gray-600 text-sm">
                Users in this segment must meet these rules
              </p>
            </div>
            <div>
              <p className="text-gray-600 text-sm">
                Total Subscriptions (estimate)
              </p>
              <h1 className="font-medium">Pending</h1>
            </div>
          </div>
          <SelectedSegmentFilters
            selectedFilters={selectedFilters}
            handleRemoveSelectedFilter={handleRemoveSelectedFilter}
            handleShowFilter={handleShowFilter}
            showFilters={showFilters}
            setSelectedFilters={setSelectedFilters}
          />
          {showFilters || selectedFilters.length === 0 ? (
            <SegmentFilters
              selectedFilters={selectedFilters}
              handleSelectFilter={handleSelectFilter}
              showFilters={showFilters}
              handleRemoveOperator={handleRemoveOperator}
              lastOperator={lastOperator}
            />
          ) : null}
        </div>
        <div className="py-3 flex justify-end gap-3">
          <button className="btn btn--border" onClick={close}>Close</button>
          <button className="btn" onClick={() => toast.info("Paid plan required")}>Create Segment</button>
        </div>
      </div>
    </Modal>
  );
};

export default AddSegment;
