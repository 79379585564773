import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useFetchFile from "../../hooks/useFetchFile";
import Loader from "../Loader/Loader";
import {
  userImagePathConst,
  imageFileTypeConst,
} from "../../consts/fileConsts";

const Avatar = (props) => {
  const defaultAvatar = "/images/avatar.jpg";
  const [image, setImage] = useState(defaultAvatar);
  const [imgLoader, setImgLoader] = useState(false);
  const fetchFile = useFetchFile();

  const getImage = async () => {
    const imageSrc = await fetchFile(
      props?.image,
      imageFileTypeConst,
      userImagePathConst
    );
    setImage(imageSrc);
  };

  useEffect(() => {
    try {
      setImgLoader(true);
      if (props?.image) {
        const isBlobImage = props?.image?.split(":")?.[0] === "blob";
        if (isBlobImage) {
          setImage(props.image);
        } else {
          getImage();
        }
      } else {
        setImage(defaultAvatar);
      }
    } catch (err) {
      setImage(defaultAvatar);
      throw err;
    } finally {
      setImgLoader(false);
    }
  }, [props.image]);

  return (
    <div
      className={`avatar-div relative w-${props.width ? props.width : "10"} h-${
        props.height ? props.height : "10"
      } ${props.classes || ""}`}
    >
      <Link to={props.link || "#"} className="inline-block">
        <figure
          className={`w-${props.width ? props.width : "10"} h-${
            props.height ? props.height : "10"
          } overflow-hidden rounded-full`}
        >
          {imgLoader ? (
            <Loader height="25" width="25" color={props.loaderBg || "#000"} />
          ) : (
            <img
              id="avatar"
              src={image}
              className="w-full h-full"
              alt="image"
            />
          )}
        </figure>

        {props.status === "available" && (
          <span className="absolute block w-[14px] h-[14px] bg-green-600 rounded-full right-[0px] bottom-[0px] border-[2px] border-white"></span>
        )}
      </Link>
    </div>
  );
};

export default Avatar;
