import React, { useRef } from "react";
import {
  NOTIFICATION_CONTENT,
  NOTIFICATION_NAME,
  NOTIFICATION_HEADING,
  NOTIFICATION_IMAGE,
} from "../../../consts/notificationFormFieldConsts";

const requiredField = <span className="font-medium text-red-600">*</span>;

const requiredFieldMsg = (
  <span className="text-sm text-red-600 font-medium py-1">
    This field is required
  </span>
);

const StepOneCreatePushNotification = (props) => {
  const { handleNext, handleChangeData, notificationData, formDataErr } = props;

  const fileUploadBtnRef = useRef();
  const imageFIleName = notificationData?.image?.name || "";
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col">
        <label className="font-medium">
          Notification Title {requiredField}
        </label>
        <input
          value={notificationData?.[NOTIFICATION_NAME] || ""}
          onChange={(e) => handleChangeData(e.target.value, NOTIFICATION_NAME)}
          type="text"
        />
        {formDataErr?.[NOTIFICATION_NAME]?.error && requiredFieldMsg}
      </div>
      <div className="flex flex-col">
        <label className="font-medium">Notification Heading</label>
        <input
          value={notificationData?.[NOTIFICATION_HEADING] || ""}
          onChange={(e) =>
            handleChangeData(e.target.value, NOTIFICATION_HEADING)
          }
          type="text"
        />
      </div>
      <div className="flex flex-col">
        <label className="font-medium">Notification Text {requiredField}</label>
        <textarea
          value={notificationData?.[NOTIFICATION_CONTENT] || ""}
          onChange={(e) =>
            handleChangeData(e.target.value, NOTIFICATION_CONTENT)
          }
          className="h-20"
        ></textarea>
        {formDataErr?.[NOTIFICATION_CONTENT]?.error && requiredFieldMsg}
      </div>
      <div className="flex flex-col">
        <label className="font-medium">Notification Image(optional)</label>
        <div className="relative">
          <input value={imageFIleName} disabled type="text" />
          <input
            ref={fileUploadBtnRef}
            id="uploadNotificationImage"
            type="file"
            accept="image/*"
            hidden
            onChange={(e) =>
              handleChangeData(e.target.files[0], NOTIFICATION_IMAGE)
            }
          />

          <button
            onClick={() => fileUploadBtnRef?.current?.click()}
            htmlFor="uploadNotificationImage"
            className="absolute right-0 z-50 btn btn--border"
            style={{
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
          >
            <span className="material-symbols-outlined text-gray-800">
              upload
            </span>
          </button>
        </div>
      </div>
      <div className="flex">
        <button className="btn w-fit" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default StepOneCreatePushNotification;
