import React, { useState, useEffect } from "react";
import AddVideoBanner from "../../components/VideoBanner/AddVideoBanner";
import useAxios from "../../hooks/useAxios";
import { Table, Pagination } from "antd";
import DeleteVideoBanner from "../../components/VideoBanner/DeleteVideoBanner";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import { toast } from "react-toastify";
import formatDate from "../../utils/FormatDateAndTime/formatDate";

const VideoBanner = () => {
  const axios = useAxios();
  const videoBannerTypeId = 4;
  const [isAddVideoBanner, setAddVideoBanner] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const pageSize = 10;
  const baseUrl = `banner/getAllBannerList?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&sortBy=added_date`;
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteVideoBannerModal, setDeleteVideobBannerModal] = useState(false);

  const videoBannerBaseUrl = `${process.env.REACT_APP_FILE_SERVE_URL}/video_banners`;

  const fetchBannerList = async () => {
    const getBannerUrl = `${baseUrl}&bannerType=${videoBannerTypeId}`;
    await axios
      .get(getBannerUrl)
      .then((res) => {
        if (res?.data?.status) {
          setTotalRecords(res?.data?.totalRecords || 0);
          const results = res?.data?.results || [];
          let formattedArr = [];
          results.forEach((item, index) => {
            const fileType = item?.video?.slice(
              ((item?.video?.lastIndexOf(".") - 1) >>> 0) + 2
            );
            formattedArr.push({
              key: index + 1,
              title: item.title,
              product: item?.product?.title || "Not applicable",
              collection: item?.collection?.title,
              active: !!Number(item.active),
              previewLink: item.video,
              videoType: fileType,
              id: item.id,
              created_date: `${formatDate(item.added_date).dateString2} ${formatDate(item.added_date).localString2}`,
              description: item.description,
            });
          });

          setDataSource(formattedArr);
        } else {
          setDataSource([]);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchBannerList();
  }, [pageNo]);

  const deleteVideoBanner = (e) => {
    setDeleteVideobBannerModal(e);
  };
  const updateBannerStatus = async (e, id) => {
    const bannerId = id;
    if (!bannerId) {
      toast.error("Something went wrong, Try again later");
      return;
    }
    await axios
      .patch("banner/updateBannerStatus", {
        bannerId,
        active: Number(e),
      })
      .then((res) => {
        if (res?.data?.status) {
          fetchBannerList();
          toast.success(
            `Banner ${e ? "activated" : "deactivated"} successfully`
          );
        } else {
          toast.error(res?.data?.message || "Failed to update banner status");
        }
      })
      .catch((err) => {
        // console.log(err)
        toast.error(
          err?.res?.data?.message || "Failed to update banner status"
        );
      });
  };

  const columns = [
    {
      title: "Preview",
      dataIndex: "preview",
      key: "preview",
      render: (_, record) => (
        <div className="p-2 rounded-md border max-w-[250px]">
          <video controls width="250">
            <source
              src={`${videoBannerBaseUrl}/${record.previewLink}`}
              type={`video/${record.videoType}`}
            />
            Your browser does not support the video tag.
          </video>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Created Date and Time",
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (_, record) => (
        <>
          <ToggleSwitch
            getToggleState={(e) => updateBannerStatus(e, record.id)}
            isChecked={!!Number(record.active)}
          />
        </>
      ),
    },
    {
      title: "Collection",
      dataIndex: "collection",
      key: "collection",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Delete",
      key: "delete",
      render: (_, record) => (
        <button onClick={() => deleteVideoBanner(record)}>
          <span className="material-symbols-outlined text-red-600">delete</span>
        </button>
      ),
    },
  ];

  return (
    <div className={`w-auto h-full`}>
      {deleteVideoBannerModal && (
        <DeleteVideoBanner
          bannerData={deleteVideoBannerModal}
          close={() => setDeleteVideobBannerModal(false)}
          fetchBannerList={fetchBannerList}
        />
      )}
      {isAddVideoBanner && (
        <AddVideoBanner
          open={isAddVideoBanner}
          close={() => setAddVideoBanner(false)}
          isAddVideoBanner={isAddVideoBanner}
          fetchBannerList={fetchBannerList}
        />
      )}
      <div className="p-10">
        <div className="flex justify-between">
          <h1 className="text-xl font-semibold">Video Banner</h1>
          <button
            className="btn btn--black"
            onClick={() => setAddVideoBanner(true)}
          >
            Add Video Banner
          </button>
        </div>
        <div className="py-3">
          <Table pagination={false} dataSource={dataSource} columns={columns} />
          <Pagination
            className="py-3"
            responsive={true}
            pageSize={pageSize}
            current={pageNo}
            total={totalRecords}
            onChange={(e) => setPageNo(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoBanner;
