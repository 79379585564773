import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AppBannerDataMenu from "../AppBannerDataMenu";

const itemClassName = "flex items-center gap-5";
const dummyImageUrl = "/images/imagedummy.png";

const CategoryBannerAccordian = ({
  val,
  categoryBannerImages,
  categoryBanner,
  menuBtnFn,
  expanded,
  handleExpandlick,
}) => {
  const bannerImageMain =
    categoryBannerImages?.find((i) => i?.itemId === val?.id)?.imgSrc || null;
  const getValItem = categoryBanner?.find((i) => i?.id === val?.id);

  return (
    <Accordion expanded={!!expanded?.find((i) => i === val?.id) || false}>
      <AccordionSummary
        expandIcon={
          val?.items?.length ? (
            <ExpandMoreIcon onClick={() => handleExpandlick(val.id)} />
          ) : <ExpandMoreIcon className="text-gray-200" />
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className="w-full">
          <div className="flex items-center justify-between">
            <div className={itemClassName}>
              <span className="mx-auto">
                <img
                  src={bannerImageMain || dummyImageUrl}
                  className="object-cover rounded-md border w-36 h-16"
                />
              </span>
              <div className="flex items-center gap-3">
                <span>{val.title}</span>
              </div>
            </div>

            <div className="flex items-center gap-3">
              {/* <span className="border rounded-md bg-gray-100 px-3 text-gray-800">
                Click count: {getValItem?.popularity || "0"}
              </span> */}
              <AppBannerDataMenu
                categoryBannerImg={bannerImageMain}
                isCategoryBannerTab={true}
                menuBtnFn={menuBtnFn}
                categoryItem={val}
              />
            </div>
          </div>
        </Typography>
      </AccordionSummary>
      {Array.isArray(val?.items) && val?.items?.length ? (
        <AccordionDetails>
          {val.items.map((item, index) => {
            return (
              <CategoryBannerAccordian
                key={`${index}`}
                val={item}
                categoryBannerImages={categoryBannerImages}
                categoryBanner={categoryBanner}
                menuBtnFn={menuBtnFn}
                handleExpandlick={handleExpandlick}
                expanded={expanded}
              />
            );
          })}
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
};

export default CategoryBannerAccordian;
