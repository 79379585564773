import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import useAxios from "../../../hooks/useAxios";
import { chartColors, generateDates } from "../../../utils/helperFunctions";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
Chart.register(...registerables);

const UserStatsCard = ({ dateRange, customerAnalyticsReport }) => {
  const navigate = useNavigate();
  const totalAppUserCount = customerAnalyticsReport?.totalAppUserCount || 0;
  const labels = generateDates(
    dateRange.fromDate,
    dateRange.toDate,
    "DD-MM-YYYY"
  );
  const appUserCountWithAddedDate =
    customerAnalyticsReport?.appUserCountWithAddedDate || [];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "App Users",
        data: labels.map((date) => {
          const getValue = appUserCountWithAddedDate.find(
            (i) => dayjs(i.cusotmer_added_date).format("DD-MM-YYYY") === date
          );
          if (getValue) {
            return getValue?.count || 0;
          }
          return 0;
        }),
        fill: false,
        tension: 0.4,
        borderColor: chartColors[0],
        backgroundColor: chartColors[4],
      },
    ],
  };
  const config = {
    type: "line",
    data: data,
    options: {
      responsive: true,
      maintainAspectRatio: true,
      interaction: {
        intersect: false,
      },
      scales: {
        x: {
          display: true,
          title: {
            display: true,
          },
        },
        y: {
          display: true,
          title: {
            display: true,
          },
          suggestedMin: 0,
        },
      },
    },
  };
  useEffect(() => {
    const ctx = document
      .getElementById("customerAnaliticsChart")
      .getContext("2d");
    const myChart = new Chart(ctx, config);
    return () => {
      myChart.destroy();
    };
  }, [customerAnalyticsReport]);

  const openAppUserListPage = () => {
    navigate("/dashboard/app-user-list");
  };

  return (
    <div className="w-full">
      <div className="rounded-lg shadow-sm mb-4">
        <div className="rounded-lg bg-white shadow-md  relative overflow-hidden">
          <div className="px-3 pt-8 pb-6 text-center relative z-10">
            <h4 className="text-sm uppercase text-gray-500 leading-tight">
              {"APP USERS"}
            </h4>
            <h3 className="text-3xl text-gray-700 font-semibold leading-tight my-3">
              {totalAppUserCount}
            </h3>
            <div>
              <canvas
                className="w-[100%] max-h-[300px]"
                id="customerAnaliticsChart"
              ></canvas>
            </div>
            {/* <p className="text-xs text-green-500 leading-tight">▲ 57.1%</p> */}
            <div
              onClick={openAppUserListPage}
              className="text-xs text-green-500 leading-tight cursor-pointer"
            >
              <span className="border rounded-md px-2 py-1 shadow-sm hover:bg-green-100 hover:text-gray-800 hover:font-medium hover:border-green-700">
                Show all users
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserStatsCard;
