export const userRoleOptions = [
  { value: 1, label: "Admin" },
  { value: 2, label: "Staff" },
];

export const portalAccessOptions = [
  { value: 1, label: "Granted" },
  { value: 0, label: "Restricted" },
];

export const accountStatusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Pending" },
];
