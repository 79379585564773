import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Loader from "../../Loader/Loader";

const AppStatDataSwiper = ({ results, isLoading, title, itemBaseUrl }) => {
  const handleOpenNewTab = (handle) => {
    if (handle && itemBaseUrl) {
      window.open(`${itemBaseUrl}/${handle}`, "_blank").focus();
    }
  };
  return (
    <div className="border flex flex-col shadow-md rounded-md bg-white p-2 h-full">
      <div>
        <h1 className="font-medium text-center">{title}</h1>
      </div>

      <div className="flex items-center justify-center h-full">
        {Array.isArray(results) ? (
          <Swiper
            modules={[Navigation]}
            slidesPerView={"auto"}
            navigation
            updateOnWindowResize
            className="max-w-[300px] h-full border  rounded-md"
          >
            {results.map((item, index) => {
              const imgSrc =
                item?.images?.edges?.[0]?.node.url || item?.image?.url;
              return (
                <SwiperSlide
                  key={`${index}_most_viewed_category`}
                  className="my-3"
                >
                  <div className="flex flex-col items-center h-full justify-center  px-3 py-6 ">
                    <figure
                      className={`w-20 h-20 overflow-hidden rounded-full border`}
                    >
                      {" "}
                      {!imgSrc ? (
                        <div
                          className={`relative inline-flex items-center justify-center w-20 h-20
                      } overflow-hidden bg-gray-200 rounded-full dark:bg-gray-600`}
                        >
                          <span className="font-medium  text-gray-600 dark:text-gray-300">
                            {item?.title?.split(" ")?.map?.((i) => i?.[0]) ||
                              ""}
                          </span>
                        </div>
                      ) : (
                        <img
                          id="avatar"
                          src={imgSrc}
                          className="w-full h-full"
                          alt="image"
                        />
                      )}
                    </figure>
                    <span
                      onClick={() => handleOpenNewTab(item.handle)}
                      className="text-center hover:text-green-600 cursor-pointer hover:font-medium py-2"
                    >
                      {item.title}
                    </span>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : isLoading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          "No data found"
        )}
      </div>
    </div>
  );
};

export default AppStatDataSwiper;
