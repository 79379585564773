import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";

const initialState = {
  profile: {},
  authentication: {},
  lastLogInLog: {},
  lastPasswordResetLog: {},
  accessList: [],
  starterLayoutLoader: false,
  allPermissionList: [],
  userDataLoader: false,
  fetchUserDataLoader: false,
};

export const logOutApi = createAsyncThunk("auth/logOut", async () => {
  try {
    const response = await axios.post("auth/logout");
    sessionStorage.removeItem("accessToken");
    return response.data;
  } catch (err) {
    throw err;
  }
});

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const {
        userData,
        token,
        lastLogInLog,
        lastPasswordResetLog,
        permissionList,
        results,
      } = action.payload;
      state.profile = userData || results || state.profile;
      state.authentication = { token: token || state.authentication.token };
      state.lastLogInLog = lastLogInLog || state.lastLogInLog;
      state.lastPasswordResetLog =
        lastPasswordResetLog || state.lastPasswordResetLog;
      state.accessList = permissionList || state.accessList;
    },
    clearAllUserData: (state) => {
      state.profile = {};
      state.authentication = {};
      state.lastLogInLog = {};
      state.lastPasswordResetLog = {};
      state.accessList = [];
    },
    setStarterLayoutLoader: (state, action) => {
      state.starterLayoutLoader = action.payload;
    },
    setAllPermissionsList: (state, action) => {
      state.allPermissionList = action?.payload || state.allPermissionList;
    },
    setUserDataLoader: (state, action) => {
      state.userDataLoader = action.payload
    },
    setFetchUserDataLoader: (state, action) => {
      state.fetchUserDataLoader = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logOutApi.fulfilled, (state, action) => {
      state.profile = {};
      state.authentication = {};
      state.lastLogInLog = {};
      state.lastPasswordResetLog = {};
      state.accessList = [];
    });
  },
});

export const {
  increment,
  amountAdded,
  setUserData,
  clearAllUserData,
  setStarterLayoutLoader,
  setAllPermissionsList,
  setUserDataLoader,
  setFetchUserDataLoader,
} = authReducer.actions;
export default authReducer.reducer;
