import React from 'react';
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const onSubmit = () => {
    navigate("/reset-password")
  }
  return (
    <div className="min-w-[400px]">
      <h1 className="form-head mb-2">FORGOT YOUR PASSWORD</h1>
      <div className='mb-10'>
        <p className='text-sm text-gray-500'>Please enter the email address you’d like your<br/> password reset information send you</p>
      </div>
      <div className="flex flex-col mb-9">
        <div className="flex flex-col gap-3">
          <label className="form-label">Email</label>
          <input
            className="form-input"
            placeholder="Enter Your Email Address"
            type="text"
          />
        </div>
      </div>
      <div>
        <button className="btn btn--full" onClick={onSubmit}>Request Password Reset Link</button>
      </div>
      <div className=' py-4 font-medium'>
        <Link to="/"><button className='btn btn--border btn--full'>Back to Login</button></Link>
      </div>
    </div>
  )
}

export default ForgotPassword