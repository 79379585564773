import React, { useEffect, useState } from "react";
import { chartColors } from "../../../utils/helperFunctions";

import useAxios from "../../../hooks/useAxios";
import formatDate from "../../../utils/FormatDateAndTime/formatDate";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const OrderStatitics = () => {
  const axios = useAxios();
  const [orderStatusData, setOrderStatusData] = useState({});
  const {
    orderCount,
    cancelledCount,
    fulfillableOrders,
    fullyPaidOrderCount,
    unPaidOrderCount,
  } = orderStatusData;

  const data = [
    {
      title: "Total Orders",
      value: orderCount,
    },
    {
      title: "Cancelled Orders",
      value: cancelledCount,
    },
    {
      title: "Full Fillable Orders",
      value: fulfillableOrders,
    },
    {
      title: "Fully Paid Orders",
      value: fullyPaidOrderCount,
    },
    {
      title: "Un Paid Orders",
      value: unPaidOrderCount,
    },
  ];

  useEffect(() => {
    const ctx = document.getElementById("orderStatitics").getContext("2d");
    const myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: [
          "Total Orders",
          "Cancelled Orders",
          "Full Fillable Orders",
          "Fully Paid Orders",
          "Un Paid Orders",
        ],
        datasets: [
          {
            label: "# of Orders",
            data: [
              orderCount || 0.0001,
              cancelledCount || 0.0001,
              fulfillableOrders || 0.0001,
              fullyPaidOrderCount || 0.0001,
              unPaidOrderCount || 0.0001,
            ],
            backgroundColor: chartColors,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {},
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
    return () => {
      myChart.destroy();
    };
  }, [orderStatusData]);
  const today = formatDate(new Date()).date;

  const fetchOrderStatus = async () => {
    const fromDate = `${today}T00:00:00.000Z`;
    await axios
      .get(`shopify/orderStats?pageLimit=100&fromDate=${fromDate}$toDate=${fromDate}`)
      .then((res) => {
        if (res?.data?.status) {
          setOrderStatusData(res.data);
        } else {
          setOrderStatusData([]);
        }
      })
      .catch((err) => {
        //console.log(err);
        setOrderStatusData([]);
      });
  };

  useEffect(() => {
    fetchOrderStatus();
  }, []);

  return (
    <>
      <div className="border rounded-md p-3  h-full bg-white shadow-md">
        <div className="flex justify-between">
          <p className="font-semibold">Order Details</p>
          <span>{today}</span>
        </div>

        <div className="flex flex-col xlg:flex-row items-center py-4">
          <div className="max-w-[150px] max-h-[150px] px-3">
            <canvas className="w-full h-full" id="orderStatitics"></canvas>
          </div>

          <div className="flex pt-5 flex-wrap xlg:flex-col w-3/4">
            {data.map((item, index) => {
              return (
                <div
                  className="flex justify-between pb-3 pr-5 text-sm items-center"
                  key={`banner_activities_key_${index}`}
                >
                  <span
                    style={{ backgroundColor: chartColors[index] }}
                    className={`xlg:h-3 xlg:w-5 h-2 w-3 rounded-[20px] mr-3`}
                  ></span>

                  <p className="text-left w-4/5">{item.title}</p>
                  <h3 className=" text-gray-800 ml-2 font-semibold">
                    {item.value}
                  </h3>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderStatitics;
