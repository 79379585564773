import React, { useState, useEffect } from "react";
import AppKey from "../../components/AppSettings/AppKey";

const AppSettings = () => {
  const [toggleTab, setToggleTab] = useState(1);
  return (
    <div className="px-10 py-5">
      <div className="flex justify-between pb-3">
        <h1 className="text-xl font-semibold">App Settings</h1>
      </div>
      <div className="flex flex-col gap-2">
        <div className="w-full">
          <div className="px-2 py-1">
            <div className="flex gap-3  border-b">
              <button
                className={`${
                  toggleTab === 1
                    ? "border-b-2 border-b-primary-800  font-medium"
                    : "px-1 py-3"
                }`}
                onClick={() => setToggleTab(1)}
              >
                App Key
              </button>
            </div>
          </div>
        </div>
        <AppKey/>
      </div>
    </div>
  );
};

export default AppSettings;
