import React, { useState } from "react";
import StaticSitePreview from "./StaticSitePreview";

const tdStyles = "px-10 py-5 border-b";

const StaticSiteData = ({ data }) => {
  const [isStaticSitePreviewModal, setStaticSitePreviewModal] = useState(false);

  const handleSetStaticSitePreviewModal = (e) => {
    setStaticSitePreviewModal(e);
  };



  return (
    <>
      {isStaticSitePreviewModal ? (
        <StaticSitePreview
          open={isStaticSitePreviewModal}
          close={() => handleSetStaticSitePreviewModal(false)}
          data={data}
        />
      ) : null}
      <tr className="text-center hover:bg-gray-100">
        <td className={`${tdStyles} font-medium`}>{data.site_name}</td>
        <td className={tdStyles}>{data.site_url}</td>
        <td className={tdStyles}>{data.access_key}</td>
        <td className={tdStyles}>
          <span
            onClick={() => handleSetStaticSitePreviewModal(true)}
            className="material-symbols-outlined text-gray-700 cursor-pointer"
          >
            preview
          </span>
        </td>
      </tr>
    </>
  );
};

export default StaticSiteData;
