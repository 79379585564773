//USER
export const VIEW_USER_KEY = "view_user";
export const ADD_USER_KEY = "add_user";
export const MANAGE_USER_PERMISSIONS_KEY = "manage_permissions";
export const REMOVE_USER_KEY = "remove_user";
export const MANAGE_PORTAL_ACCESS_KEY = "manage_portal_access";

//APP BANNER
export const VIEW_APP_BANNER_KEY = "view_app_banner";
export const MANAGE_BANNER_STATUS_KEY = "manage_banner_status";
export const ADD_APP_BANNER_KEY = "add_app_banner";
export const EDIT_APP_BANNER_KEY = "edit_app_banner";
export const DELETE_APP_BANNER_KEY = "delete_app_banner";

// CATEGORY BANNER
export const VIEW_CATEGORY_BANNER_KEY = "view_category_banner";
export const EDIT_CATEGORY_BANNER_KEY = "edit_category_banner";
export const DELETE_CATEGORY_BANNER_KEY = "delete_category_banner";

// PUSH NOTIFICAITON
export const CREATE_PUSH_NOTIFICATION_KEY = "create_push_notification";
export const VIEW_PUSH_NOTIFICATION_KEY = "view_push_notification";
export const CANCEL_SCHEDULED_PUSH_NOTIFICATION_KEY = "cancel_scheduled_push_notification";

// SEGMENT
export const CREATE_SEGMENT_KEY = "create_segment";
export const VIEW_SEGMENT_KEY = "view_segment";
export const DELETE_SEGMENT_KEY = "delete_segment";

//WIDGETS
export const VIEW_BANNER_ACTIVITIES_WIDGET = "view_banner_activities_widget"
export const VIEW_BANNER_VISITORS_WIDGET = "view_banner_visitors_widget"
export const VIEW_NOTIFICATION_ACTIVITY_WIDGET = "view_notification_activity_widget"
export const VIEW_BANNER_OVERVIEW_WIDGET = "view_banner_overview_widget"
export const VIEW_OUTCOMES_WIDGET = "view_outcomes_widget"

// APP KEY