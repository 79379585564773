import axios from "../helpers/axios";
import { setUserData } from "../redux/reducers/auth";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useRef } from "react";

const useRefreshToken = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const firstTimeNotification = useRef(true);

  const checkCookieExists = (response) => {
    if (
      response?.response?.data?.message === "Cookie doesn't exist" &&
      firstTimeNotification?.current &&
      location?.pathname !== "/"
    ) {
      toast.warn("Session Expired, Log in Again");
      navigate("/");
      firstTimeNotification.current = false;
    }
  };

  const refreshToken = async () => {
    const response = await axios
      .get("auth/newToken", {
        withCredentials: true,
      })
      .then((res) => res)
      .catch((err) => err);

    if (response?.data?.status) {
      const accessToken = response.data.token;
      sessionStorage?.setItem("accessToken", accessToken);
      const decodedData = jwtDecode(accessToken);
      dispatch(setUserData({ ...decodedData.data, ...response.data }));
      return accessToken;
    } else {
      sessionStorage?.removeItem("accessToken");
    }
    checkCookieExists(response);
  };
  return refreshToken;
};

export default useRefreshToken;
