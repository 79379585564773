import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import useAxios from "../../../hooks/useAxios";
import { chartColors } from "../../../utils/helperFunctions";
Chart.register(...registerables);

const BannerActivities = () => {
  const axios = useAxios();
  const [bannerActivityData, setBannerActivityData] = useState({});
  const {
    total_banners,
    in_progress_banner_count,
    pending_banner_count,
    completed_banner_count,
    default_banner_count,
    active_banner_count,
    in_active_banner_count,
  } = bannerActivityData;

  const data = [
    {
      title: "Total Banners",
      value: total_banners || 0,
    },
    {
      title: "In Progress Banners",
      value: in_progress_banner_count || 0,
    },
    {
      title: "Pending Banners",
      value: pending_banner_count || 0,
    },
    {
      title: "Completed Banners",
      value: completed_banner_count || 0,
    },
    {
      title: "Default Banners",
      value: default_banner_count || 0,
    },
    {
      title: "Active Banners",
      value: active_banner_count || 0,
    },
    {
      title: "In Active Banners",
      value: in_active_banner_count || 0,
    },
  ];

  useEffect(() => {
    const ctx = document
      .getElementById("bannerActivitesChart")
      .getContext("2d");
    const myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: [
          "Total Banners",
          "In Progress Banners",
          "Pending Banners",
          "Completed Banners",
          "Default Banners",
          "Active Banners",
          "In Active Banners",
        ],
        datasets: [
          {
            label: "# of Banner",
            data: [
              total_banners || 0.0001,
              in_progress_banner_count || 0.0001,
              pending_banner_count || 0.0001,
              completed_banner_count || 0.0001,
              default_banner_count || 0.0001,
              active_banner_count || 0.0001,
              in_active_banner_count || 0.0001,
            ],
            backgroundColor: chartColors,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {},
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
    return () => {
      myChart.destroy();
    };
  }, [bannerActivityData]);

  const fetchBannerActivities = async () => {
    await axios
      .get("banner/bannerActivities")
      .then((res) => {
        if (res?.data?.status) {
          setBannerActivityData(res.data.result);
        } else {
          setBannerActivityData({});
        }
      })
      .catch((err) => {
        setBannerActivityData({});
      });
  };
  useEffect(() => {
    fetchBannerActivities();
  }, []);

  return (
    <>
      <div className="border rounded-md p-3  h-full bg-white shadow-md">
        <div className="flex justify-between">
          <p className="font-semibold">App Banner Activities</p>
        </div>

        <div className="flex flex-col xlg:flex-row items-center py-4">
          <div className="max-w-[150px] max-h-[150px] px-3">
            <canvas id="bannerActivitesChart"></canvas>
          </div>
          <div className="flex pt-5 flex-wrap xlg:flex-col w-3/4">
            {data.map((item, index) => {
              return (
                <div
                  className="flex  justify-between  w-full pb-3 pr-5 text-sm items-center"
                  key={`banner_activities_key_${index}`}
                >
                  <span
                    style={{ backgroundColor: chartColors[index] }}
                    className={`xlg:h-3 xlg:w-5 h-2 w-3 rounded-[20px] mr-3`}
                  ></span>

                  <p className="text-left w-4/5">{item.title}</p>
                  <h3 className=" text-gray-800 ml-2 font-semibold">
                    {item.value}
                  </h3>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerActivities;
