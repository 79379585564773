import React, { useState } from "react";
import {
  URL,
  NOTIFICATION_NAME,
  NOTIFICATION_CONTENT,
  INCLUDED_SEGMENTS,
  EXCLUDED_SEGMENTS,
} from "../../../consts/notificationFormFieldConsts";
import { toast } from "react-toastify";
import useAxios from "../../../hooks/useAxios";
import LoadingButton from "../../LoadingButton";

const StepThreeCreatePushNotification = (props) => {
  const [isLoaderBtn, setLoaderBtn] = useState(false);
  const axios = useAxios();
  const {
    handleBack,
    handleChangeData,
    notificationData,
    setFormDataErr,
    handleClearAllFormData,
  } = props;

  const validateData = async (data) => {
    const requiredFields = [
      { title: "Notification name", key: NOTIFICATION_NAME },
      { title: "Notification text", key: NOTIFICATION_CONTENT },
      { title: "Target audience", key: INCLUDED_SEGMENTS },
    ];
    let isDataExists = true;
    const errField = {};
    requiredFields.forEach((item) => {
      const value = data[item.key];
      if (!value || (Array.isArray(value) && !value?.length)) {
        isDataExists = false;
        errField[item.key] = {
          error: true,
          title: item.title,
          key: item.key,
          message: "This field is required",
        };
      }
      setFormDataErr(errField);
    });
    return isDataExists;
  };

  const onSubmit = async () => {
    const isAllDataExists = await validateData(notificationData);
    if (isAllDataExists) {
      const formData = new FormData();
      const keys = Object.keys(notificationData);
      keys.forEach((item) => {
        let value = notificationData[item];
        const isSegments =
          item === INCLUDED_SEGMENTS || item === EXCLUDED_SEGMENTS;
        if (isSegments) {
          value = notificationData[item].map((item) => item.label);
        }
        formData.append(item, value);
      });
      setLoaderBtn(true);
      await axios
        .post("notification/createNotification", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res?.data?.status) {
            handleClearAllFormData();
            toast.success(res?.data?.message || "");
          } else {
            toast.error(
              res?.data?.message || "Failed to create push notification"
            );
          }
        })
        .catch((err) => {
          
          toast.error(
            err?.res?.data?.message || "Failed to create push notification"
          );
        })
        .finally(() => {
          setLoaderBtn(false);
        });
    } else {
      toast.info("Fill out all the required fields", {
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  };
  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col">
        <label className="font-medium">URL (optional)</label>
        <input
          onChange={(e) => handleChangeData(e.target.value, URL)}
          type="text"
        />
      </div>
      <div className="flex gap-3">
        {isLoaderBtn ? (
          <LoadingButton title="Creating Push Notification" />
        ) : (
          <button onClick={onSubmit} className="btn w-fit">
            Create Push Notification
          </button>
        )}
        <button className="btn w-fit btn--border" onClick={handleBack}>
          Back
        </button>
      </div>
    </div>
  );
};

export default StepThreeCreatePushNotification;
