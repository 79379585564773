import React from "react";
import formatDate from "../../../utils/FormatDateAndTime/formatDate";
import SegmentListOptions from "./SegmentListOptions";
import useAxios from "../../../hooks/useAxios";
import { toast } from "react-toastify";
const tableDataStyle = `py-3 text-left px-5`;

const SegmentListData = ({ data }) => {
  const axios = useAxios();
  const deleteSegment = async () => {
    await axios
      .delete(`notification/deleteSegment?segmentId=${data?.id}`)
      .then((res) => {
        toast.info(res?.data?.message || "Paid plan required");
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const menuBtnFn = {
    deleteSegment,
  };
  const tableData = [
    {
      value: data.name,
    },
    {
      value: (
        <span className={`status status--${data.is_active ? "green" : "red"}`}>
          {data.is_active ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      value: formatDate(data.created_at).dateString2,
    },
    {
      value: <SegmentListOptions menuBtnFn={menuBtnFn} />,
    },
  ];
  return (
    <tr className="border">
      {tableData.map((item, index) => (
        <td key={`table_data_segments_key_${index}`} className={tableDataStyle}>
          {item.value}
        </td>
      ))}
    </tr>
  );
};

export default SegmentListData;
