import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
import StepOneCreatePushNotification from "./StepOneCreatePushNotification";
import StepTwoCreatePushNotification from "./StepTwoCreatePushNotification";
import StepThreeCreatePushNotification from "./StepThreeCreatePushNotification";
import DevicePreview from "../DevicePreview";
import useAxios from "../../../hooks/useAxios";
import AccessDenied from "../../AccessDenied";
import {
  URL,
  NOTIFICATION_NAME,
  NOTIFICATION_HEADING,
  NOTIFICATION_CONTENT,
  NOTIFICATION_IMAGE,
  INCLUDED_SEGMENTS,
  EXCLUDED_SEGMENTS,
  SEND_AFTER,
} from "../../../consts/notificationFormFieldConsts";
import useGetAccess from "../../../hooks/useGetAccess";
import { CREATE_PUSH_NOTIFICATION_KEY } from "../../../utils/AccessList/accessList";
import { useSelector } from "react-redux";

const CreatePushNotification = () => {
  const axios = useAxios();
  const [activeStep, setActiveStep] = React.useState(0);
  const [notificationData, setNotificationData] = useState({});
  const [segmentList, setSegmentList] = useState([]);
  const [formDataErr, setFormDataErr] = useState({});
  const getAccess = useGetAccess();

  const user = useSelector((state) => state.auth);

  const [accessList, setAccessList] = useState({
    hasCreatePushNotificationAccess: getAccess(CREATE_PUSH_NOTIFICATION_KEY),
  });

  useEffect(() => {
    setAccessList({
      hasCreatePushNotificationAccess: getAccess(CREATE_PUSH_NOTIFICATION_KEY),
    });
  }, [user]);

  const { hasCreatePushNotificationAccess } = accessList;

  const fetchSegmentList = async () => {
    await axios
      .get("notification/segmentList")
      .then((res) => {
        if (res?.data?.status) {
          setSegmentList(res?.data?.results?.segments || []);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchSegmentList();
  }, []);

  const handleChangeData = (value, source) => {
    setNotificationData((prev) => ({
      ...prev,
      [source]: value,
    }));
  };

  const handleClearAllFormData = () => {
    setNotificationData({});
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      label: "Welcome Notification",
      component: (
        <StepOneCreatePushNotification
          notificationData={notificationData}
          handleChangeData={handleChangeData}
          handleNext={handleNext}
          formDataErr={formDataErr}
        />
      ),
    },
    {
      label: "Target & Scheduling",
      component: (
        <StepTwoCreatePushNotification
          formDataErr={formDataErr}
          segmentList={segmentList}
          handleNext={handleNext}
          handleBack={handleBack}
          handleChangeData={handleChangeData}
          notificationData={notificationData}
          setFormDataErr={setFormDataErr}
        />
      ),
    },
    {
      label: "Additional Option",
      component: (
        <StepThreeCreatePushNotification
          setFormDataErr={setFormDataErr}
          notificationData={notificationData}
          handleChangeData={handleChangeData}
          handleBack={handleBack}
          handleClearAllFormData={handleClearAllFormData}
        />
      ),
    },
  ];

  if (hasCreatePushNotificationAccess) {
    return (
      <div className="w-full py-4 px-8 flex">
        <div className="w-1/2 p-5">
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  error={false}
                  StepIconProps={{
                    sx: { color: "#189634", cursor: "pointer" },
                    active: false,
                    completed: false,
                    error: false,
                  }}
                  // optional={
                  //   index === 2 ? <p variant="caption">Last step</p> : null
                  // }
                  onClick={() => setActiveStep(index)}
                >
                  <h1
                    className={`${
                      index === 0 &&
                      (formDataErr[NOTIFICATION_NAME] ||
                        formDataErr[NOTIFICATION_CONTENT])
                        ? "text-red-600"
                        : ""
                    } ${
                      index === 1 && formDataErr[INCLUDED_SEGMENTS]
                        ? "text-red-600"
                        : ""
                    } text-lg font-semibold cursor-pointer`}
                  >
                    {step.label}
                  </h1>
                </StepLabel>
                <StepContent TransitionProps={{ unmountOnExit: false }}>
                  {step.component}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="w-1/2 p-5">
          <DevicePreview notificationData={notificationData} />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-[calc(100%-100px)]">
      <AccessDenied />
    </div>
  );
};

export default CreatePushNotification;
