import React, { useEffect, useState } from "react";
import Search from "../../components/Search";
import useAxios from "../../hooks/useAxios";
import Loader from "../../components/Loader/Loader";
import AppUserListTable from "../../components/AppUserList/AppUserListTable";
import PaginationBar from "../../components/PaginationBar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AppUserList = () => {
  const LOADER = <Loader />;
  const NODATAFOUND = "No Data Found";
  const navigate = useNavigate();
  const axios = useAxios();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [customerData, setCustomerData] = useState({});
  const [pageComponent, setPageComponent] = useState(LOADER);
  const [filter, setFilter] = useState({
    search: "",
    sortOrder: "DESC",
  });
  const isAscSortOrder = filter.sortOrder === "ASC";

  const fetchCustomerList = async (filters = "") => {
    setPageComponent(LOADER);
    const response = await axios
      .get(
        `customer/getAllCustomerList?pageNo=${pageNo}&pageSize=${pageSize}${filters}`
      )
      .then((res) => res)
      .catch((err) => err);
    if (response?.data?.status) {
      setCustomerData(response.data);
      setPageComponent(null);
    } else if (response?.response?.data?.error === "INVALID_PAGE_NUMBER") {
      setPageNo(1);
    } else {
      setPageComponent(NODATAFOUND);
    }
  };
  const { results, totalRecords } = customerData;

  const formatFilters = () => {
    let filters = "";
    if (filter.search) {
      filters += `&filter=${filter.search}`;
    }
    if (filter.sortOrder) {
      filters += `&sortOrder=${filter.sortOrder}`;
    }
    return filters;
  };

  useEffect(() => {
    const filters = formatFilters();
    fetchCustomerList(filters);
  }, [pageNo, pageSize, filter]);

  const getSearchValue = (e) => {
    setFilter((prev) => ({
      ...prev,
      search: e,
    }));
  };

  const handleExportCsv = async () => {
    const filters = formatFilters();
    const result = await axios
      .get(
        `customer/getAllCustomerList?exportCsv=1&pageNo=${pageNo}&pageSize=${pageSize}${filters}`
      )
      .then((res) => res)
      .catch((err) => err);
    if (Number(result?.status === 200) && typeof result.data === "string") {
      try {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = "customer_list";
        link.setAttribute("download", `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.success("Report downloaded");
      } catch (error) {
        toast.error(result?.data?.message || "Failed to generate report");
      }
    } else {
      toast.error(
        result?.response?.data?.message || "Failed to generate report"
      );
    }
  };

  return (
    <div>
      <div className="p-10">
        <div className="flex items-center">
          <span
            onClick={() => navigate("/dashboard")}
            className="material-symbols-outlined hover:text-green-600 cursor-pointer"
          >
            arrow_back_ios
          </span>
          <h1 className="text-xl font-semibold">App User List</h1>
        </div>
        <div className="flex justify-between py-5">
          <div className="flex gap-3 items-center">
            <button
              onClick={() =>
                setFilter((prev) => ({
                  ...prev,
                  sortOrder: isAscSortOrder ? "DESC" : "ASC",
                }))
              }
              className="flex items-center border h-10 px-1 rounded-md border-gray-300"
            >
              <span class="material-symbols-outlined ">swap_vert</span> Sort by
              signup date {isAscSortOrder ? "Ascending" : "Descending"}
            </button>
            {/* Filters can be used in here */}
          </div>
          <div className="flex items-center gap-3">
            <Search onChange={getSearchValue} />
            <button className="btn btn--border" onClick={handleExportCsv}>
              <span className="material-symbols-outlined">download</span>Export
              CSV
            </button>
          </div>
        </div>
        <div className="mb-10">
          {pageComponent ? (
            <div className="w-full flex justify-center items-center h-[calc(100vh-300px)]">
              {pageComponent}
            </div>
          ) : Array.isArray(results) ? (
            <AppUserListTable data={results} />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="fixed bottom-0 xl:w-[calc(100%-300px)] w-[calc(100%-77px)] border">
        <PaginationBar
          page={{
            pageNo,
            setPageNo,
            pageSize,
            setPageSize,
            totalRecords,
          }}
        />
      </div>
    </div>
  );
};

export default AppUserList;
