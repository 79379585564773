import React, { useEffect, useState } from "react";
import StaticSiteTable from "../../../components/AppSettings/StaticSite/StaticSiteTable";
import useAxios from "../.././../hooks/useAxios";
import AddStaticSite from "../../../components/AppSettings/StaticSite/AddStaticSite";
import Loader from "../../../components/Loader/Loader";

const StaticSite = () => {
  const axios = useAxios();
  const [isAddStaticSiteModal, setAddStaticSiteModal] = useState(false);
  const [staticPageData, setStaticPageData] = useState(null);
  const [component, setComponent] = useState(<Loader />);

  const getAllStaticPageData = async () => {
    await axios
      .get("services/getAllStaticPage")
      .then((res) => {
        if (res?.data?.status) {
          setStaticPageData(res.data.results);
        } else {
          setComponent("No data found");
        }
      })
      .catch((err) => {
        //console.log(err);
        setComponent("No data found");
      });
  };

  useEffect(() => {
    getAllStaticPageData();
  }, []);

  const fetchPrivacyPolicyHtmlContent = async () => {
    const response = await axios
      .get("services/getPrivacyPolicyPage")
      .then((res) => res)
      .catch((err) => err);
    return response;
  };

  return (
    <div className="bg-gray-50 h-[calc(100vh-100px)]">
      {isAddStaticSiteModal ? (
        <AddStaticSite
          open={isAddStaticSiteModal}
          close={() => setAddStaticSiteModal(false)}
          getAllStaticPageData={getAllStaticPageData}
        />
      ) : null}
      <div className="border-b p-10 flex items-center justify-between">
        <h1 className="text-xl font-semibold">Static Site</h1>
        <button onClick={() => setAddStaticSiteModal(true)} className="btn">
          Add Static Site
        </button>
      </div>
      {Array.isArray(staticPageData) ? (
        <div className="">
          <StaticSiteTable data={staticPageData} />
        </div>
      ) : (
        <div className="flex justify-center items-center h-[calc(100%-200px)]">
          {component}
        </div>
      )}
    </div>
  );
};

export default StaticSite;
