import React, { useState } from "react";
import Select from "react-select";
import { languageArray } from "../../../utils/SegmentSelectBoxValues/segmentFilterValues";

const selectOptions = {
  language: languageArray,
};

const SelectedSegmentFilters = ({
  selectedFilters,
  handleRemoveSelectedFilter,
  handleShowFilter,
  showFilters,
  setSelectedFilters,
}) => {
  const handleSetFilters = (value, key, source) => {
    setSelectedFilters((prev) =>
      prev.map((item) => {
        if (item.id === source.id) {
          return {
            ...item,
            data: {
              ...item.data,
              [key]: value,
              field: source.key,
            },
          };
        } else {
          return item;
        }
      })
    );
  };

  const getSelectBoxValue = (source) => {
    try {
      return selectOptions[source] || [];
    } catch (err) {
      throw err;
    }
  };



  return (
    <>
      {selectedFilters.map((item, index) => {
        const selectBoxValue = getSelectBoxValue(item.key);
        const isKey = item?.filters?.find((i) =>
          Object.prototype.hasOwnProperty.call(i, "key")
        );
        const isValue = item?.filters?.find((i) =>
          Object.prototype.hasOwnProperty.call(i, "value")
        );
        const isMiddle = item?.filters?.find((i) =>
          Object.prototype.hasOwnProperty.call(i, "middle")
        );
        return (
          <div key={`${index}_selected_fitlers_map_key_for_segment`}>
            {item.id && (
              <div className="flex text-sm">
                <div className="bg-gray-200 min-w-[180px] relative font-medium px-4 py-2 flex items-center gap-2">
                  <span className="material-symbols-outlined text-primary-800">
                    {item.icon}
                  </span>
                  <p>{item.title}</p>

                  {selectedFilters[index + 1] &&
                    selectedFilters[index + 1].operator && (
                      <span className="absolute text-xs uppercase w-9 text-center font-medium rounded-sm right-40 top-10 bg-gray-500 z-50 px-1 py-[2px]">
                        {selectedFilters[index + 1].operator}
                      </span>
                    )}
                </div>
                <div className="bg-gray-50 px-4 py-2 flex items-center justify-between gap-2 w-full">
                  <div className="flex gap-4">
                    {isKey && (
                      <>
                        {isKey.key === "select" ? (
                          <Select
                            options={selectBoxValue || []}
                            className="custom-select-container"
                            onChange={(e) =>
                              handleSetFilters(e.value, "key", item)
                            }
                          />
                        ) : (
                          <input
                            type={isKey.key}
                            onChange={(e) => {
                              handleSetFilters(e.target.value, "key", item);
                            }}
                          />
                        )}
                      </>
                    )}
                    {
                      isMiddle ? "" :  <Select
                      options={item.filters.map((i) => ({
                        label: i.title,
                        value: i.operator,
                      }))}
                      className="custom-select-container"
                      onChange={(e) =>
                        handleSetFilters(e.value, "relation", item)
                      }
                    />
                    }
                   
                    {isValue && (
                      <>
                        {isValue.value === "select" ? (
                          <Select
                            options={selectBoxValue || []}
                            className="custom-select-container"
                            onChange={(e) =>
                              handleSetFilters(e.value, "value", item)
                            }
                          />
                        ) : (
                          <input
                            type={isValue.value}
                            onChange={(e) =>
                              handleSetFilters(e.target.value, "value", item)
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                  <span
                    onClick={() => handleRemoveSelectedFilter(item)}
                    className="material-symbols-outlined cursor-pointer text-primary-800"
                  >
                    close
                  </span>
                </div>
              </div>
            )}
            {!showFilters &&
            selectedFilters.length > 0 &&
            index === selectedFilters.length - 1 ? (
              <>
                <div className="flex text-sm">
                  <div
                    onClick={() => handleShowFilter(true, "and")}
                    className="bg-gray-200 cursor-pointer min-w-[180px] font-medium px-4 py-2 flex items-center gap-2"
                  >
                    <span className="material-symbols-outlined text-primary-800">
                      add_circle
                    </span>
                    <p>Add filter</p>
                  </div>
                  <div className="bg-gray-50 px-4 py-2 flex items-center justify-between gap-2 w-full"></div>
                </div>
                <div className="flex text-sm">
                  <div
                    onClick={() => handleShowFilter(true, "or")}
                    className=" cursor-pointer min-w-[180px] font-medium px-4 py-2 flex items-center gap-2"
                  >
                    <span className="material-symbols-outlined text-primary-800">
                      add_circle
                    </span>
                    <p>Add Or</p>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        );
      })}
    </>
  );
};

export default SelectedSegmentFilters;
