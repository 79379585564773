import React from "react";

const DevicePreview = (props) => {
  const { notificationData } = props;
  const notificaitonHeading = notificationData?.notificationHeading || "";
  const notificationContent = notificationData?.notificationContent || "";
  const notificaitonImage = notificationData?.image ? URL.createObjectURL(notificationData?.image) : null

  return (
    <div>
      <div className="border border-gray-300 rounded-md min-w-[375px]">
        <div className="p-5 border-b border-gray-300">
          <h1 className="font-medium text-lg">Device Preview</h1>
          <p className="text-sm ">
            Lorem ipsum dolor sit amet consectetur. Cursus fringilla facilisis
            molestie ac ac. Cras mattis mauris tempus risus. Facilisis lectus
            nibh nisi penatibus tellus risus sem magna. Commodo vulputate nulla
            cras ut lorem tincidunt .{" "}
          </p>
        </div>

        <div className="bg-gray-200 flex flex-col gap-10 items-center p-5">
          <div className="flex flex-col items-center gap-2">
            <div>
              <img
                src="/images/device_preview/android_preview.jpg"
                alt="android device"
                className="w-fit rounded-t-3xl"
              />
              <div className="bg-white pb-5 px-5 w-[375px]">
                <div className="shadow-sm border border-gray-200 rounded-lg px-5 py-2">
                  <div className="flex justify-between items-center pb-2">
                    <div className="flex items-center gap-1">
                      <img
                        src="/images/device_preview/notification_header_icon.svg"
                        alt="notificaiton icon"
                      />

                      <span className="text-primary-600 text-xs font-medium">
                        Grazie
                      </span>
                      <span className="text-[10px] font-medium">now</span>
                    </div>
                    <div>
                      <span className="material-symbols-outlined text-sm">
                        keyboard_arrow_up
                      </span>
                    </div>
                  </div>
                  <p className="font-medium">{notificaitonHeading}</p>
                  <p className="text-sm break-words">{notificationContent}</p>
                  <img src={notificaitonImage}/>
                </div>
              </div>
            </div>
            <label className="font-medium">Android</label>
          </div>
          <div className="flex flex-col items-center gap-2">
            <div>
              <img
                src="/images/device_preview/ios_preview.jpg"
                alt="android device"
                className="w-fit rounded-t-3xl"
              />
              <div className="bg-white pb-5 px-5 w-[375px]">
                <div className="shadow-sm border border-gray-200 rounded-lg px-5 py-2">
                  <div className="flex justify-between items-center pb-2">
                    <div className="flex items-center gap-1">
                      <img
                        src="/images/device_preview/notification_header_icon.svg"
                        alt="notificaiton icon"
                      />

                      <span className="text-primary-600 text-xs font-medium">
                        Grazie
                      </span>
                      <span className="text-[10px] font-medium">now</span>
                    </div>
                    <div>
                      <span className="material-symbols-outlined text-sm">
                        keyboard_arrow_up
                      </span>
                    </div>
                  </div>
                  <p className="font-medium">{notificaitonHeading}</p>
                  <p className="text-sm break-words">{notificationContent}</p>
                  <img src={notificaitonImage}/>
                </div>
              </div>
            </div>
            <label className="font-medium">IOS</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevicePreview;
