import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import Loader from "../components/Loader/Loader";
const StarterLayout = () => {
  
  const [isLoader, setIsLoader] = useState(true);
  const initialRender = useRef(true);
  const handleChangeLoader = () => {
    setIsLoader(false);
    initialRender.current = false;
  };

  useEffect(() => {
    if (initialRender.current && isLoader) {
      setTimeout(() => {
        setIsLoader(false);
        initialRender.current = false;
      }, 2000);
    }
  }, []);

  return (
    <div className="flex relative">
      {isLoader && initialRender.current ? (
        <div className="z-[100] flex items-center justify-center bg-white h-screen w-screen fixed">
          <Loader />
        </div>
      ) : null}

      <div className="w-1/2 overflow-hidden h-screen">
        <img
          onLoad={handleChangeLoader}
          className="w-full h-full object-cover"
          src="images/layout/starterLayout.jpg"
          alt="starter-layout"
        />
      </div>

      <div className="flex items-center justify-center w-1/2 h-screen">
        <Outlet />
      </div>
    </div>
  );
};

export default StarterLayout;
