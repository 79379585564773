import React from "react";
import Notifications from "./Notifications";
import Avatar from "../Avatar/Avatar";
import UserProfileMenu from "./UserProfileMenu";
import { useNavigate } from "react-router-dom";
import { logOutApi } from "../../redux/reducers/auth";
import { useDispatch, useSelector } from "react-redux";

const DashboardHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const viewProfilePage = () => {
    navigate("/dashboard/profile");
  };
  const viewSettingsPage = () => {
    navigate("/dashboard/profile/settings");
  };
  const logOutBtn = async () => {
    await dispatch(logOutApi()).then((res) => {
      if (res?.payload?.status) {
        return navigate("/");
      }
    });
  };

  const menuBtnAction = {
    viewProfilePage,
    viewSettingsPage,
    logOutBtn,
  };
  const userProfile = useSelector((state) => state.auth.profile);
  const isUserDataLoader = useSelector((state) => state.auth.userDataLoader);
  const userFullName = `${userProfile?.first_name || ""} ${
    userProfile?.last_name || ""
  }`;
  const userRole = userProfile?.role_name || "";
  const image = userProfile?.image || null;
  return (
    // w-[calc(100vw-302px)]
    <div>
      <div className="flex border-b bg-white justify-between sticky top-0 z-50 items-center h-[100px] xl:w-[calc(100vw-308px)] w-[calc(100vw-77px)] ">
        <div className="px-10">
          <p className="font-semibold whitespace-nowrap hidden ssm:block text-sm md:text-xl">
            Welcome Back {userFullName}
          </p>
          <p className="text-sm hidden ssm:block text-gray-500">
          Grazie App Management Portal
          </p>
        </div>
        <div className="flex items-center gap-3 px-8">
          <Notifications />
          <div className="flex items-center gap-2">
            <Avatar image={image} status="available" />
            <div>
              <h1 className="text-sm font-semibold">{userFullName}</h1>
              <p className="text-xs text-gray-500 font-medium">{userRole}</p>
            </div>
            <UserProfileMenu menuBtnAction={menuBtnAction} />
          </div>
        </div>
      </div>
      {isUserDataLoader ? <div className="loader-line"></div> : null}
    </div>
  );
};

export default DashboardHeader;
