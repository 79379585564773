import React, { useEffect, useState } from "react";
import AuthenticationDetails from "./AuthenticationDetails";
import { useForm, Controller } from "react-hook-form";
import {
  emailRegex,
  alphaOnlyRegex,
  numericOnlyRegex,
} from "../../helpers/regexValues";
import { getFormErrMsg } from "../../utils/helperFunctions";
import Select from "react-select";
import useAxios from "../../hooks/useAxios";
import LoadingButton from "../LoadingButton";

const EditProfileDetails = ({ user, validateUserData, isBtnLoader }) => {
  const { profile } = user;
  const axios = useAxios();
  const [locaitonList, setLocationList] = useState({
    country: [],
    state: [],
    city: [],
  });
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState(null);

  const profileData = {
    firstName: profile?.first_name || "",
    lastName: profile?.last_name || "",
    email: profile?.email || "",
    phoneNumber: profile?.phone || "",
    countryName: profile?.country_name || "",
    stateName: profile?.state_name || "",
    cityName: profile?.city_name || "",
    pinCode: profile?.pincode || "",
    adress: profile?.adress || "",
    personalWebsiteAdress: profile?.personal_website_adress || "",
    countryId: profile?.country_id || "",
    stateId: profile?.state_id || "",
    cityId: profile?.city_id || "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: profileData,
  });

  const fetchCountryList = async () => {
    await axios
      .get(`services/location`)
      .then((res) => {
        if (res?.data?.status) {
          setLocationList((prev) => ({
            ...prev,
            country: res?.data?.results || [],
          }));
        }
      })
      .catch((err) => {
        //console.log(err, "err part");
      });
  };
  const fetchStateList = async () => {
    await axios
      .get(`services/location?countryId=${selectedCountryId}`)
      .then((res) => {
        if (res?.data?.status) {
          setLocationList((prev) => ({
            ...prev,
            state: res?.data?.results || [],
          }));
        }
      })
      .catch((err) => {
        //console.log(err, "err part");
      });
  };
  const fetchCityList = async () => {
    await axios
      .get(
        `services/location?countryId=${selectedCountryId}&stateId=${selectedStateId}`
      )
      .then((res) => {
        if (res?.data?.status) {
          setLocationList((prev) => ({
            ...prev,
            city: res?.data?.results || [],
          }));
        }
      })
      .catch((err) => {
        //console.log(err, "err part");
      });
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  useEffect(() => {
    if (selectedCountryId) {
      fetchStateList();
    }
  }, [selectedCountryId]);

  useEffect(() => {
    if (selectedStateId) {
      fetchCityList();
    }
  }, [selectedStateId]);


  const onSubmit = (data) => {
    validateUserData(data, profileData);
  };

  return (
    <div className="w-4/5 shadow-md border flex flex-col gap-2 rounded-md">
      <div className="">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-5">
            <h1 className="font-semibold">My Profile Details</h1>
          </div>
          <div className="flex flex-col gap-4 border-b-2 p-5">
            <div className="flex justify-between gap-5">
              <div className="flex flex-col w-full">
                <label className="text-sm">First Name</label>
                <input
                  {...register("firstName", {
                    required: "This field is required",
                    pattern: {
                      value: alphaOnlyRegex,
                      message: "Provide a valid first name",
                    },
                  })}
                  type="text"
                />
                {errors?.firstName && getFormErrMsg("firstName", errors)}
              </div>
              <div className="flex flex-col w-full">
                <label className="text-sm">Last Name</label>
                <input
                  {...register("lastName", {
                    required: "This field is required",
                    pattern: {
                      value: alphaOnlyRegex,
                      message: "Provide a valid last name",
                    },
                  })}
                  type="text"
                />
                {errors?.lastName && getFormErrMsg("lastName", errors)}
              </div>
            </div>
            <div className="flex justify-between gap-5">
              <div className="flex flex-col w-full">
                <label className="text-sm">Email</label>
                <input
                  {...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: emailRegex,
                      message: "Provide a valid email",
                    },
                  })}
                  type="text"
                />
                {errors?.email && getFormErrMsg("email", errors)}
              </div>
              <div className="flex flex-col w-full">
                <label className="text-sm">Phone Number</label>
                <input
                  {...register("phoneNumber", {
                    required: "This field is required",
                    pattern: {
                      value: numericOnlyRegex,
                      message: "Provide a valid phone number",
                    },
                  })}
                  type="text"
                />
                {errors?.phoneNumber && getFormErrMsg("phoneNumber", errors)}
              </div>
            </div>
            <div className="flex justify-between gap-5">
              <div className="flex flex-col w-full">
                <label className="text-sm">Country</label>
                <Controller
                  control={control}
                  name="countryId"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      options={locaitonList.country.map((i) => ({
                        label: i?.country_name,
                        value: i?.id,
                      }))}
                      placeholder={profileData?.countryName || "Select country"}
                      className="custom-select-container"
                      onChange={(e) => {
                        onChange(e.value);
                        setSelectedCountryId(e.value);
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col w-full">
                <label className="text-sm">State</label>

                <Controller
                  control={control}
                  name="stateId"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      options={locaitonList.state.map((i) => ({
                        label: i?.state_name,
                        value: i?.id,
                      }))}
                      placeholder={profileData?.stateName || "Select state"}
                      className="custom-select-container"
                      onChange={(e) => {
                        onChange(e.value);
                        setSelectedStateId(e.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex justify-between gap-5">
              <div className="flex flex-col w-full">
                <label className="text-sm">City</label>
                <Controller
                  control={control}
                  name="cityId"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      options={locaitonList.city.map((i) => ({
                        label: i?.city_name,
                        value: i?.id,
                      }))}
                      placeholder={profileData?.cityName || "Select city"}
                      className="custom-select-container"
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col w-full">
                <label className="text-sm">Zipcode</label>
                <input
                  {...register("pinCode", {
                    pattern: {
                      value: numericOnlyRegex,
                      message: "Provide a valid pincode",
                    },
                  })}
                  type="text"
                />
                {errors?.pinCode && getFormErrMsg("pinCode", errors)}
              </div>
            </div>
            <div className="flex justify-between gap-5">
              <div className="flex flex-col w-full">
                <label className="text-sm">Adress</label>
                <input {...register("adress")} type="text" />
                {errors?.adress && getFormErrMsg("adress", errors)}
              </div>
              <div className="flex flex-col w-full">
                <label className="text-sm">Website</label>
                <input {...register("personalWebsiteAdress")} type="text" />
                {errors?.personalWebsiteAdress &&
                  getFormErrMsg("personalWebsiteAdress", errors)}
              </div>
            </div>
            <div className="flex justify-between gap-5">
              {isBtnLoader ? (
                <LoadingButton title="Updating Information" />
              ) : (
                <button type="submit" className="btn">
                  Update Information
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <AuthenticationDetails user={user} />
    </div>
  );
};

export default EditProfileDetails;
