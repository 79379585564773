import React from "react";
import UserListData from "./UserListData";

const tableHeadCols = [
  {
    title: "Name",
  },
  {
    title: "Role",
  },
  {
    title: "Email",
  },
  {
    title: "Status",
  },
  {
    title: "Portal Access",
  },
  {
    title: "",
  },
];

const UserListTable = ({ data }) => {
  return (
    <table className="w-full">
      <thead className="bg-gray-50 border">
        <tr>
          {tableHeadCols.map((col, index) => (
            <td
              key={`user_list_table_head_key_${index}`}
              className={`py-3 font-medium  ${
                tableHeadCols.length - 1 === index ? "" : "px-10"
              }`}
            >
              {col.title}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <UserListData key={`user_list_data_key_${index}`} data={item} />
        ))}
      </tbody>
    </table>
  );
};

export default UserListTable;
