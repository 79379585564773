import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import LoadingButton from "../../components/LoadingButton";
import { setUserData, logOutApi } from "../../redux/reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { emailRegex } from "../../helpers/regexValues";
import axios from "../../helpers/axios";
import { jwtDecode } from "jwt-decode";
import useAxios from "../../hooks/useAxios";
import Loader from "../../components/Loader/Loader";
import { getFormErrMsg } from "../../utils/helperFunctions";
import { toast } from "react-toastify";
import Avatar from "../../components/Avatar";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const [isLoaderBtn, setLoaderBtn] = useState(false);
  const [isPageLoader, setPageLoader] = useState(true);
  const [isUserAuthByToken, setIsUserAuthByToken] = useState(false);
  const [isBrowserHistory, setIsBrowserHistory] = useState(null);
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosPrivate = useAxios();
  const location = useLocation();
  const initialRender = useRef(true);
  const user = useSelector((state) => state.auth.profile);
  const email = user?.email || "";
  const firstName = user?.first_name || "";
  const image = user?.image || "";

  const fetchUserData = async () => {
    try {
      const controller = new AbortController();
      const signal = controller.signal;
      await axiosPrivate
        .get("auth/getUserByAuth?login", { signal })
        .then((res) => {
          if (res?.data?.status) {
            setIsUserAuthByToken(true);
            const { data } = res;
            sessionStorage?.setItem("accessToken", data.token);
            const decodedData = jwtDecode(data.token);
            dispatch(setUserData({ ...decodedData.data, token: data.token }));
          }
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {
          setPageLoader(false);
        });
      return () => {
        controller.abort();
      };
    } catch (err) {
      setPageLoader(false);
      throw err;
    }
  };

  useEffect(() => {
    if (
      (location?.state?.tokenExpired || location?.state?.portalAccessDenied) &&
      initialRender.current
    ) {
      let message = location?.state?.tokenExpired
        ? "Session expired, Login again"
        : location?.state?.portalAccessDenied
        ? "Portal access denied"
        : "";
      toast.warn(message, {
        hideProgressBar: true,
      });
      if (location?.state?.path) {
        setIsBrowserHistory(location?.state?.path);
      }
      initialRender.current = false;
      window.history.replaceState({}, document.title);
    }
    setTimeout(() => {
      fetchUserData();
    }, 1000);
  }, []);

  const onSubmit = async (data) => {
    try {
      setErrMsg("");
      setLoaderBtn(true);
      return await axios
        .post("auth/login", data)
        .then((res) => {
          if (res?.response?.data?.validationRes) {
            if (Array.isArray(res?.response?.data?.validationRes?.errors)) {
              let err = "";
              res?.response?.data?.validationRes?.errors.forEach((item) => {
                err += `${item.msg} `;
              });
              toast.error(err);
            }
          }
          if (res?.data?.status) {
            const { data } = res;
            sessionStorage?.setItem("accessToken", data.token);
            const decodedData = jwtDecode(data.token);
            dispatch(setUserData({ ...decodedData.data, ...data }));
            navigate(isBrowserHistory ? isBrowserHistory : "/dashboard");
          } else {
            setErrMsg(
              res?.data?.message ||
                res?.response?.data?.message ||
                "No server response"
            );
          }
        })
        .catch((err) => {
          setErrMsg(err?.response?.data?.message || "No server response");
        })
        .finally(() => {
          setLoaderBtn(false);
        });
    } catch (err) {
      setLoaderBtn(false);
      setErrMsg(err?.response?.data?.message || "No server response");
      throw err;
    }
  };

  if (isPageLoader) {
    return <Loader />;
  }

  const handleClickLogInAsDifferentUser = () => {
    setIsUserAuthByToken(false);
    dispatch(logOutApi());
  };

  return (
    <div className="min-w-[400px]">
      {isUserAuthByToken ? (
        <div className="border rounded-lg px-5 py-10 flex flex-col justify-center items-center gap-3">
          <Avatar height="20" width="20" image={image} />
          <span>{email}</span>
          <button
            onClick={() => navigate("/dashboard")}
            className="btn btn--border w-full"
            type="button"
          >
            Continue as {firstName}
          </button>
          <div className="flex items-center gap-2">
            <span className="w-[160px] h-px border border-gray-500 bg-gray-800"></span>
            <span>or</span>
            <span className="w-[160px] h-px border border-gray-500 bg-gray-800"></span>
          </div>
          <button
            onClick={handleClickLogInAsDifferentUser}
            className="btn  w-full"
            type="button"
          >
            Log in as different user
          </button>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="form-head mb-8">LOGIN</h1>
          {errMsg && (
            <>
              <span className="font-semibold text-red-600 text-sm">
                {errMsg}
              </span>
              <br />
            </>
          )}
          <div className="flex flex-col gap-7 mb-2">
            <div className="flex flex-col gap-3">
              <label className="form-label">Email</label>
              <div>
                <input
                  {...register("email", {
                    required: "This field is required",
                    pattern: {
                      value: emailRegex,
                      message: "Provide a valid email adress",
                    },
                  })}
                  className="form-input"
                  placeholder="Enter Your Email Address"
                  type="text"
                />
                {errors.email && getFormErrMsg("email", errors)}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <label className="form-label">Password</label>
              <div>
                <input
                  {...register("password", {
                    required: "This field is required",
                  })}
                  className="form-input"
                  placeholder="Enter Your Password"
                  type="password"
                />
                {errors.password && getFormErrMsg("password", errors)}
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center ">
            <div className="flex items-center gap-2">
              <input {...register("rememberMe")} type="checkbox" />{" "}
              <span className="text-sm font-medium text-gray-800">
                Remember me
              </span>
            </div>

            <Link to="/forgot-password">
              <button
                type="button"
                className="text-sm font-medium text-gray-800"
              >
                Forgot your password!
              </button>
            </Link>
          </div>

          <div className="mt-7">
            {isLoaderBtn ? (
              <LoadingButton class="btn--full" title="Logging In" />
            ) : (
              <button className="btn btn--full" type="submit">
                Login
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default Login;
