import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { Modal } from "react-responsive-modal";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import LoadingButton from "../LoadingButton";
import Loader from "../Loader/Loader";

const AddVideoBanner = ({ open, close, fetchBannerList }) => {
  const axios = useAxios();
  const [collectionList, setCollectionList] = useState([]);
  const [collectionProductList, setCollectionProductList] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState();
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [fileErrMsg, setFileErrMsg] = useState({
    error: false,
    message: "",
  });
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm({
    mode: "onBlur",
    // defaultValues: bannerFormDataInitialValue,
  });

  const fetchCollections = async (pageLimit = 50) => {
    setCollectionList([]);
    await axios
      .get(`shopify/getCollections?pageLimit=${pageLimit}`)
      .then((res) => {
        if (res?.data?.status) {
          setCollectionList(res?.data?.collections?.edges || []);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const fetchCollectionProducts = async () => {
    setCollectionProductList([]);
    setIsProductLoading(true);
    await axios
      .get(`shopify/collectionProducts?collectionId=${selectedCollection}`)
      .then((res) => {
        if (res?.data?.status) {
          setCollectionProductList(res?.data?.results || []);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setIsProductLoading(false);
      });
  };

  useEffect(() => {
    if (selectedCollection) {
      fetchCollectionProducts();
    }
  }, [selectedCollection]);

  useEffect(() => {
    fetchCollections();
  }, []);

  const onSubmit = async (data) => {
    if (fileErrMsg.error) {
      return;
    }
    const { bannerTitle, collection, product, description, isActive, video } =
      data;
    const formData = new FormData();
    formData.append("bannerTitle", bannerTitle);
    formData.append("description", description);
    if (product) {
      formData.append("product", JSON.stringify(product));
    }
    if (collection) {
      formData.append("collection", JSON.stringify(collection));
    }
    formData.append("isActive", Number(isActive));
    formData.append("video", video[0]);
    setLoaderBtn(true);
    const response = await axios
      .post("banner/createVideoBanner", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setUploadProgress(progress);
        },
      })
      .then((res) => res)
      .catch((err) => err)
      .finally(() => {
        setLoaderBtn(false);
      });
    if (response?.data?.status) {
      toast.success(response?.data?.message || "Video banner created");
      fetchBannerList();
      close();
    } else {
      toast.error("Failed to create video banner");
    }
  };

  const getFieldErrMsg = (field) => {
    if (errors?.[field]) {
      return (
        <span className="text-red-600 text-sm">{errors[field].message}</span>
      );
    }
  };

  const handleValidateVideoFile = (e) => {
    setFileErrMsg({
      error: false,
      message: "",
    });
    const file = e?.target?.files?.[0];
    if (!file) {
      setFileErrMsg({
        error: true,
        message: "Something went wrong while uploading file, Try again.",
      });
      return;
    }
    const { size } = file;
    if (size > 5253880) {
      setFileErrMsg({
        error: true,
        message:
          "File size is greater than 5MB, Try uploading video file upto 5MB",
      });
    }
  };
  return (
    <Modal open={open} onClose={close} center closeOnOverlayClick={false}>
      <div className="min-w-[500px]">
        {isProductLoading && (
          <div className="fixed border text-white bg-gray-800 z-10 h-[300px] left-[123px] opacity-75 rounded-md top-[30px] w-[300px] flex items-center justify-center">
            <div className="flex flex-col justify-center">
              <Loader />
              Loading Products...
            </div>
          </div>
        )}
        <div className="">
          <h1 className="font-medium">Add Video Banner</h1>
        </div>
        {fileErrMsg?.error && (
          <span className="text-red-600 font-medium text-sm">
            {fileErrMsg?.message || ""}
          </span>
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col justify-between py-3"
        >
          <div className="">
            <div className="flex flex-col gap-3">
              <div className="flex flex-col">
                <label>Title</label>
                <input
                  type="text"
                  {...register("bannerTitle", {
                    required: "This field is required",
                  })}
                />
                {getFieldErrMsg("bannerTitle")}
              </div>
              <div className="flex flex-col">
                <label>Description</label>
                <textarea {...register("description")}></textarea>
                {getFieldErrMsg("description")}
              </div>
              <div className="flex flex-col">
                <label>Video</label>
                <input
                  {...register("video", {
                    required: "This field is required",
                    onChange: (e) => handleValidateVideoFile(e),
                  })}
                  type="file"
                />
                {uploadProgress > 0 && uploadProgress < 100 && (
                  <div>
                    <p>Upload Progress: {uploadProgress}%</p>
                    <progress value={uploadProgress} max="100" />
                  </div>
                )}
                {getFieldErrMsg("video")}
              </div>
              <div className="flex gap-3">
                <div>
                  <label>Collection</label>
                  <Controller
                    name="collection"
                    control={control}
                    rules={{
                      required: "This field is required",
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          className="custom-select-container"
                          options={collectionList}
                          getOptionLabel={(option) => option.node.title}
                          getOptionValue={(option) => option.node.id}
                          onChange={(e) => {
                            onChange(e.node);
                            setSelectedCollection(e.node.id);
                          }}
                        />
                      );
                    }}
                  />
                  {getFieldErrMsg("collection")}
                </div>
                <div>
                  <label>Products</label>
                  <Controller
                    name="product"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          className="custom-select-container"
                          options={collectionProductList}
                          getOptionLabel={(option) => option.node.title}
                          getOptionValue={(option) => option.node.id}
                          onChange={(e) => {
                            onChange(e.node);
                          }}
                        />
                      );
                    }}
                  />
                  {getFieldErrMsg("product")}
                </div>
              </div>
              <div className="flex items-center gap-1">
                <label>Default Video</label>
                <input {...register("isActive")} type="checkbox" />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-3">
            <button type="button" onClick={close} className="btn btn--border">
              Cancel
            </button>
            {loaderBtn ? (
              <LoadingButton title="Uploading video file..." />
            ) : (
              <button type="submit" className="btn">
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddVideoBanner;
