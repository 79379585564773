import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import useAxios from "../../../hooks/useAxios";
import { chartColors } from "../../../utils/helperFunctions";


Chart.register(...registerables);

const Notification = () => {
  const axios = useAxios();

  const [outcomes, setOutcomes] = useState([]);
  const [data, setData] = useState([
    {
      title: "Click Count",
      value: 0,
      key: "os__click",
    },
    {
      title: "Session Count",
      value: 0,
    },
    {
      title: "Delivered",
      value: 0,
    },
  ]);


  useEffect(() => {
    setData((prev) => {
      return prev.map((item) => {
        const getCoutcome = outcomes.find((i) => i?.id === item?.key);
        if (getCoutcome) {
          return {
            ...item,
            ...getCoutcome,
          };
        } else {
          return item;
        }
      });
    });
  }, [outcomes]);

  const fetchOutComes = async () => {
    await axios
      .get("notification/outcomes")
      .then((res) => {
        if (res?.data?.status && Array.isArray(res.data.outcomes)) {
          setOutcomes(res.data.outcomes);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };


  useEffect(() => {
    fetchOutComes();
  }, []);


  useEffect(() => {
    const ctx = document.getElementById("NotificationChart").getContext("2d");
    const myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: data.map((i) => i.title),
        datasets: [
          {
            label: "# count",
            data: data.map((i) => i.value || 0.00001),
            backgroundColor: chartColors,
          },
        ],
      },
      options: {
        scales: {},
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
    return () => {
      myChart.destroy();
    };
  }, [data]);
  return (
    <>
      <div className="border rounded-md p-3  h-full bg-white shadow-md">
        <div className="flex justify-between">
          <p className="font-semibold">Notification Statistics</p>
        </div>

        <div className="flex flex-col xlg:flex-row items-center py-4">
          <div className="max-w-[150px] max-h-[150px] px-3">
            <canvas id="NotificationChart"></canvas>
          </div>
          <div className="flex pt-5 flex-wrap xlg:flex-col w-3/4">
            {data.map((item, index) => {
              return (
                <div
                  className="flex  justify-between  w-full pb-3 pr-5 text-sm items-center"
                  key={`banner_activities_key_${index}`}
                >
                  <span
                    style={{ backgroundColor: chartColors[index] }}
                    className={`xlg:h-3 xlg:w-5 h-2 w-3 rounded-[20px] mr-3`}
                  ></span>

                  <p className="text-left w-4/5">{item.title}</p>
                  <h3 className=" text-gray-800 ml-2 font-semibold">
                    {item.value}
                  </h3>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
